import React from 'react';
import ToggleScaffold from '../../../../../../../../common/forms/ToggleScaffold';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { AmenityDailyAvailabilityPicker } from './AmenityDailyAvailabilityPicker';
import { AmenityCustomAvailabilityPicker } from './AmenityCustomAvailabilityPicker';
import { TemporaryClosings } from './TemporaryClosings';
import { useTranslation } from 'react-i18next';

export const AmenityAvailability = props => {
  const {
    errors,
    isSubmitting,
    setFieldValue,
    touched,
    values,
    mode,
    setReasonForCancelAll,
    setShowCancelReservationsModal,
    amenityHasActiveReservations,
  } = props;
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.availability.sections' });

  const pathToDays = 'daysAndHours.subsections.custom.daysOfWeek';
  const weekDays = [
    { name: 'sunday', shorthand: t(`${pathToDays}.sun`) },
    { name: 'monday', shorthand: t(`${pathToDays}.mon`) },
    { name: 'tuesday', shorthand: t(`${pathToDays}.tue`) },
    { name: 'wednesday', shorthand: t(`${pathToDays}.wed`) },
    { name: 'thursday', shorthand: t(`${pathToDays}.thu`) },
    { name: 'friday', shorthand: t(`${pathToDays}.fri`) },
    { name: 'saturday', shorthand: t(`${pathToDays}.sat`) },
  ];

  return (
    <div className="paper__grid">
      <div className="paper__section--grid-item">
        <section className="paper__section">
          <h4 className="section-header h4 w--helper">{t('daysAndHours.title')}</h4>
          <p className="help-text">{t('daysAndHours.subtitle')}</p>
          <InputScaffold required validation={touched.availability && errors.availability}>
            <div
              className="radio--container radio--container__column"
              style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              <ToggleScaffold label={t('daysAndHours.radioOptions.none')}>
                <input
                  type="radio"
                  name="none"
                  checked={values.availability === 'NONE'}
                  onChange={() => {
                    if (mode === 'edit') {
                      setReasonForCancelAll('');
                      if (amenityHasActiveReservations) {
                        setShowCancelReservationsModal(true);
                      }
                    }
                    setFieldValue('availability', 'NONE');
                    setFieldValue('reservation_type', 'NON_RESERVABLE');
                  }}
                />
              </ToggleScaffold>
              <ToggleScaffold label={t('daysAndHours.radioOptions.24/7')}>
                <input
                  type="radio"
                  name="24/7"
                  checked={values.availability === '24/7'}
                  onChange={() => {
                    if (mode === 'edit') {
                      setReasonForCancelAll('');
                    }
                    setFieldValue('availability', '24/7');
                  }}
                />
              </ToggleScaffold>
              <ToggleScaffold label={t('daysAndHours.radioOptions.daily')}>
                <input
                  type="radio"
                  name="daily"
                  checked={values.availability === 'DAILY'}
                  onChange={() => {
                    if (mode === 'edit') {
                      setReasonForCancelAll('');
                    }
                    setFieldValue('availability_schedule', {});
                    setFieldValue('availability', 'DAILY');
                  }}
                />
              </ToggleScaffold>
              <ToggleScaffold label={t('daysAndHours.radioOptions.custom')}>
                <input
                  type="radio"
                  name="custom"
                  checked={values.availability === 'CUSTOM'}
                  onChange={() => {
                    if (mode === 'edit') {
                      setReasonForCancelAll('');
                    }
                    setFieldValue('availability_schedule', {});
                    setFieldValue('availability', 'CUSTOM');
                  }}
                />
              </ToggleScaffold>
            </div>
          </InputScaffold>
        </section>
        <section className="paper__section">
          {values.availability === 'DAILY' && (
            <AmenityDailyAvailabilityPicker {...{ isSubmitting, values, setFieldValue, weekDays, errors, touched }} />
          )}
          {values.availability === 'CUSTOM' && (
            <AmenityCustomAvailabilityPicker {...{ isSubmitting, values, setFieldValue, weekDays, errors, touched }} />
          )}
        </section>
      </div>
      <div className="paper__section--grid-item">
        <div className="paper__section">
          <h4 className="section-header h4">{t('temporaryClosings.title')}</h4>
          {mode === 'edit' ? (
            <TemporaryClosings {...props} />
          ) : (
            <p className="help-text">{t('temporaryClosings.subtitle')}.</p>
          )}
        </div>
      </div>
    </div>
  );
};
