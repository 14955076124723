import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import RouteLeavingGuard from '../../../../../../../../common/RouteLeavingGuard';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import { useRefetchEventById, useSendEventCommunication } from '../../hooks';
import { cloneDeep } from 'lodash';
import Icon from '../../../../../../../../common/icons/icon';
import NotificationStatusIndicator from '../../../../../../../../common/forms/NotificationStatusIndicator';
import { getUserPermission } from '../../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Communications = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ event, isEventLoaded, userPermissionList }) => {
    const userPermission = {
      'Edit/Cancel Event': userPermissionList.includes('EDIT/CANCEL_EVENT'),
    };
    const [submitMessage, setSubmitMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);

    const { t } = useTranslation('events', { keyPrefix: 'communicationsTab' });
    const { t: tTime } = useTranslation('time');

    const sendCommunication = useSendEventCommunication(event.id);
    const history = useHistory();
    const [expandedIndex, setExpandedIndex] = useState(null);
    const eventCommunicationsList = event?.event_communications;
    const refetchEventCommunications = useRefetchEventById(event.id);

    const loggedInUserId = useSelector(state => state.user.get('id'));

    const toggleExpandedIndex = index => {
      if (expandedIndex === index) {
        setExpandedIndex(null);
      } else {
        setExpandedIndex(index);
      }
    };

    return (
      <Fragment>
        <Formik
          initialValues={{
            message: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(formValues, formikActions) => {
            setSubmitMessage('');
            setSubmitStatus(null);

            const submitValues = {
              ...cloneDeep(formValues),
              created_by: loggedInUserId,
            };

            sendCommunication.mutate(submitValues, {
              onSuccess: () => {
                refetchEventCommunications(event.id);
                formikActions.resetForm();
                setSubmitMessage(t('successMessage'));
                setSubmitStatus('Success');
              },
              onError: () => {
                formikActions.setSubmitting(false);
                setSubmitMessage(t('errorMessage'));
                setSubmitStatus('Failure');
              },
            });
          }}
          render={({ values, dirty, errors, handleChange, touched, isSubmitting, handleSubmit }) => (
            <div className="container">
              <RouteLeavingGuard
                when={dirty}
                navigate={history.push}
                modalAlertProps={{
                  title: 'Cancel Sending Communication',
                  body: 'Are you sure you want to cancel creating this event communication?',
                  confirmButtonTitle: 'Cancel Event Communication',
                  cancelButtonTitle: 'Cancel',
                }}
              />
              <form onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius top-right">
                  <div className="paper__grid">
                    <div className="paper__section--grid-item">
                      <section className="paper__section">
                        <div className="section-header h4">{t('title')}</div>
                        <p className="help-text">{t('body')}</p>

                        <InputScaffold label={t('message')} required validation={touched.title && errors.title}>
                          <textarea
                            name="message"
                            maxLength="2000"
                            rows="4"
                            cols="50"
                            disabled={isSubmitting}
                            onChange={handleChange}
                            value={values.message}
                          />
                        </InputScaffold>
                      </section>
                    </div>
                  </div>
                </div>
                <div className="paper__footer radius-bottom-left radius-bottom-right">
                  {userPermission['Edit/Cancel Event'] && (
                    <button type="submit" disabled={isSubmitting || Object.keys(errors).length} className="button">
                      {t('submit')}
                    </button>
                  )}
                  <NotificationStatusIndicator
                    hideNotification={!submitMessage}
                    message={submitMessage}
                    type={submitStatus}
                  />
                </div>
              </form>
            </div>
          )}
        />
        {eventCommunicationsList.length > 0 ? (
          <div className="container event--communications__wrapper">
            <h4 className="section-header h4">{t('historyTitle')}</h4>
            {isEventLoaded && (
              <table className="table-units-list">
                <thead>
                  <tr>
                    <th className="first-column">{t('dateSent')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {eventCommunicationsList
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .map((pastMessage, index) => {
                      const date = new Date(pastMessage.created_at);
                      const dateString = moment(date).format(tTime('dateFormat'));

                      return (
                        <Fragment key={index}>
                          <tr
                            className={`event-communication__main-tr ${expandedIndex === index && 'expanded'}`}
                            style={expandedIndex === index ? { borderBottomWidth: 0 } : {}}
                          >
                            <td className="first-column">{dateString}</td>
                            <td className="last-column">
                              <div
                                className="event-communication__show-message"
                                onClick={() => toggleExpandedIndex(index)}
                              >
                                {index === expandedIndex ? (
                                  <Fragment>
                                    <span>{t('hide')}</span>
                                    <Icon icon="ChevronUp" />
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <span>{t('show')}</span>
                                    <Icon icon="ChevronDown" />
                                  </Fragment>
                                )}
                              </div>
                            </td>
                          </tr>
                          {expandedIndex === index && (
                            <tr
                              className={`event-communication__comm-tr ${expandedIndex === index && 'expanded'}`}
                              style={expandedIndex === index ? { borderTopWidth: 0 } : {}}
                            >
                              <td className="event-message" colSpan="2">
                                {pastMessage.message}
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  }
);

export default Communications;
