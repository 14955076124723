import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import InputScaffold from '../../../../../../../common/forms/InputScaffold';
import Switch from '../../../../../../../common/forms/Switch';

import Yup from 'yup';
import { uniqueProperty } from '../../../../../../../common/utils/yup-extensions';
import { getPhoneMask, isValidUUID, trimAndPruneFalsyValues } from '../../../../../../../common/utils/helpers';
import MaskedInput from 'react-input-mask';
import { withRouter } from 'react-router-dom';
import PageHeader from '../../../../../../../common/PageHeader';
import SubHeader from '../../../../../../../common/SubHeader';
import Credentials from '../../../../../../../common/forms/Credentials';
import AccessProfiles from '../../../../../../../common/forms/AccessProfiles';
import Delete from '../../../../../../../common/Delete';
import NoCredentialTypesPlaceholder from '../../../../../common/NoCredentialTypesPlaceholder';
import PrivateSection from '../../../../../../../common/PrivateSection';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

Yup.addMethod(uniqueProperty.schemaConstructor, uniqueProperty.name, uniqueProperty.method);

const UNASSIGNED = 'Unassigned';

class PropertyManagerForm extends Component {
  state = {
    submitError: null,
    confirmDelete: false,
    invalidCodeDetails: null,
  };
  navigateToList = () => {
    const { history, match } = this.props;
    history.push(`/properties/${match.params.propertyId}/property-details/property-managers`);
  };
  navigateToEdit = propertyManagerId => {
    const { history, match } = this.props;
    history.push(`/properties/${match.params.propertyId}/property-details/property-managers/${propertyManagerId}/edit`);
  };

  removePropertyManager = () => {
    this.props.removePropertyManager();
    this.navigateToList();
  };

  render() {
    const {
      editing,
      submitErrors,
      handleSubmit,
      initialValues,
      children,
      credentialTypes,
      profileOptions,
      propertyHasAccessControl,
      propertyHasMasterPanel,
      match,
      roleOptions,
      userPermissionList,
      t,
    } = this.props;

    const userPermission = {
      deletePMUser: userPermissionList.includes('DELETE_PM_USER'),
      editPMUserRole: userPermissionList.includes('EDIT_PM_USER_ROLE'),
    };

    const { submitError, confirmDelete, invalidCodeDetails } = this.state;

    const translatedRoleOptions = roleOptions.map(({ value, label }) =>
      label === UNASSIGNED ? { value, label: t('details.fields.roles.unassigned') } : { value, label }
    );

    const propertyHasAccessProfiles =
      propertyHasAccessControl && propertyHasMasterPanel && !!profileOptions && profileOptions.length > 0;

    const validationSchema = Yup.object().shape({
      first_name: Yup.string()
        .trim()
        .max(64, t('details.errors.maxFirstName'))
        .required(t('details.errors.requiredFirstName')),
      last_name: Yup.string()
        .trim()
        .max(64, t('details.errors.maxLastName'))
        .required(t('details.errors.requiredLastName')),
      email: Yup.string()
        .trim()
        .email(t('details.errors.invalidEmail'))
        .max(255)
        .required(t('details.errors.requiredEmail')),
      company_name: Yup.string().trim(),
      phone: Yup.string().matches(/^[1]\d{10}|[0,2-9]\d{9}$/, t('details.errors.invalidPhoneNumber')),
      property_credentials: Yup.array().of(
        Yup.object()
          .shape({
            code: Yup.string()
              .trim()
              .length(5, t('details.errors.invalidCodeLength'))
              .required(t('details.errors.enterCode'))
              .test('is-valid-range', t('details.errors.invalidCodeNumber'), (val, context) => {
                return !val || (!isNaN(Number(val)) && Number(val) >= 0 && Number(val) <= 65535);
              }),
            credential_type_id: Yup.string()
              .trim()
              .required(t('details.errors.selectType')),
            note: Yup.string()
              .trim()
              .nullable()
              .max(255, t('details.errors.invalidNoteLength')),
          })
          .uniqueProperty('code', t('details.errors.uniqueProperty'))
      ),
      profiles:
        propertyHasMasterPanel && propertyHasAccessProfiles
          ? Yup.array()
              .of(
                Yup.string()
                  .trim()
                  .required()
                  .test('is-uuid', 'Must be a valid UUID', value => isValidUUID(value))
              )
              .min(1, t('details.errors.profiles'))
          : Yup.string().notRequired(),
      enable_new_discussion_board_post_email: Yup.boolean().required(),
      enable_daily_discussion_board_activity_email: Yup.boolean().required(),
    });

    return (
      <div>
        <PageHeader
          title={editing ? t('details.editPortalUser') : t('details.addPortalUser')}
          white
          backLink={editing ? '../' : `./`}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const submitValues = trimAndPruneFalsyValues(values);

            if (submitValues.profiles) {
              submitValues.access_control_profile_id1 = submitValues.profiles[0] || null;
              submitValues.access_control_profile_id2 = submitValues.profiles[1] || null;
              submitValues.access_control_profile_id3 = submitValues.profiles[2] || null;
              submitValues.access_control_profile_id4 = submitValues.profiles[3] || null;
              delete submitValues.profiles;
            }

            if (Array.isArray(submitValues.property_credentials)) {
              submitValues.property_credentials = submitValues.property_credentials.map(c => {
                return { ...c, note: c.note || null };
              });

              for (const credential of submitValues.property_credentials) {
                credential.access_control_profile_id1 = submitValues.access_control_profile_id1;
                credential.access_control_profile_id2 = submitValues.access_control_profile_id2;
                credential.access_control_profile_id3 = submitValues.access_control_profile_id3;
                credential.access_control_profile_id4 = submitValues.access_control_profile_id4;
              }
            }

            if (editing) {
              delete submitValues.email;
            }

            if (!values.phone) {
              submitValues.phone = null;
            }

            return handleSubmit(submitValues, actions).then(res => {
              if (res.response.ok) {
                return editing ? this.navigateToList() : this.navigateToEdit(res.json.id);
              } else if (res.json.error) {
                this.setState({ submitError: res.json.message, invalidCodeDetails: res.json.invalidCodeDetails });
                actions.setSubmitting(false);
              }
            });
          }}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            setFieldValue,
            isSubmitting,
            initialValues,
          }) => (
            <form onSubmit={handleSubmit} className="container property-manager-form property-property-manager-form">
              {submitError && (
                <div className="form-error__wrapper">
                  <span className="form-error--label">Error:&nbsp;</span>
                  {submitError}
                </div>
              )}
              <div>
                <div className="paper radius-top-left radius-top-right">
                  <SubHeader underline title={t('details.staffInformation')} />
                  <div className="input-scaffold__wrapper-row" style={{ maxWidth: '900px' }}>
                    <InputScaffold
                      label={t('details.fields.firstName')}
                      required
                      validation={touched.first_name && errors.first_name}
                    >
                      <input
                        type="text"
                        name="first_name"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.first_name}
                      />
                    </InputScaffold>
                    <InputScaffold
                      label={t('details.fields.lastName')}
                      required
                      validation={touched.last_name && errors.last_name}
                    >
                      <input
                        type="text"
                        name="last_name"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.last_name}
                      />
                    </InputScaffold>
                    <InputScaffold
                      label={t('details.fields.role')}
                      required
                      validation={touched.role_id && errors.role_id}
                    >
                      <Select
                        options={translatedRoleOptions}
                        placeholder={
                          translatedRoleOptions.length
                            ? t('details.fields.selectRole')
                            : t('details.fields.unavailableRole')
                        }
                        onChange={value => userPermission.editPMUserRole && setFieldValue('role_id', value)}
                        value={values.role_id}
                        simpleValue={true}
                        disabled={!userPermission.editPMUserRole}
                      />
                    </InputScaffold>
                  </div>

                  <InputScaffold label={t('details.fields.email')} required validation={touched.email && errors.email}>
                    <input
                      type="text"
                      name="email"
                      maxLength="255"
                      onChange={handleChange}
                      value={values.email}
                      disabled={editing}
                    />
                  </InputScaffold>
                  <div className="input-scaffold__wrapper-row">
                    <InputScaffold label={t('details.fields.phoneNumber')} validation={touched.phone && errors.phone}>
                      <MaskedInput
                        mask={getPhoneMask(values.phone)}
                        name="phone"
                        onChange={event => {
                          setFieldValue('phone', event.target.value.replace(/[^0-9]+/g, ''));
                        }}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                    </InputScaffold>
                    <InputScaffold label={t('details.fields.companyName')} validation={errors.company_name}>
                      <input
                        type="text"
                        name="company_name"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.company_name}
                      />
                    </InputScaffold>
                  </div>
                  <PrivateSection roles={['ADMIN']}>
                    <div className="checkbox-list--item">
                      <label>
                        <input
                          type="checkbox"
                          checked={values.approved_purchaser}
                          onChange={handleChange}
                          name="approved_purchaser"
                        />
                        <span htmlFor="approved_purchaser" className="label">
                          {t('details.fields.approvedPurchaser')}
                        </span>
                      </label>
                    </div>
                  </PrivateSection>
                  <div className="edit-property-manager-section-margin">
                    <SubHeader underline title={t('details.emailSettings')} />
                    <Switch
                      label={t('details.fields.enableDiscussionBoardEmails')}
                      input={{
                        value: values.enable_new_discussion_board_post_email,
                        name: 'enable_new_discussion_board_post_email',
                        onChange: selectedValue =>
                          setFieldValue('enable_new_discussion_board_post_email', selectedValue),
                      }}
                      disabled={false}
                    />
                    <Switch
                      label={t('details.fields.enableDailyDiscussionBoardActivityEmails')}
                      input={{
                        value: values.enable_daily_discussion_board_activity_email,
                        name: 'enable_daily_discussion_board_activity_email',
                        onChange: selectedValue =>
                          setFieldValue('enable_daily_discussion_board_activity_email', selectedValue),
                      }}
                      disabled={false}
                    />
                  </div>
                  {propertyHasAccessControl && propertyHasMasterPanel && (
                    <div className="edit-property-manager-section-margin">
                      <AccessProfiles
                        profileOptions={profileOptions}
                        setFieldValue={setFieldValue}
                        selectedProfiles={values.profiles}
                        errors={errors}
                        touched={touched}
                        propertyId={match.params.propertyId}
                      />
                    </div>
                  )}
                  <div className="edit-property-manager-section-margin">{children}</div>
                  {propertyHasAccessControl && editing && (
                    <div className="edit-property-manager-section-margin">
                      {!initialValues.property_credentials.length && (!credentialTypes || !credentialTypes.length) ? (
                        <NoCredentialTypesPlaceholder />
                      ) : (
                        <Credentials
                          invalidCodeDetails={invalidCodeDetails}
                          property_credentials={values.property_credentials}
                          credentialTypes={credentialTypes}
                          touched={touched}
                          errors={errors}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </div>
                  )}
                  {!!submitErrors && (
                    <div className="input-scaffold">
                      <div className="input-validation">{submitErrors}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className="paper__footer radius-bottom-left radius-bottom-right edit-pm-btns-container">
                {confirmDelete ? (
                  <Delete
                    message={<span dangerouslySetInnerHTML={{ __html: t('details.removeConfirmation') }} />}
                    deleteFunc={this.removePropertyManager}
                    onCancel={() => this.setState({ confirmDelete: false })}
                  />
                ) : (
                  <>
                    <button type="submit" disabled={isSubmitting || Object.keys(errors).length} className="button">
                      {t('details.saveButton')}
                    </button>
                    {editing && userPermission.deletePMUser && (
                      <button
                        type="button"
                        className="button button--destructive"
                        onClick={() => this.setState({ confirmDelete: true })}
                      >
                        {t('details.removeButton')}
                      </button>
                    )}
                    <button type="button" className="button button--secondary" onClick={this.navigateToList}>
                      {t('cancel', { ns: 'buttons' })}
                    </button>
                  </>
                )}
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

PropertyManagerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  initialValues: PropTypes.object,
  match: PropTypes.object.isRequired,
  children: PropTypes.node,
  credentialTypes: PropTypes.array,
  propertyProfileOptions: PropTypes.array,
  userPermissionList: PropTypes.array,
};

export default withRouter(withTranslation(['propertyManagers', 'buttons'])(PropertyManagerForm));
