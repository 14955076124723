import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from '../icons/icon';
import { withTranslation } from 'react-i18next';

class PageHeader extends Component {
  render() {
    const { title, children, backLink, className, white, withTabs, tag, t } = this.props;

    return (
      <div className={classNames('page-header', white && 'page-header__white', withTabs && 'with-tabs', className)}>
        <div className="container">
          {backLink && (
            <Link to={backLink} className="back-arrow">
              <Icon icon="ArrowLeft" />
              {t('back')}
            </Link>
          )}
          {title && (
            <div className="page-header__content--wrapper">
              <div className="page-header__title">
                <h1 className="h1">{title}</h1>
                {tag && <span className="page-header__tag">{tag}</span>}
              </div>
            </div>
          )}
          {children && <div className="page-header__children">{children}</div>}
        </div>
      </div>
    );
  }
}

PageHeader.propTypes = {
  title: PropTypes.node,
  backLink: PropTypes.node,
  children: PropTypes.node,
  white: PropTypes.bool,
  className: PropTypes.string,
};

export default withTranslation('buttons')(PageHeader);
