import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { Formik } from 'formik';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { getPhoneMask, isValidUUID, trimAndSetNullFalsyValues } from '../../../../../../../../common/utils/helpers';

import { trimAndPruneFalsyValues } from '../../../../../../../../common/utils/helpers';
import Yup from 'yup';
import { uniqueProperty } from '../../../../../../../../common/utils/yup-extensions';
import MaskedInput from 'react-input-mask';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PageHeader from '../../../../../../../../common/PageHeader';
import Credentials from '../../../../../../../../common/forms/Credentials';
import AccessProfiles from '../../../../../../../../common/forms/AccessProfiles';
import SubHeader from '../../../../../../../../common/SubHeader';
import NoCredentialTypesPlaceholder from '../../../../../../common/NoCredentialTypesPlaceholder';
import DeleteWithPrompt from '../../../../../../../../common/DeleteWithPrompt';
import { withTranslation } from 'react-i18next';

Yup.addMethod(uniqueProperty.schemaConstructor, uniqueProperty.name, uniqueProperty.method);

class PropertyGuestForm extends Component {
  state = {
    submitError: null,
    invalidCodeDetails: null,
  };
  navigateToList = () => {
    const { history, match } = this.props;

    history.push(`/properties/${match.params.propertyId}/access-control/property-guests`);
  };

  handleRemovePropertyGuest = () => {
    this.navigateToList();
    this.props.removePropertyGuest();
  };

  render() {
    const {
      editing,
      handleSubmit,
      initialValues,
      children,
      credentialTypes,
      profileOptions,
      propertyHasMasterPanel,
      match,
      t,
    } = this.props;
    const { submitError, invalidCodeDetails } = this.state;
    const validationSchema = Yup.object().shape({
      first_name: Yup.string()
        .trim()
        .max(64, t('property.errors.maxFirstName'))
        .required(t('property.errors.enterFirstName')),
      last_name: Yup.string()
        .trim()
        .max(64, t('property.errors.maxLastName'))
        .required(t('property.errors.enterLastName')),
      email: Yup.string()
        .trim()
        .email(t('property.errors.invalidEmailAddress'))
        .max(255),
      phone: Yup.string()
        .matches(/^[1]\d{10}|[0,2-9]\d{9}$/, t('property.errors.invalidPhoneNumber'))
        .required(t('property.errors.enterPhoneNumber')),
      property_credentials: Yup.array().of(
        Yup.object()
          .shape({
            code: Yup.string()
              .trim()
              .length(5, t('property.errors.invalidCodeLength'))
              .required(t('property.errors.enterCode'))
              .test('is-valid-range', t('property.errors.invalidCodeNumber'), (val, context) => {
                return !val || (!isNaN(Number(val)) && Number(val) >= 0 && Number(val) <= 65535);
              }),
            credential_type_id: Yup.string()
              .trim()
              .required(t('property.errors.selectType')),
            note: Yup.string()
              .trim()
              .nullable()
              .max(255, t('property.errors.invalidNoteLength')),
          })
          .uniqueProperty('code', t('property.errors.uniqueProperty'))
      ),
      profiles:
        propertyHasMasterPanel && profileOptions.length > 0
          ? Yup.array()
              .of(
                Yup.string()
                  .trim()
                  .required()
                  .test('is-uuid', 'Must be a valid UUID', value => isValidUUID(value))
              )
              .min(1, 'At least one access profile option must be selected')
          : Yup.string().notRequired(),
    });

    return (
      <div>
        <PageHeader
          title={editing ? t('property.editTitle') : t('property.addTitle')}
          backLink={editing ? '../../' : '../'}
          white
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const submitValues = trimAndPruneFalsyValues(values, ['phone']);

            submitValues.access_control_profile_id1 = submitValues.profiles[0] || null;
            submitValues.access_control_profile_id2 = submitValues.profiles[1] || null;
            submitValues.access_control_profile_id3 = submitValues.profiles[2] || null;
            submitValues.access_control_profile_id4 = submitValues.profiles[3] || null;
            delete submitValues.profiles;

            if (editing) {
              delete submitValues.email;

              for (const credential of submitValues.property_credentials) {
                credential.access_control_profile_id1 = submitValues.access_control_profile_id1;
                credential.access_control_profile_id2 = submitValues.access_control_profile_id2;
                credential.access_control_profile_id3 = submitValues.access_control_profile_id3;
                credential.access_control_profile_id4 = submitValues.access_control_profile_id4;
              }
            }

            return handleSubmit(trimAndSetNullFalsyValues(submitValues), actions).then(res => {
              if (res.response.ok) {
                return this.navigateToList();
              } else {
                this.setState({
                  submitError: res.json.message,
                  invalidCodeDetails: res.json.invalidCodeDetails,
                });
                actions.setSubmitting(false);
              }
            });
          }}
          render={({
            values,
            initialValues,
            isValid,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form onSubmit={handleSubmit} className="container">
                <div className="paper radius-top-left radius-top-right">
                  <div>
                    <SubHeader underline title={t('property.guestInformation')} />
                    <div className="input-scaffold__wrapper-row">
                      <InputScaffold
                        label={t('property.fields.firstName')}
                        required
                        validation={touched.first_name && errors.first_name}
                      >
                        <input
                          type="text"
                          name="first_name"
                          maxLength="64"
                          onChange={handleChange}
                          value={values.first_name}
                        />
                      </InputScaffold>
                      <InputScaffold
                        label={t('property.fields.lastName')}
                        required
                        validation={touched.last_name && errors.last_name}
                      >
                        <input
                          type="text"
                          name="last_name"
                          maxLength="64"
                          onChange={handleChange}
                          value={values.last_name}
                        />
                      </InputScaffold>
                    </div>
                    <InputScaffold
                      label={t('property.fields.phoneNumber')}
                      required
                      validation={touched.phone && errors.phone}
                    >
                      <MaskedInput
                        mask={getPhoneMask(values.phone)}
                        name="phone"
                        onChange={event => {
                          setFieldValue('phone', event.target.value.replace(/[^0-9]+/g, ''));
                        }}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                    </InputScaffold>
                    <InputScaffold label={t('property.fields.emailAddress')} validation={touched.email && errors.email}>
                      <input
                        type="text"
                        name="email"
                        maxLength="255"
                        onChange={handleChange}
                        value={values.email}
                        disabled={editing}
                      />
                    </InputScaffold>
                    {propertyHasMasterPanel && (
                      <div className="edit-property-guest-section-margin">
                        <AccessProfiles
                          profileOptions={profileOptions}
                          setFieldValue={setFieldValue}
                          selectedProfiles={values.profiles}
                          touched={touched}
                          errors={errors}
                          propertyId={match.params.propertyId}
                        />
                      </div>
                    )}
                    <div className="edit-property-guest-section-margin">{children}</div>
                    {editing && (
                      <div className="edit-property-guest-section-margin">
                        {!initialValues.property_credentials.length && (!credentialTypes || !credentialTypes.length) ? (
                          <NoCredentialTypesPlaceholder />
                        ) : (
                          <Credentials
                            invalidCodeDetails={invalidCodeDetails}
                            property_credentials={values.property_credentials}
                            credentialTypes={credentialTypes}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                          />
                        )}
                      </div>
                    )}
                    {!!submitError && (
                      <div className="input-scaffold">
                        <div className="input-validation">{submitError}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="paper__footer radius-bottom-left radius-bottom-right">
                  <button disabled={!isValid || isSubmitting} className="button">
                    {isSubmitting ? t('property.savingPropertyGuest') : t('property.savePropertyGuest')}
                  </button>
                  {editing && (
                    <DeleteWithPrompt
                      disabled={isSubmitting}
                      message={<span dangerouslySetInnerHTML={{ __html: t('main.removeConfirmation') }} />}
                      buttonLabel={t('property.removeGuest')}
                      deleteFunc={this.handleRemovePropertyGuest}
                    />
                  )}
                  <button type="button" className="button button--secondary" onClick={this.navigateToList}>
                    {t('cancel', { ns: 'buttons' })}
                  </button>
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

PropertyGuestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  initialValues: PropTypes.object,
  match: PropTypes.object.isRequired,
  accessControlProfiles: PropTypes.instanceOf(List),
};

export default compose(withRouter)(withTranslation(['propertyGuests', 'buttons'])(PropertyGuestForm));
