import React, { useState } from 'react';
import Icon from '../../../../../../../../common/icons/icon';
import { EventRegistrantItem } from './EventRegistrantItem';
import { CancelRegistrationModal } from './CancelRegistrationModal';
import {
  useEventRegistrationList,
  useRefetchEventRegistrationList,
  useEventWaitList,
  useRefetchEventWaitList,
  useMoveToTop,
} from '../../hooks';
import Loading from '../../../../../../../../common/Loading';
import AlertMessage from '../../../../../../../../common/AlertMessage';
import { getUserPermission } from '../../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { EventWaitListItem } from './EventWaitListItem';
import GuestRegistration from '../GuestRegistration';
import moment from 'moment';
import { GenerateCSVBtn } from './GenerateCSVBtn';
import { useTranslation } from 'react-i18next';

export const Registrations = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ event, unitGroupList, userPermissionList }) => {
    const userPermission = {
      Cancel_Event_Registration: userPermissionList.includes('CANCEL_EVENT_REGISTRATION'),
      'Edit/Cancel Event': userPermissionList.includes('EDIT/CANCEL_EVENT'),
    };
    const [kebabMenuOpenForRegistrationId, setKebabMenuOpenForRegistrationId] = useState(null);
    const [cancelModalRegistration, setCancelModalRegistration] = useState(null);
    const [lastCanceledRegistration, setLastCanceledRegistration] = useState(null);

    const [showRegister, setshowRegister] = useState(false);
    const [kebabMenuOpenForWaitListId, setkebabMenuOpenForWaitListId] = useState(null);
    const [collapsed, setcollapsed] = useState(true);
    const [collapsed2, setcollapsed2] = useState(true);

    const eventRegistrationList = useEventRegistrationList(event.id);
    const refetchEventRegistrationList = useRefetchEventRegistrationList(event.id);
    const { data, isFetched } = eventRegistrationList;
    const showButtonCSV = userPermission['Edit/Cancel Event'] && isFetched && !!data.length;

    const eventWaitList = useEventWaitList(event.id);
    const refetchEventWaitList = useRefetchEventWaitList(event.id);

    const eventGroupIds = event.event_group_ids || [];

    const moveToTop = useMoveToTop();
    const { t } = useTranslation('events');

    const [cancelType, setCancelType] = useState('Attend');
    if (showRegister) {
      return (
        <div className="container event--registrations">
          <GuestRegistration
            setshowRegister={setshowRegister}
            refetchEventWaitList={refetchEventWaitList}
            refetchEventRegistrationList={refetchEventRegistrationList}
            setLastCanceledRegistration={setLastCanceledRegistration}
            event={event}
          />
        </div>
      );
    }
    const eventDate = moment(event.event_date).format('YYYY-MM-DD');
    const eventTime = moment(event.start_time, 'HH:mm').format('HH:mm');
    const hasEventPassed = moment(moment(eventDate + ' ' + eventTime)).isBefore(moment());
    let showGuestRegistrationButton = true;
    if (hasEventPassed) {
      showGuestRegistrationButton = false;
    }
    if (event?.price_amount > 0) {
      showGuestRegistrationButton = false;
    }

    return (
      <div className="container event--registrations">
        <CancelRegistrationModal
          onClose={() => {
            if (cancelType === 'Attend') {
              refetchEventRegistrationList();
              setLastCanceledRegistration(cancelModalRegistration);
              refetchEventWaitList();
            } else {
              refetchEventWaitList();
              setLastCanceledRegistration(cancelModalRegistration);
            }
            setCancelModalRegistration(null);
          }}
          onCancel={() => {
            if (cancelType === 'Attend') {
              refetchEventRegistrationList();
              setLastCanceledRegistration(null);
            } else {
              refetchEventWaitList();
              setLastCanceledRegistration(null);
            }
            setCancelModalRegistration(null);
          }}
          eventId={event.id}
          residentId={cancelModalRegistration?.resident_id}
          visible={!!cancelModalRegistration}
          cancelType={cancelType}
        />
        <div className="paper radius-top-left radius top-right">
          <div className="paper__grid">
            <div className="paper__section--grid-item-event-edit">
              <div className="paper__section-event-edit">
                <h4 className="section-header h4 event-registration-settings">{t('editEvent.registrations.header')}</h4>
                <div className="label event-registration-unit-group">{t('editEvent.registrations.subHeader')}</div>
                <div className="allowed-groups">
                  {eventGroupIds.length === 0 ? (
                    <div className="allowed-group">
                      <Icon icon="FatCheck" />
                      <span className="allowed-group--name">{t('editEvent.registrations.allResidents')}</span>
                    </div>
                  ) : (
                    unitGroupList.map((option, index) => {
                      const matches = eventGroupIds.filter(item => {
                        return item.group_id === option.get('id');
                      });
                      if (matches.length > 0) {
                        return (
                          <div key={index} className="allowed-group">
                            <Icon icon="Check" />
                            <span className="allowed-group--name">{option.get('name')}</span>
                          </div>
                        );
                      }
                      return null;
                    })
                  )}
                </div>
              </div>
              <div>
                {showButtonCSV && (
                  <GenerateCSVBtn
                    title={t('editEvent.registrations.downloadCSV')}
                    filePath={`events/registrations/${event.id}/registrations-csv-list.csv`}
                    style={{ marginRight: '15px' }}
                  />
                )}
                {userPermission['Edit/Cancel Event'] && showGuestRegistrationButton && (
                  <button
                    type="submit"
                    className="button"
                    onClick={() => {
                      setshowRegister(true);
                    }}
                  >
                    {t('editEvent.registrations.registerResidentsBtn')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {lastCanceledRegistration ? (
          <AlertMessage
            msg={
              cancelType === 'Attend'
                ? t('modals.cancelEventRegistration.alertMessage', {
                    firstName: lastCanceledRegistration.users[0].first_name,
                    lastName: lastCanceledRegistration.users[0].last_name,
                  })
                : t('modals.cancelEventRegistrationWaitList.alertMessage', {
                    firstName: lastCanceledRegistration.users[0].first_name,
                    lastName: lastCanceledRegistration.users[0].last_name,
                  })
            }
            type="success"
          />
        ) : null}
        {!eventRegistrationList.isFetchedAfterMount ? (
          <Loading />
        ) : (
          <div>
            <div className="event-edit-table-out-div">
              <h2 className="table-title-event-edit">
                {t('editEvent.registrations.attendeesTitle')}
                <Icon
                  className={classNames(['nav-arrow', collapsed ? 'collapsed' : 'expanded'])}
                  icon="ChevronDown"
                  onClick={e => {
                    setcollapsed(!collapsed);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </h2>
              {collapsed ? (
                <div>
                  <table className="table-units-list attend-table">
                    <thead>
                      <tr>
                        <th>{t('editEvent.registrations.resName')}</th>
                        <th>{t('editEvent.registrations.resDate')}</th>
                        <th>{t('editEvent.registrations.attendees')}</th>
                        <th>{t('editEvent.registrations.status')}</th>
                        <th>{/* Kebab */}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventRegistrationList.data.map(eventRegistration => (
                        <EventRegistrantItem
                          key={eventRegistration.id}
                          eventRegistration={eventRegistration}
                          openKebabMenu={() => setKebabMenuOpenForRegistrationId(eventRegistration.id)}
                          isKebabMenuOpen={kebabMenuOpenForRegistrationId === eventRegistration.id}
                          closeKebabMenu={() => setKebabMenuOpenForRegistrationId(null)}
                          onCancelRegistration={() => {
                            setCancelType('Attend');
                            setCancelModalRegistration(eventRegistration);
                          }}
                          cancelEventRegistrationPermission={
                            !hasEventPassed && userPermission.Cancel_Event_Registration
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )}

        {event.waitlist_allowed && (
          <>
            {!eventWaitList.isFetchedAfterMount ? (
              <Loading />
            ) : (
              <div style={{ marginBottom: '150px' }}>
                <div className="event-edit-table-out-div">
                  <h2 className="table-title-event-edit">
                    {t('editEvent.registrations.waitListTitle')}
                    <Icon
                      className={classNames(['nav-arrow', collapsed2 ? 'collapsed' : 'expanded'])}
                      icon="ChevronDown"
                      onClick={e => {
                        setcollapsed2(!collapsed2);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    />
                  </h2>
                  {collapsed2 ? (
                    <div>
                      <table className="table-units-list waitlist-table">
                        <thead>
                          <tr>
                            <th>{t('editEvent.registrations.resName')}</th>
                            <th>{t('editEvent.registrations.resDate')}</th>
                            <th>{t('editEvent.registrations.attendees')}</th>
                            <th>{t('editEvent.registrations.status')}</th>
                            <th>{/* Kebab */}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eventWaitList.data.map(eventRegistration => (
                            <EventWaitListItem
                              key={eventRegistration.id}
                              eventRegistration={eventRegistration}
                              openKebabMenu={() => setkebabMenuOpenForWaitListId(eventRegistration.id)}
                              isKebabMenuOpen={kebabMenuOpenForWaitListId === eventRegistration.id}
                              closeKebabMenu={() => setkebabMenuOpenForWaitListId(null)}
                              onCancelWaitList={() => {
                                setCancelType('WaitList');
                                setCancelModalRegistration(eventRegistration);
                              }}
                              onMoveToTop={() => {
                                moveToTop.mutate(eventRegistration.id, {
                                  onSuccess: () => {
                                    refetchEventWaitList();
                                  },
                                  onError: e => {
                                    refetchEventWaitList();
                                  },
                                });
                              }}
                              onCancelPending={() => {
                                setCancelType('Pending');
                                setCancelModalRegistration(eventRegistration);
                              }}
                              cancelEventRegistrationPermission={
                                !hasEventPassed && userPermission.Cancel_Event_Registration
                              }
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);
