import { connect } from 'react-redux';
import PropertyLayout from './PropertyLayout';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { propertySelector, unitSelector } from '../../views/Property/redux/selectors';
import { getUserPermission } from '../../redux/selectors';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    activeProperty: propertySelector(state),
    activeUnit: unitSelector(state),
    userPermissionList: getUserPermission(state),
    user: state.user,
  };
}

const PropertyLayoutContainer = compose(
  connect(mapStateToProps),
  withRouter
)(withTranslation('navigation', { keyPrefix: 'propertyNavigation' })(PropertyLayout));

export default PropertyLayoutContainer;
