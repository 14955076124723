import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Yup from 'yup';

import InputScaffold from './InputScaffold';
import AlertMessage from '../AlertMessage';
import { trimAndSetNullFalsyValues } from '../utils/helpers';
import Delete from '../Delete';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .max(120, 'Message title must be 120 characters or less.')
    .required('Please enter an announcement title'),
  description: Yup.string().trim(),
});

class AnnouncementDetailsForm extends Component {
  handleCheckboxToggle = (setFieldValue, values, e) => {
    const groupsArr = values.groups;

    if (e.target.checked) {
      groupsArr.push(e.target.value);
    } else {
      groupsArr.splice(groupsArr.indexOf(e.target.value), 1);
    }
    if (groupsArr.length === 0) {
      setFieldValue('all_residents_selected', true);
    } else {
      setFieldValue('all_residents_selected', false);
    }
    setFieldValue('groups', groupsArr);
  };
  toggleAllResidents = setFieldValue => {
    setFieldValue('groups', []);
    setFieldValue('all_residents_selected', true);
  };
  handleCancel = () => this.props.history.goBack();
  render() {
    const {
      initialValues,
      submitFunction,
      groupOptions,
      createdAt,
      editMode,
      removeMessage,
      toggleRemove,
      showRemove,
      saveRemoveAnnouncementPermission,
      t,
    } = this.props;
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => submitFunction(trimAndSetNullFalsyValues(values))}
        render={({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="paper radius-top-left radius-top-right">
                <section>
                  <div className="section-header h4">{t('announcementDetailsTitle')}</div>
                  <p>{createdAt ? `${t('postedAt')} ${createdAt}` : ''}</p>
                  <InputScaffold label={t('fieldTitleAnnouncement')} required validation={errors.title}>
                    <input type="text" name="title" maxLength="120" onChange={handleChange} value={values.title} />
                  </InputScaffold>

                  <InputScaffold label={t('fieldAnnouncementDescription')} validation={errors.description}>
                    <textarea name="description" onChange={handleChange} value={values.description} />
                  </InputScaffold>

                  <div className="checkbox-list">
                    <label className="label">{t('labelGroups')}</label>
                    <div className="checkbox-list--item">
                      <label>
                        <input
                          type="checkbox"
                          checked={values.all_residents_selected}
                          disabled={values.all_residents_selected}
                          onChange={this.toggleAllResidents.bind(this, setFieldValue)}
                          name="all-residents"
                        />
                        {t('allResidents')}
                      </label>
                    </div>

                    {groupOptions.map(option => {
                      return (
                        <div key={option.value} className="checkbox-list--item">
                          <label>
                            <input
                              onChange={this.handleCheckboxToggle.bind(this, setFieldValue, values)}
                              type="checkbox"
                              checked={values.groups.indexOf(option.value) >= 0}
                              value={option.value}
                            />
                            {option.label}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </section>
                <AlertMessage icon msg={t('helperAnnouncementText')} />
              </div>

              {showRemove ? (
                <div
                  className="container paper__footer radius-bottom-left radius-bottom-right"
                  style={{ display: 'block' }}
                >
                  <Delete
                    message={
                      <span>
                        {t('deletePopupMassage_1')} <strong className="strong">{t('deletePopupMassage_2')}</strong>?
                      </span>
                    }
                    onCancel={toggleRemove}
                    deleteFunc={removeMessage}
                    inline
                  />
                </div>
              ) : (
                <div className="container paper__footer radius-bottom-left radius-bottom-right">
                  {editMode ? (
                    <>
                      {saveRemoveAnnouncementPermission && (
                        <button disabled={isSubmitting || Object.keys(errors).length} type="submit" className="button">
                          {t('saveAnnouncementBtn')}
                        </button>
                      )}
                    </>
                  ) : (
                    <button disabled={isSubmitting || Object.keys(errors).length} type="submit" className="button">
                      {t('postAnnouncementBtn')}
                    </button>
                  )}

                  <button type="button" className="button button--secondary" onClick={this.handleCancel}>
                    {t('cancelAnnouncementBtn')}
                  </button>

                  {editMode ? (
                    <>
                      {saveRemoveAnnouncementPermission && (
                        <button className="button button--secondary--destructive" type="button" onClick={toggleRemove}>
                          {t('removeAnnouncementBtn')}
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              )}
            </div>
          </form>
        )}
      />
    );
  }
}

AnnouncementDetailsForm.propTypes = {
  editMode: PropTypes.bool,
  createdAt: PropTypes.string,
  showRemove: PropTypes.bool,
  toggleRemove: PropTypes.func,
  removeMessage: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  submitFunction: PropTypes.func.isRequired,
  groupOptions: PropTypes.array,
  t: PropTypes.func.isRequired,
};
export default withTranslation('announcements')(withRouter(AnnouncementDetailsForm));
