import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Yup from 'yup';
import Credentials from '../../../../../../../../../../../common/forms/Credentials';
import { getCredentialTypes } from '../../../../../../../../../../../redux/actions';
import { selectCredentialTypesForProperty } from '../../../../../../../../../redux/selectors';
import { selectActiveResident } from '../../../../../redux/selectors';
import { uniqueProperty } from '../../../../../../../../../../../common/utils/yup-extensions';
import { Prompt } from 'react-router-dom';
import { updateResidentCommunityCredentials } from '../../../../../redux/actions';
import { trimAndSetNullFalsyValues } from '../../../../../../../../../../../common/utils/helpers';
import NotificationStatusIndicator from '../../../../../../../../../../../common/forms/NotificationStatusIndicator';
import NoCredentialTypesPlaceholder from '../../../../../../../../../common/NoCredentialTypesPlaceholder';
import { withTranslation } from 'react-i18next';

Yup.addMethod(uniqueProperty.schemaConstructor, uniqueProperty.name, uniqueProperty.method);
const ResidentCredentialsComponent = ({
  credentialTypes,
  getCredentialTypes,
  resident,
  updateResidentCommunityCredentials,
  t,
}) => {
  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    getCredentialTypes();
  }, [getCredentialTypes]);

  const validationSchema = Yup.object().shape({
    property_credentials: Yup.array().of(
      Yup.object()
        .shape({
          code: Yup.string()
            .trim()
            .length(5, 'Credential code must be 5 characters')
            .required(t('edit.credentialsTab.errorRequired'))
            .test('is-valid-range', 'Code must be between 00000 and 65535', (val, context) => {
              return !val || (!isNaN(Number(val)) && Number(val) >= 0 && Number(val) <= 65535);
            }),
          credential_type_id: Yup.string()
            .trim()
            .required('Please select a credential type'),
          note: Yup.string()
            .trim()
            .nullable()
            .max(255, 'Credential note must be 255 characters or less'),
        })
        .uniqueProperty('code', 'Code must be unique')
    ),
  });

  if (
    (!resident.get('property_credentials') || !resident.get('property_credentials').size) &&
    (!credentialTypes || !credentialTypes.length)
  ) {
    return (
      <div className="container">
        <div className="paper radius-top-left radius-top-right">
          <NoCredentialTypesPlaceholder />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <Formik
        initialValues={{
          property_credentials: resident.get('property_credentials') ? resident.get('property_credentials').toJS() : [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values, formActions) => {
          return updateResidentCommunityCredentials(
            resident.get('id'),
            values.property_credentials.map(trimAndSetNullFalsyValues)
          ).then(action => {
            if (action.response.ok) {
              formActions.resetForm({ values: { property_credentials: action.json } });
              setSubmitMessage(t('edit.credentialsTab.success'));
              setSubmitStatus('Success');
            } else {
              formActions.setSubmitting(false);
              setSubmitMessage(action.json.message || t('edit.credentialsTab.error'));
              setSubmitStatus('Failure');
            }
          });
        }}
      >
        {({ dirty, values, errors, handleSubmit, touched, setFieldValue, isSubmitting, isValid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Prompt when={dirty} message={t('edit.credentialsTab.confirmLeaving')} />
              <div className="paper radius-top-left radius-top-right">
                <Credentials
                  property_credentials={values.property_credentials}
                  credentialTypes={credentialTypes}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  accessControlProfileId1={resident.get('access_control_profile_id1')}
                  accessControlProfileId2={resident.get('access_control_profile_id2')}
                  accessControlProfileId3={resident.get('access_control_profile_id3')}
                  accessControlProfileId4={resident.get('access_control_profile_id4')}
                />
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <button
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length || !isValid}
                  className="button"
                >
                  {isSubmitting ? t('edit.credentialsTab.saving') : t('edit.credentialsTab.save')}
                </button>
                <NotificationStatusIndicator
                  hideNotification={!submitMessage}
                  message={submitMessage}
                  type={submitStatus}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export const ResidentCredentials = connect(
  (state, props) => ({
    credentialTypes: selectCredentialTypesForProperty(state, props),
    resident: selectActiveResident(state, props.match.params.residentId),
  }),
  { getCredentialTypes, updateResidentCommunityCredentials }
)(withTranslation('residents')(ResidentCredentialsComponent));
