import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { Formik } from 'formik';
import Yup from 'yup';
import { Label } from 'synfrastructure';
import TimePicker from 'rc-time-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import PropertyPageHeader from '../../../../../../../../common/PropertyPageHeader';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import NotificationStatusIndicator from '../../../../../../../../common/forms/NotificationStatusIndicator';
import { postTypeOptions } from '../../postTypeOptions';
import { DiscussionImageInput } from './DiscussionImageInput';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Icon from '../../../../../../../../common/icons/icon';
import { cloneDeep } from 'lodash';
import { normalizeUrl } from '../../../../../../../../common/utils/helpers';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const startTime = moment()
  .add(1, 'days')
  .hour(0)
  .minute(0)
  .second(0);
const endTime = moment()
  .add(2, 'days')
  .hour(12)
  .minute(0)
  .second(0);

class CreatePost extends Component {
  state = {
    error: null,
    selectedPostType: 'general',
    headline: '',
    body: '',
    images: [],
  };

  constructor(props) {
    super(props);
    this.t = props.t;
  }

  getInitialValues() {
    const initialValues = {
      headline: this.state.headline,
      body: this.state.body,
      images: [],
    };

    switch (this.state.selectedPostType) {
      case 'general':
        initialValues.link = '';
        break;
      case 'for-sale':
        initialValues.price = '';
        break;
      case 'event':
        initialValues.link = '';
        initialValues.start_time = startTime;
        initialValues.end_time = endTime;
        initialValues.location = '';
        break;
      case 'recommendation':
        initialValues.type = 'request';
        break;
      default:
        break;
    }

    return initialValues;
  }

  getValidationSchema() {
    const schema = {
      headline: Yup.string()
        .trim()
        .max('120', 'Must be 120 characters or less')
        .required('Please enter a headline'),
      body: Yup.string()
        .trim()
        .max('400', 'Must be 400 characters or less')
        .required('Please enter a body'),
    };

    const linkSchema = Yup.string()
      .transform((currentValue, originalValue) => {
        return normalizeUrl(originalValue);
      })
      .url('Must be a valid URL')
      .trim()
      .max('2048', 'Must be 2048 characters or less');

    switch (this.state.selectedPostType) {
      case 'general':
        schema.link = linkSchema;
        break;
      case 'for-sale':
        schema.price = Yup.number()
          .required('Please enter a price')
          .min(0, "Price can't be negative")
          .integer('Whole dollar amounts only');
        break;
      case 'event':
        schema.start_time = Yup.date()
          .required('Start time is required')
          .test('end_time', 'Start time must be before end time', function(value) {
            return !moment(value, 'ddd MMM DD Y HH:mm:ss zZZ').isSameOrAfter(
              moment(this.parent.end_time, 'ddd MMM DD Y HH:mm:ss zZZ')
            );
          });
        schema.end_time = Yup.date()
          .required('End time is required')
          .test('start_time', 'End time must be after start time', function(value) {
            return !moment(this.parent.start_time, 'ddd MMM DD Y HH:mm:ss zZZ').isSameOrAfter(
              moment(value, 'ddd MMM DD Y HH:mm:ss zZZ')
            );
          });
        schema.link = linkSchema;
        schema.location = Yup.string()
          .trim()
          .max('120', 'Must be 120 characters or less');
        break;
      default:
        break;
    }

    return Yup.object().shape(schema);
  }

  renderLinkField(formikProps) {
    const { values, touched, errors, handleChange } = formikProps;
    return (
      <InputScaffold label={this.t('LINK')} validation={touched.link && errors.link}>
        <input type="text" name="link" onChange={handleChange} value={values.link} />
      </InputScaffold>
    );
  }

  renderDayPicker(formikProps, label, namePrefix) {
    const name = `${namePrefix}_date`;
    const { setFieldValue, values, errors } = formikProps;
    return (
      <InputScaffold label={this.t(label.toUpperCase())} required validation={errors[name]}>
        <DayPickerInput
          inputProps={{
            autoComplete: 'false',
          }}
          name={name}
          value={
            values[`${namePrefix}_time`] ? moment(values[`${namePrefix}_time`]).format(this.t('MMM DD, YYYY')) : null
          }
          placeholder="From"
          format={this.t('MMM DD, YYYY')}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            numberOfMonths: 1,
            disabledDays: date => moment(date).isBefore(moment().subtract(1, 'day')),
          }}
          onDayChange={date => {
            const currentTime = values[`${namePrefix}_time`].clone();
            const selectedDate = moment(date);
            currentTime.year(selectedDate.year());
            currentTime.month(selectedDate.month());
            currentTime.date(selectedDate.date());
            setFieldValue(`${namePrefix}_time`, currentTime);
          }}
        />
      </InputScaffold>
    );
  }

  renderTimePicker(formikProps, label, namePrefix) {
    const name = `${namePrefix}_time`;
    const { errors, values, setFieldValue } = formikProps;
    return (
      <InputScaffold label={this.t(label.toUpperCase())} required validation={errors[name]}>
        <TimePicker
          name={name}
          showSecond={false}
          value={values[name]}
          onChange={time => {
            let existingDate = values[name];
            if (existingDate) {
              let newDate = moment(existingDate).clone();
              newDate.hour(time.hours());
              newDate.minute(time.minutes());
              setFieldValue(`${namePrefix}_time`, moment(newDate));
            } else {
              setFieldValue(`${namePrefix}_time`, moment(time));
            }
          }}
          format={this.t('h:mm a')}
          allowEmpty={false}
          use12Hours
          inputReadOnly
          className="time-picker"
        />
      </InputScaffold>
    );
  }

  renderTypeSpecificFields(formikProps) {
    const { touched, errors, values, handleChange, setFieldValue } = formikProps;
    switch (this.state.selectedPostType) {
      case 'general':
        return this.renderLinkField(formikProps);
      case 'for-sale':
        return (
          <InputScaffold label={this.t('PRICE (IN DOLLARS)')} required validation={touched.price && errors.price}>
            <input type="number" name="price" value={values.price} onChange={handleChange} />
          </InputScaffold>
        );
      case 'recommendation':
        return (
          <Fragment>
            <div className="input-scaffold">
              <Label className="input">
                <span className="input-label">{this.t('TYPE')}</span>
              </Label>
              <Select
                value={values.type}
                clearable={false}
                closeOnSelect={true}
                name="search"
                onChange={type => setFieldValue('type', type.value)}
                options={[
                  { value: 'request', label: this.t('Request') },
                  { value: 'offer', label: this.t('Offer') },
                ]}
              />
            </div>
          </Fragment>
        );
      case 'event':
        return (
          <Fragment>
            {this.renderDayPicker(formikProps, 'Start Date', 'start')}
            {this.renderTimePicker(formikProps, 'Start Time', 'start')}
            {this.renderDayPicker(formikProps, 'End Date', 'end')}
            {this.renderTimePicker(formikProps, 'End Time', 'end')}
            <InputScaffold label={this.t('LOCATION')} validation={touched.location && errors.location}>
              <input type="text" name="location" value={values.location} onChange={handleChange} maxLength="120" />
            </InputScaffold>
            {this.renderLinkField(formikProps)}
          </Fragment>
        );
      default:
        return null;
    }
  }

  renderDiscussionImagesList = (images, onChangeImages) => {
    return (
      <DragDropContext
        onDragEnd={result => {
          const wasDroppedOutsideList = !result.destination;
          if (wasDroppedOutsideList || result.destination.index === result.source.index) {
            return;
          }

          onChangeImages(reorder(images, result.source.index, result.destination.index));
        }}
      >
        <Droppable droppableId="droppable" direction="horizontal">
          {provided => (
            <div className="image-upload__list" ref={provided.innerRef} {...provided.droppableProps}>
              {images.map((image, index) => (
                <Draggable
                  key={image.id || image.tempId}
                  draggableId={image.id || image.tempId}
                  index={index}
                  isDragDisabled={images.length === 1}
                >
                  {({ draggableProps, dragHandleProps, innerRef }) => (
                    <div ref={innerRef} {...draggableProps}>
                      <div className="amenity__draggable--img-preview" style={{ backgroundImage: `url(${image.url})` }}>
                        <div className="draggable-actions__container">
                          {images.length > 1 && (
                            <div className="image-upload__drag-handle" {...dragHandleProps}>
                              <Icon icon="HorizontalDragDrop" />
                            </div>
                          )}
                          <div
                            className="image-upload__remove"
                            onClick={() => onChangeImages([...images.slice(0, index), ...images.slice(index + 1)])}
                          >
                            <Icon icon="TrashOutline" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  renderImages = formikProps => {
    const { values, setFieldValue } = formikProps;
    return (
      <div>
        <div>
          <h4 className="section-header h4">{this.t('Upload Discussion Post Images')}</h4>
          <p className="help-text tighter">{this.t('Upload an image or multiple to show off this Discussion Post')}</p>
          <DiscussionImageInput
            images={values.images}
            onChangeImages={images => {
              setFieldValue('images', images);
            }}
          />
        </div>
        {values.images.length > 0 && (
          <div style={{ marginTop: 10 }}>
            <h4 className="section-header h4">Discussion Images</h4>
            <p className="help-text tighter">
              You can reorder the images in the order you want them to appear in the app or remove them. Only a max of 5
              images are allowed.
            </p>
            {this.renderDiscussionImagesList(values.images, images => setFieldValue('images', images))}
          </div>
        )}
      </div>
    );
  };

  renderForm() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues()}
        validationSchema={this.getValidationSchema()}
        onSubmit={(values, actions) => {
          if (values.link) {
            values.link = normalizeUrl(values.link);
          }

          const submitValues = cloneDeep(values);

          submitValues.image_data = submitValues.images.map((image, index) => {
            return {
              image_url: image.url,
              sort_order: index + 1,
            };
          });
          delete submitValues.images;

          return this.props.createPost(this.state.selectedPostType, submitValues).then(action => {
            if (action.response.ok) {
              this.props.history.goBack();
            } else {
              this.setState({ error: action.json.message || 'Unable to submit at this time' });
              actions.setSubmitting(false);
            }
          });
        }}
      >
        {formikProps => {
          const { isSubmitting, values, touched, errors, handleSubmit, handleBlur, handleChange } = formikProps;
          const translatedPostTypeOptions = postTypeOptions.map(({ value, label }) => ({
            value,
            label: this.t(label),
          }));
          return (
            <form onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius-top-right paper__grid">
                <div className="paper__section--grid-item">
                  <section className="paper__section">
                    <InputScaffold label={this.t('SELECT POST TYPE')}>
                      <Select
                        clearable={false}
                        closeOnSelect={true}
                        placeHolder={'Select Post Type'}
                        name="search"
                        searchable={false}
                        value={this.state.selectedPostType}
                        onChange={value =>
                          this.setState({ selectedPostType: value.value, headline: values.headline, body: values.body })
                        }
                        options={translatedPostTypeOptions}
                      />
                    </InputScaffold>
                    <InputScaffold label={this.t('HEADLINE')} required validation={touched.headline && errors.headline}>
                      <input
                        type="text"
                        name="headline"
                        value={values.headline}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength="120"
                      />
                    </InputScaffold>
                    <InputScaffold label={this.t('BODY')} required validation={touched.body && errors.body}>
                      <textarea
                        type="text"
                        name="body"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.body}
                        maxLength="400"
                      />
                    </InputScaffold>
                    {this.renderTypeSpecificFields(formikProps)}
                    <div className="create-post--button__wrapper">
                      <button type="submit" disabled={isSubmitting} className="button">
                        {this.t('Submit')}
                      </button>
                      <NotificationStatusIndicator
                        hideNotification={!this.state.error}
                        message={this.state.error}
                        type="Failure"
                      />
                    </div>
                  </section>
                </div>

                <div className="paper__section--grid-item">
                  <section className="paper__section">{this.renderImages(formikProps)}</section>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <div>
        <PropertyPageHeader title={this.t('Discussion Board')} backLink="./" />
        <div className="container paper radius-top-left radius-top-right">{this.renderForm()}</div>
      </div>
    );
  }
}

export default withTranslation('discussions')(CreatePost);
