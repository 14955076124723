import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Loading from '../Loading/SpinnerLoading';
import ModalAlert from '../ModalAlert';
import { useTranslation } from 'react-i18next';

const AccessCode = ({
  code,
  codePlaceholder,
  disabled,
  disabledText,
  error,
  hasCode,
  isError,
  isLoading,
  isSuccess,
  name,
  noCodePlaceholder,
  onGenerate,
  onRemove,
  onReset,
  onSendReminder,
  onToggleShow,
  userPermission,
}) => {
  const [isCodeShowing, setCodeShowing] = useState(false);
  const [isConfirmRemoveCodeModalShowing, setConfirmRemoveCodeModalShowing] = useState(false);
  const { t } = useTranslation('residents', { keyPrefix: 'edit.accessCodesTab' });
  const { t: tCommon } = useTranslation('buttons');

  useEffect(() => {
    if (isCodeShowing && !hasCode) {
      setCodeShowing(false);
    }
  }, [hasCode, isCodeShowing]);

  let actionButtons;
  if (!hasCode) {
    actionButtons = [
      <button className="button" type="button" key="generate" disabled={disabled} onClick={onGenerate}>
        {t('generate')}
      </button>,
    ];
  } else {
    actionButtons = [
      userPermission.showUnhideUsersAccessCodes
        ? [
            <button
              className="button"
              type="button"
              key="show-hide"
              disabled={disabled || !userPermission.showUnhideUsersAccessCodes}
              onClick={() => {
                if (onToggleShow) onToggleShow(!isCodeShowing);
                setCodeShowing(!isCodeShowing);
              }}
            >
              {!isCodeShowing ? t('show') : t('hide')}
            </button>,
          ]
        : null,
      userPermission.resendResidentsAccessCodes
        ? [
            <button
              className="button"
              type="button"
              key="remind"
              disabled={disabled || !userPermission.resendResidentsAccessCodes}
              onClick={onSendReminder}
            >
              {t('sendReminder')}
            </button>,
          ]
        : null,
      userPermission.resetAResidentsAccessCodes
        ? [
            <button
              className="button"
              type="button"
              key="reset"
              disabled={disabled || !userPermission.resetAResidentsAccessCodes}
              onClick={onReset}
            >
              {t('reset')}
            </button>,
          ]
        : null,
      userPermission.removeAResidentsAccessCodes
        ? [
            <button
              className="button button--destructive"
              type="button"
              key="remove"
              disabled={disabled || !userPermission.removeAResidentsAccessCodes}
              onClick={() => setConfirmRemoveCodeModalShowing(true)}
            >
              {t('remove')}
            </button>,
          ]
        : null,
    ];
  }

  let middleText;
  if (disabled) {
    middleText = disabledText;
  } else if (!hasCode) {
    middleText = noCodePlaceholder;
  } else if (!isCodeShowing) {
    middleText = codePlaceholder;
  } else {
    middleText = code;
  }

  const accessCodeClasses = {
    'access-code': true,
    'access-code--error': isError && !isLoading,
    'access-code--success': isSuccess && !isLoading && !isError,
    'access-code--is-loading': isLoading,
  };

  return (
    <div className="access-code__wrapper">
      <div className={cn(accessCodeClasses)}>
        <ModalAlert
          title={t('modalAlert.title')}
          body={t('modalAlert.body')}
          confirmButtonTitle={t('modalAlert.confirmButtonTitle')}
          cancelButtonTitle={tCommon('cancel')}
          handleConfirm={() => {
            onRemove();
            setConfirmRemoveCodeModalShowing(false);
          }}
          handleCancel={() => {
            setConfirmRemoveCodeModalShowing(false);
          }}
          visible={isConfirmRemoveCodeModalShowing}
        />
        {isLoading ? (
          <div className="access-code-loading">
            <Loading />
          </div>
        ) : null}
        <div className="access-code__name">{name}</div>
        <div className={cn('access-code__middle-text', isCodeShowing && 'hidden-code')}>{middleText}</div>
        <div className="access-code__action-buttons">{actionButtons}</div>
      </div>
      {error ? <div className="access-code-error-message">{error.message}</div> : null}
    </div>
  );
};

export const UnitAccessCode = props => {
  const { t } = useTranslation('residents', { keyPrefix: 'edit.accessCodesTab.unit' });

  return (
    <AccessCode
      name={t('name')}
      disabledText={t('disabledText')}
      codePlaceholder="&#x2731;&#x2731;&#x2731;&#x2731;&#x2731;&#x2731;"
      noCodePlaceholder={t('noCodePlaceholder')}
      {...props}
    />
  );
};

export const CommunityAccessCode = props => {
  const { t } = useTranslation('residents', { keyPrefix: 'edit.accessCodesTab.community' });

  return (
    <AccessCode
      name={t('name')}
      codePlaceholder="&#x2731;&#x2731;&#x2731;&#x2731;&#x2731;&#x2731;"
      noCodePlaceholder={t('noCodePlaceholder')}
      {...props}
    />
  );
};
