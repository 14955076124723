import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import querystring from 'query-string';

import GroupsKebab from './GroupsKebab';
import GroupsForm from './GroupsForm';
import Delete from '../../../../../../common/Delete';
import { UnitTooltip } from '../../../Units/views/UnitsList/UnitTooltip';
import { withTranslation } from 'react-i18next';

class GroupItem extends Component {
  state = {
    mode: 'view',
    showRemove: false,
    showingMenu: false,
    shortenItem: true,
  };

  toggleShowingMenu = () => this.setState(state => ({ showingMenu: !state.showingMenu }));

  toggleRemove = () => {
    return this.setState(state => ({ showRemove: !state.showRemove, showingMenu: false }));
  };

  switchMode = mode => this.setState(state => ({ mode }));

  handleRemoveGroup = () => this.props.removeGroups(this.props.data.get('property_id'), this.props.data.get('id'));

  handleCancel = () => this.switchMode('view');

  render() {
    const { createGroups, data, editGroups, validationSchema, t } = this.props;
    const { mode, showingMenu, showRemove } = this.state;
    const querySearch = {
      group_id: data.get('id'),
      sectionName: data.get('name'),
    };
    const unitName = data.get('name');

    const display = (
      <tr>
        <td>
          <UnitTooltip offset={[0, 10]} placement="bottom-start" content={unitName} disabled={unitName.length <= 20}>
            <Link
              to={{
                pathname: `/properties/${data.get('property_id')}/units/unit-list`,
                search: querystring.stringify(querySearch),
              }}
            >
              {unitName.length > 20 ? `${unitName.slice(0, 20)}...` : unitName}
            </Link>
          </UnitTooltip>
        </td>
        <td>
          <span className={data.get('units').size > 0 ? 'text-primary' : 'font-light'}>{data.get('units').size}</span>
        </td>
        <td className="group-item__actions">
          {showRemove ? (
            <Delete
              message={<span dangerouslySetInnerHTML={{ __html: t('item.removeConfirmation') }} />}
              deleteFunc={this.handleRemoveGroup}
              onCancel={this.toggleRemove}
            />
          ) : (
            <GroupsKebab
              toggleShowingMenu={this.toggleShowingMenu}
              showingMenu={showingMenu}
              switchMode={this.switchMode}
              toggleRemove={this.toggleRemove}
              isItemShort={this.state.shortenItem}
            />
          )}
        </td>
      </tr>
    );

    return mode === 'edit' ? (
      <tr>
        <GroupsForm
          getGroups={this.props.getGroups}
          data={data}
          mode={mode}
          editGroups={editGroups}
          createGroups={createGroups}
          showingMenu={showingMenu}
          switchMode={this.switchMode}
          toggleRemove={this.toggleRemove}
          toggleShowingMenu={this.toggleShowingMenu}
          toggleShortenItem={this.toggleShortenItem}
          validationSchema={validationSchema}
        />
      </tr>
    ) : (
      display
    );
  }
}

GroupItem.propTypes = {
  data: PropTypes.object.isRequired,
  createGroups: PropTypes.func.isRequired,
  removeGroups: PropTypes.func.isRequired,
  editGroups: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default withTranslation(['unitGroups'])(GroupItem);
