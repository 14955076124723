import Icon from '../icons/icon';
import { FieldArray } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function FormListItem({ children, handleRemoveItem }) {
  const { t } = useTranslation('buttons');

  return (
    <div className="form-list__item">
      <div className="form-list__input-row">{children}</div>
      <div className="form-list__action-row">
        <button type="button" className="button button--icon button--destructive" onClick={handleRemoveItem}>
          <div className="button--children">
            <Icon icon="Close" />
            <span>{t('remove')}</span>
          </div>
        </button>
      </div>
    </div>
  );
}

export function FormList({ addButtonDisabled, defaultItemValue, addButtonLabel, name, renderItem, items }) {
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        return (
          <>
            <div className="form-list__wrapper">
              {items.map(renderItem).map((item, index) => {
                if (React.isValidElement(item)) {
                  return React.cloneElement(item, {
                    key: `${name}-item-${index}`,
                    handleRemoveItem: () => arrayHelpers.remove(index),
                  });
                }
                return item;
              })}
            </div>
            <button
              disabled={addButtonDisabled}
              style={{ marginTop: '10px' }}
              type="button"
              className="button button--outline--primary button--icon"
              onClick={() => arrayHelpers.push(defaultItemValue)}
            >
              <div className="button--children">
                <Icon icon="Plus" />
                <span>{addButtonLabel || 'Add'}</span>
              </div>
            </button>
          </>
        );
      }}
    />
  );
}
