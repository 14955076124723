import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SubHeader from '../../../../../../../common/SubHeader';
import { useEventList, useRefetchEventList } from '../hooks';
import Loading from '../../../../../../../common/Loading';
import EmptyPlaceholder from '../../../../../../../common/EmptyPlaceholder';
import { EventListItem } from './EventListItem';
import AlertMessage from '../../../../../../../common/AlertMessage';
import { CancelEventModal } from '../CancelEventModal';
import { getCommunityStripeConnectAccount } from '../../../../../redux/actions/communityStripeIntegrationActions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserPermission } from '../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import { GenerateCSVBtn } from '../EditEvent/Registrations/GenerateCSVBtn';
import { useTranslation } from 'react-i18next';

export const EventList = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ match, history, userPermissionList }) => {
    const userPermission = {
      Add_Event: userPermissionList.includes('ADD_EVENT'),
      'Edit/Cancel Event': userPermissionList.includes('EDIT/CANCEL_EVENT'),
    };
    const [kebabMenuOpenForEventId, setKebabMenuOpenForEventId] = useState(null);
    const [cancelModalEvent, setCancelModalEvent] = useState(null);
    const [canceledEventTitle, setCanceledEventTitle] = useState(history.location.state?.canceledEventTitle || null);
    const eventList = useEventList(match.params.propertyId);
    const refetchEventList = useRefetchEventList(match.params.propertyId);
    const dispatch = useDispatch();
    const communityStripeConnectAccount = useSelector(state => state.property.communityStripeConnectAccount);
    const communityStripeConnectAccountLoaded = useSelector(
      state => state.property.communityStripeConnectAccountLoaded
    );
    const stripeConnectAccountConnected =
      communityStripeConnectAccount && communityStripeConnectAccount.get('connected');
    const { t } = useTranslation('events');

    useEffect(() => {
      dispatch(getCommunityStripeConnectAccount(match.params.propertyId));
    }, [dispatch, match.params.propertyId]);

    const StripeAccountNotConnectedAlert = () => (
      <AlertMessage
        type="error"
        icon
        msg={
          <>
            {t('main.noStripeError.part_1_created')}
            <Link to={`/properties/${match.params.propertyId}/property-details/settings`}>
              {t('main.noStripeError.part_2')}
            </Link>{' '}
            {t('main.noStripeError.part_3')}
          </>
        }
      />
    );

    if (!eventList.isFetchedAfterMount) {
      return <Loading />;
    }

    if (!eventList.data?.length) {
      return (
        <div className="container">
          <SubHeader title={t('main.subHeader')} />
          <EmptyPlaceholder
            buttonText={t('main.addEventBtn')}
            linkTo={userPermission.Add_Event ? `${match.url}/add` : ''}
          />
        </div>
      );
    }

    return (
      <div className="container">
        <CancelEventModal
          eventId={cancelModalEvent?.id}
          visible={!!cancelModalEvent}
          onCancel={() => {
            refetchEventList().then(() => {
              setCanceledEventTitle(cancelModalEvent?.title);
              setCancelModalEvent(null);
            });
          }}
          onClose={() => {
            setCancelModalEvent(null);
          }}
        />
        <SubHeader
          title={t('main.subHeader')}
          actions={[
            <>
              {userPermission['Edit/Cancel Event'] && (
                <GenerateCSVBtn
                  title={t('main.csvBtn')}
                  filePath={`events/${match.params.propertyId}/events-csv-list.csv`}
                  style={{ marginRight: '15px' }}
                />
              )}
              {userPermission.Add_Event && (
                <Link className="button" to={`${match.url}/add`}>
                  {t('main.addEventBtn')}
                </Link>
              )}
            </>,
          ]}
        />
        {canceledEventTitle ? (
          <AlertMessage icon type="success" msg={t('main.canceledEventAlert', { event: canceledEventTitle })} />
        ) : null}
        {communityStripeConnectAccountLoaded && !stripeConnectAccountConnected && <StripeAccountNotConnectedAlert />}
        <table className="table-units-list">
          <thead>
            <tr>
              <th className="first-column">{t('main.tabs.name')}</th>
              <th>{t('main.tabs.date')}</th>
              <th>{t('main.tabs.time')}</th>
              <th>{t('main.tabs.registrants')}</th>
              <th>{t('main.tabs.attendees')}</th>
              <th>{t('main.tabs.status')}</th>
              <th>{/* Kebab */}</th>
            </tr>
          </thead>
          <tbody>
            {eventList.data.map(event => (
              <EventListItem
                key={event.id}
                event={event}
                hasKebab
                isKebabMenuOpen={kebabMenuOpenForEventId === event.id}
                openKebabMenu={() => setKebabMenuOpenForEventId(event.id)}
                closeKebabMenu={() => setKebabMenuOpenForEventId(null)}
                onClickCancelEvent={setCancelModalEvent}
                pageContext="eventList"
                editCancelEventPermission={userPermission['Edit/Cancel Event']}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);
