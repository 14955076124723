import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getResidents, acceptOrDeclineDeviceSwitch, getAllResidents } from '../../../../redux/actions';
import ResidentList from './ResidentList';
import compose from 'lodash/flowRight';
import { getUserPermission } from '../../../../../../redux/selectors';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state, props) {
  return {
    propertyId: state.property.activeProperty.get('id'),
    property: state.property.activeProperty,
    residentsList: state.property.residentsList,
    residentsListTotal: state.property.residentsListTotal,
    loading: state.property.residentsListLoading,
    error: state.property.residentsListError,
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllResidents: propertyId => dispatch(getAllResidents(propertyId)),
    getResidents: (propertyId, searchTerm, page, pageSize) =>
      dispatch(getResidents(propertyId, searchTerm, page, pageSize)),
    acceptOrDeclineDeviceSwitch: (residentId, acceptOrDecline, propertyId) =>
      dispatch(acceptOrDeclineDeviceSwitch(residentId, acceptOrDecline, propertyId)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(withTranslation(['residents'])(ResidentList));
