import { connect } from 'react-redux';
import { editProperty, approvePendingPosts } from '../../../../redux/actions';
import ResidentFeatures from './ResidentFeatures';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    approvePendingPosts: propertyId => dispatch(approvePendingPosts(propertyId)),
    editProperty: (values, propertyId) => dispatch(editProperty(values, propertyId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    approvePendingPosts: () => dispatchProps.approvePendingPosts(stateProps.property.get('id')),
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withTranslation(['residentFeatures', 'buttons'])(ResidentFeatures));
