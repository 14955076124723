import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { CommunityAccessCode, UnitAccessCode } from '../../../../../../../../../../../common/AccessCodes/AccessCode';
import { setUnitAccessCodeOnResident, setCommunityAccessCodeOnResident } from '../../../../../redux/actions';
import { useUnitAccessCodeMutations } from '../../../../../../../../../../../common/AccessCodes/mutations/useUnitAccessCodeMutations';
import { useCommunityAccessCodeMutations } from '../../../../../../../../../../../common/AccessCodes/mutations/useCommunityAccessCodeMutations';
import SubHeader from '../../../../../../../../../../../common/SubHeader';
import { selectActiveResident, selectUnitHasSmartLock } from '../../../../../redux/selectors';
import { useUnitAccessCodeById } from '../../../../../../../../../../../common/AccessCodes/queries/useUnitAccessCodeById';
import { getUserPermission } from '../../../../../../../../../../../redux/selectors';
import { withTranslation } from 'react-i18next';

const ResidentAccessCodesComponent = ({
  resident,
  property,
  setUnitAccessCodeOnResident,
  setCommunityAccessCodeOnResident,
  unitHasSmartLock,
  userPermissionList,
  t,
}) => {
  const unitAccessCode = useUnitAccessCodeById(resident.get('unit_access_code_id'));
  const unitAccessCodeMutations = useUnitAccessCodeMutations(
    resident,
    'unit_access_code_id',
    setUnitAccessCodeOnResident,
    {
      residentId: resident.get('id'),
    }
  );
  const communityAccessCodeMutations = useCommunityAccessCodeMutations(
    resident,
    'property_access_code_id',
    setCommunityAccessCodeOnResident,
    {
      residentId: resident.get('id'),
    }
  );
  const userPermission = {
    showUnhideUsersAccessCodes: userPermissionList.includes("SHOW_(UNHIDE)_USER'S_ACCESS_CODES"),
    resendResidentsAccessCodes: userPermissionList.includes("RESEND_A_RESIDENT'S_ACCESS_CODES"),
    resetAResidentsAccessCodes: userPermissionList.includes("RESET_A_RESIDENT'S_ACCESS_CODES"),
    removeAResidentsAccessCodes: userPermissionList.includes("REMOVE_A_RESIDENT'S_ACCESS_CODES"),
    ChangResidentAccessControlProfile: userPermissionList.includes("CHANGE_A_RESIDENT'S_ACCESS_CONTROL_PROFILE"),
    EditResidentPersonalDetail: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
  };

  return (
    <div className="container">
      <div className="paper radius-top-left radius-top-right">
        <SubHeader underline title={t('edit.accessCodesTab.title')} />
        <UnitAccessCode
          code={get(unitAccessCode, 'data.code')}
          disabled={!unitHasSmartLock}
          hasCode={resident.get('unit_access_code_id')}
          error={unitAccessCode.error || unitAccessCodeMutations.error}
          isError={unitAccessCode.isError || unitAccessCodeMutations.isError}
          isLoading={unitAccessCode.isLoading || unitAccessCodeMutations.isLoading}
          isSuccess={unitAccessCodeMutations.isSuccess}
          onGenerate={unitAccessCodeMutations.create}
          onRemove={unitAccessCodeMutations.remove}
          onReset={unitAccessCodeMutations.resetCode}
          onSendReminder={unitAccessCodeMutations.sendReminder}
          onToggleShow={isShowing => (isShowing && unitAccessCode.isStale ? unitAccessCode.refetch() : null)}
          userPermission={userPermission}
        />
        {property.get('admin_enabled_access_control') ? (
          <CommunityAccessCode
            code={resident.getIn(['property_access_code', 'code'])}
            hasCode={resident.get('property_access_code_id')}
            error={communityAccessCodeMutations.error}
            isError={communityAccessCodeMutations.isError}
            isLoading={communityAccessCodeMutations.isLoading}
            isSuccess={communityAccessCodeMutations.isSuccess}
            onGenerate={communityAccessCodeMutations.create}
            onRemove={communityAccessCodeMutations.remove}
            onReset={communityAccessCodeMutations.resetCode}
            onSendReminder={communityAccessCodeMutations.sendReminder}
            userPermission={userPermission}
          />
        ) : null}
      </div>
    </div>
  );
};

export const ResidentAccessCodes = connect(
  (state, props) => {
    return {
      resident: selectActiveResident(state, props.match.params.residentId),
      property: state.property.activeProperty,
      unitHasSmartLock: selectUnitHasSmartLock(state),
      userPermissionList: getUserPermission(state),
    };
  },
  { setUnitAccessCodeOnResident, setCommunityAccessCodeOnResident }
)(withTranslation('residents')(ResidentAccessCodesComponent));
