import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Icon from '../../../../../../../../common/icons/icon';
import { useAmenityReservations, useRefetchAmenityReservationsById } from '../../hooks';
import Loading from '../../../../../../../../common/Loading';
import { ReservationListItem } from './ReservationsListItem';
import EmptyPlaceholder from '../../../../../../../../common/EmptyPlaceholder';
import SubHeader from '../../../../../../../../common/SubHeader';
import { CancelReservationModal } from './CancelReservationModal';
import { getUserPermission } from '../../../../../../../../redux/selectors';
import { useTranslation } from 'react-i18next';

export const Reservations = connect(state => ({
  community: state.property.activeProperty,
  userPermissionList: getUserPermission(state),
}))(({ amenity, community, userPermissionList }) => {
  const userPermission = {
    'Cancel Amenity Reservation': userPermissionList.includes('CANCEL_AMENITY_RESERVATION'),
  };
  const [page, setPage] = useState(0);
  const [kebabMenuOpenForAmenityReservationId, setKebabMenuOpenForAmenityReservationId] = useState(null);
  const [cancelModalReservation, setCancelModalReservation] = useState(null);

  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.reservations' });

  const reservations = useAmenityReservations(amenity.id, page, 30);
  const refetchReservations = useRefetchAmenityReservationsById(amenity.id, page, 30);

  const onCancelReservation = () => {
    refetchReservations();
  };

  if (reservations.status === 'loading') {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  if (reservations.status !== 'success') {
    return (
      <div className="container">
        <EmptyPlaceholder>
          <div className="amenities-list__empty-placeholder">
            <Icon icon="Amenity" />
            <p>{t('failedStatus')}</p>
          </div>
        </EmptyPlaceholder>
      </div>
    );
  }

  return (
    <div className="container">
      <CancelReservationModal
        onClose={() => {
          setCancelModalReservation(null);
        }}
        onCancelReservation={() => {
          refetchReservations();
          setCancelModalReservation(null);
        }}
        amenityId={amenity.id}
        reservationId={cancelModalReservation}
        visible={!!cancelModalReservation}
      />
      <SubHeader title={t('title')} />
      {reservations.data.results.length > 0 ? (
        <table className="table-units-list">
          <thead>
            <tr>
              <th className="first-column">{t('tableHeaders.residentName')}</th>
              <th>{t('tableHeaders.dateOfReservation')}</th>
              <th>{t('tableHeaders.timeOfReservation')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reservations.data.results.map(reservation => (
              <ReservationListItem
                key={reservation.id}
                communityTimezoneId={community?.get('timezone_id')}
                amenityId={amenity.id}
                reservation={reservation}
                isKebabMenuOpen={kebabMenuOpenForAmenityReservationId === reservation.id}
                openKebabMenu={() => setKebabMenuOpenForAmenityReservationId(reservation.id)}
                closeKebabMenu={() => setKebabMenuOpenForAmenityReservationId(null)}
                onCancelReservation={onCancelReservation}
                setCancelModalReservation={setCancelModalReservation}
                cancelAmenityReservationPermission={userPermission['Cancel Amenity Reservation']}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <EmptyPlaceholder>
          <div className="amenities-list__empty-placeholder">
            <Icon icon="Amenity" />
            <p>{t('noReservations')}</p>
          </div>
        </EmptyPlaceholder>
      )}

      {reservations.data.total > 0 && (
        <div className="paginate__wrapper">
          <ReactPaginate
            onPageChange={({ selected }) => setPage(selected)}
            initialPage={page}
            pageCount={
              reservations?.data?.pageSize ? Math.ceil(reservations.data.total / reservations.data.pageSize) : 1
            }
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            breakLabel="..."
            previousLabel={<Icon icon="ChevronLeft" />}
            nextLabel={<Icon icon="ChevronRight" />}
            disabledClassName={'paginate-disabled'}
            containerClassName={'paginate'}
            pageClassName={'paginate-page'}
            breakClassName={'paginate-break'}
            activeClassName={'paginate-active'}
          />
        </div>
      )}
    </div>
  );
});
