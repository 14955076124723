import React, { useState } from 'react';
import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import PageHeader from '../../../../../../../common/PageHeader';
import { AmenityDetails } from '../tabs/AmenityDetails';
import { AmenityAvailability } from '../tabs/AmenityAvailability';
import { Tab, TabsContainer } from '../../../../../../../common/Tabs';
import RouteLeavingGuard from '../../../../../../../common/RouteLeavingGuard';
import { ErrorBanner } from '../../../../../../../common/ErrorBanner';
import AlertMessage from '../../../../../../../common/AlertMessage';
import Icon from '../../../../../../../common/icons/icon';
import { AmenitySummaryModal } from '../AmenitySummaryModal';
import { addAmenityValidationSchema } from '../validationSchema';
import { useCreateAmenity } from '../hooks';
import { Images } from '../tabs/Images';
import moment from 'moment';
import { ReservationType } from '../tabs/ReservationType';
import { dollarsToCents } from '../../Events/util';
import { useTranslation } from 'react-i18next';

export const AddAmenity = ({ history, match }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isSummaryModalVisible, setSummaryModalVisible] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage' });
  const { t: tCommon } = useTranslation('buttons');

  const tabs = [
    {
      label: t('tabs.amenityDetails.title'),
      component: AmenityDetails,
      fieldsToShowTabErrorBadgeWhenInvalid: ['name', 'description'],
    },
    {
      label: t('tabs.images.title'),
      component: Images,
      fieldsToShowTabErrorBadgeWhenInvalid: [],
    },
    {
      label: t('tabs.availability.title'),
      component: AmenityAvailability,
      fieldsToShowTabErrorBadgeWhenInvalid: ['availability', 'availability_schedule'],
    },
    {
      label: t('tabs.reservationType.title'),
      component: ReservationType,
      fieldsToShowTabErrorBadgeWhenInvalid: [
        'reservation_type',
        'max_reservation',
        'min_reservation',
        'cancelation_policy',
        'refund_cutoff_time',
        'cancel_fee_percent',
        'refund_policy',
        'price',
      ],
    },
  ];

  const createAmenity = useCreateAmenity(match.params.propertyId);

  const RenderedComponent = tabs[currentTabIndex].component;

  return (
    <>
      <PageHeader
        title={t('title')}
        white
        withTabs
        backLink={`/properties/${match.params.propertyId}/community/amenities`}
      />
      <Formik
        initialValues={{
          name: '',
          description: '',
          max_occupancy: '',
          terms_of_use: '',
          images: [],
          availability: 'NONE',
          availability_schedule: null,
          reservation_type: 'NON_RESERVABLE',
          min_reservation: '',
          max_reservation: '',
          cancelation_policy: '',
          price: '',
          price_currency: 'USD',
          refund_cutoff_time: '',
          refund_policy: '',
          cancel_fee_percent: '',
        }}
        onSubmit={(formValues, formikActions) => {
          if (isSummaryModalVisible) {
            setSubmitError(null);
            setSummaryModalVisible(false);

            const submitValues = cloneDeep(formValues);

            submitValues.images_data = submitValues.images.map(image => image.url);
            delete submitValues.images;

            let maxReservationLength = 0;
            let biggestWindow = 0;

            if (submitValues.availability_schedule) {
              // format the moments into human readable times
              let newAvailabilitySchedule = {};
              const schedule = submitValues.availability_schedule;
              for (const day in submitValues.availability_schedule) {
                if (!schedule?.[day]?.open || !schedule?.[day]?.close) continue;

                const formattedOpen = moment(schedule[day].open).format('h:mma');
                const formattedClose = moment(schedule[day].close).format('h:mma');

                let duration = moment.duration(schedule[day].close.diff(schedule[day].open)).asMinutes();

                biggestWindow = Math.round(duration);

                if (biggestWindow > maxReservationLength) {
                  maxReservationLength = biggestWindow;
                }
                if (submitValues.max_reservation > maxReservationLength) {
                  submitValues.max_reservation = maxReservationLength;
                }

                newAvailabilitySchedule[day] = {
                  open: formattedOpen,
                  close: formattedClose,
                };
              }

              submitValues.availability_schedule = newAvailabilitySchedule;
            } else {
              delete submitValues.availability_schedule;
            }

            if (submitValues.reservation_type === 'NON_RESERVABLE') {
              submitValues.min_reservation = null;
              submitValues.max_reservation = null;
              submitValues.cancelation_policy = null;
            }

            if (submitValues.price) {
              submitValues.price = dollarsToCents(submitValues.price);
            } else {
              submitValues.price = 0;
            }

            if (submitValues.cancel_fee_percent) {
              submitValues.cancel_fee_percent = Number(submitValues.cancel_fee_percent);
            } else {
              submitValues.cancel_fee_percent = 0;
            }

            if (submitValues.refund_cutoff_time) {
              submitValues.refund_cutoff_time = Number(submitValues.refund_cutoff_time);
            } else {
              submitValues.refund_cutoff_time = null;
            }

            if (!submitValues.refund_policy) {
              delete submitValues.refund_policy;
            }

            if (submitValues.availability === 'NONE') {
              submitValues.reservation_type = 'NON_RESERVABLE';
            }

            createAmenity.mutate(
              {
                ...submitValues,
                max_occupancy: submitValues.max_occupancy || null,
                terms_of_use: submitValues.terms_of_use || null,
                min_reservation: submitValues.min_reservation || null,
                max_reservation: submitValues.max_reservation || null,
                cancelation_policy: submitValues.cancelation_policy || null,
              },
              {
                onSuccess: () => {
                  formikActions.resetForm();
                  history.push(`/properties/${match.params.propertyId}/community/amenities`);
                },
                onError: e => {
                  formikActions.setSubmitting(false);
                  setSummaryModalVisible(false);
                  setSubmitError(e.message);
                },
              }
            );
          } else {
            setSummaryModalVisible(true);
            formikActions.setSubmitting(false);
          }
        }}
        validationSchema={addAmenityValidationSchema(t)}
        render={({
          values,
          dirty,
          errors,
          handleChange,
          setFieldValue,
          touched,
          handleSubmit,
          isSubmitting,
          setSubmitting,
        }) => (
          <>
            <RouteLeavingGuard
              when={dirty}
              navigate={history.push}
              modalAlertProps={{
                title: t('modals.cancelCreating.title'),
                body: t('modals.cancelCreating.subtitle'),
                confirmButtonTitle: t('modals.cancelCreating.confirmCanceling'),
                cancelButtonTitle: tCommon('cancel'),
              }}
            />
            {isSummaryModalVisible && (
              <AmenitySummaryModal
                formValues={values}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                onClose={() => {
                  setSubmitting(false);
                  setSummaryModalVisible(false);
                }}
              />
            )}
            <TabsContainer>
              {tabs.map((tab, i) => (
                <Tab
                  key={tab.label}
                  label={tab.label}
                  isActive={currentTabIndex === i}
                  onPress={() => setCurrentTabIndex(i)}
                  showErrorBadge={tab.fieldsToShowTabErrorBadgeWhenInvalid?.some(
                    fieldName => touched[fieldName] && errors[fieldName]
                  )}
                />
              ))}
            </TabsContainer>
            <div className="container">
              <ErrorBanner hasError={Object.keys(errors).some(fieldName => touched[fieldName])} />
              {createAmenity.isError ? <AlertMessage type="error" msg={submitError} /> : null}
              <form onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius top-right">
                  <RenderedComponent
                    {...{
                      propertyId: match.params.propertyId,
                      errors,
                      handleChange,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values,
                      mode: 'add',
                    }}
                  />
                </div>

                <div className="paper__footer wizard radius-bottom-left radius-bottom-right">
                  <div className="button--wrapper__grouping">
                    {currentTabIndex > 0 ? (
                      <button
                        type="button"
                        className="button button--icon"
                        onClick={() => setCurrentTabIndex(currentTabIndex - 1)}
                        disabled={isSubmitting}
                      >
                        <div className="button--children">
                          <Icon icon="ArrowLongLeft" />
                          <span>{tCommon('back')}</span>
                        </div>
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="button button--secondary"
                      onClick={() => history.push(`/properties/${match.params.propertyId}/community/amenities`)}
                      disabled={isSubmitting}
                    >
                      {tCommon('cancel')}
                    </button>
                  </div>
                  <div className="button--wrapper__grouping">
                    {currentTabIndex < tabs.length - 1 ? (
                      <button
                        type="button"
                        className="button button--icon"
                        onClick={e => {
                          e.preventDefault();
                          setCurrentTabIndex(currentTabIndex + 1);
                        }}
                        disabled={isSubmitting}
                      >
                        <div className="button--children">
                          <span>{tCommon('next')}</span>
                          <Icon icon="ArrowLongRight" />
                        </div>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="button"
                        disabled={isSubmitting || isSummaryModalVisible}
                        onClick={handleSubmit}
                      >
                        {isSubmitting ? tCommon('saving') : t('saveButton')}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      />
    </>
  );
};
