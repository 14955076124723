import React, { Fragment } from 'react';
import DropNCrop from '@synapsestudios/react-drop-n-crop';
import UploadIcon from '../../../../common/icons/Upload';
import ClearIcon from '../../../../common/icons/Clear';
import { useTranslation } from 'react-i18next';

export const emptyImageData = {
  result: null,
  filename: null,
  filetype: null,
  src: '',
  error: null,
};
const maxImageBytes = 1000000;
const imageSizeRestriction = {
  width: 1500,
  height: 600,
};
const InstructionsComponent = () => {
  const { t } = useTranslation('events');
  return (
    <div>
      <UploadIcon />
      <p>{t('addDetailsTab.eventImage.dragImg')}</p>
    </div>
  );
};
export const EventImageInput = ({ onChange, values }) => {
  const { t } = useTranslation('events');
  return (
    <Fragment>
      <h4 className="h4">{t('addDetailsTab.eventImage.header')}</h4>

      <div className="image-uploader">
        <span className="label">{t('addDetailsTab.eventImage.uploadImg')}</span>
        <p className="font-small">{t('addDetailsTab.eventImage.subHeader')}</p>
        <div className="image-uploader">
          <DropNCrop
            onChange={onChange}
            canvasHeight="240px"
            value={values.image_data}
            maxFileSize={maxImageBytes}
            instructions={<InstructionsComponent />}
            cropperOptions={{
              aspectRatio: imageSizeRestriction.width / imageSizeRestriction.height,
              guides: false,
              rotatable: false,
              scalable: false,
              minContainerheight: 300,
            }}
          />
          {values.image_data.result ? (
            <div className="uploader--actions__wrapper">
              <div className="button--stripped button--stripped--destructive" onClick={() => onChange(emptyImageData)}>
                <ClearIcon />
                <span>{t('addDetailsTab.eventImage.remove')}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};
