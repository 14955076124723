import React, { useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import { amenityAvailabilityValidationSchema } from '../validationSchema';
import { ErrorBanner } from '../../../../../../../common/ErrorBanner';
import AlertMessage from '../../../../../../../common/AlertMessage';
import NotificationStatusIndicator from '../../../../../../../common/forms/NotificationStatusIndicator';
import { AmenityAvailability } from '../tabs/AmenityAvailability';
import { useRefetchAmenityById, useUpdateAmenity, useAmenityHasReservations } from '../hooks';
import EditAmenityRouteLeavingGuard from '../tabs/EditAmenityRouteLeavingGuard';
import { CancelAllReservationsModal } from './CancelAllReservationsModal';
import { CANCEL_ALL_RESERVATIONS_MODAL_TYPES } from '../constants/cancelAllReservationsModalTypes';
import { getUserPermission } from '../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import Icon from '../../../../../../../common/icons/icon';
import { useTranslation } from 'react-i18next';

export const EditAmenityAvailabilityForm = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ amenity, history, match, userPermissionList }) => {
    const userPermission = {
      'Edit/Delete Amenity': userPermissionList.includes('EDIT/DELETE_AMENITY'),
    };
    const [submitMessage, setSubmitMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);
    const [isAddingTemporaryClosing, setIsAddingTemporaryClosing] = useState(false);
    const [reasonForCancelAll, setReasonForCancelAll] = useState('');
    const [showCancelReservationsModal, setShowCancelReservationsModal] = useState(false);
    const amenityHasReservations = useAmenityHasReservations(amenity.id);
    const amenityHasActiveReservations = !!amenityHasReservations?.data ?? false;

    const updateAmenity = useUpdateAmenity(amenity?.id);
    const refetchAmenity = useRefetchAmenityById(amenity?.id);

    const { t } = useTranslation('amenities', {
      keyPrefix: 'amenityPage',
    });
    const { t: tCommon } = useTranslation('buttons');

    const availabilityScheduleFormObject =
      amenity?.availability_schedule.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.weekday]: {
            open: moment(curr.open_time, 'h:mm A'),
            close: moment(curr.close_time, 'h:mm A'),
          },
        };
      }, {}) ?? null;

    return (
      <Formik
        initialValues={{
          availability: amenity.availability,
          availability_schedule: availabilityScheduleFormObject,
        }}
        onSubmit={(formValues, formikActions) => {
          const submitValues = cloneDeep(formValues);

          if (submitValues.availability_schedule) {
            // format the moments into human readable times
            let newAvailabilitySchedule = {};
            const schedule = submitValues.availability_schedule;
            for (const day in submitValues.availability_schedule) {
              if (!schedule?.[day]?.open || !schedule?.[day]?.close) continue;

              const formattedOpen = moment(schedule[day].open).format('h:mm A');
              const formattedClose = moment(schedule[day].close).format('h:mm A');

              newAvailabilitySchedule[day] = {
                open: formattedOpen,
                close: formattedClose,
              };
            }
            submitValues.availability_schedule = newAvailabilitySchedule;
          } else {
            delete submitValues.availability_schedule;
          }

          if (submitValues.availability === 'NONE' && reasonForCancelAll) {
            submitValues.cancel_all_reservations_reason = reasonForCancelAll;
          }

          updateAmenity.mutate(submitValues, {
            onSuccess: () => {
              refetchAmenity().then(() => {
                formikActions.resetForm({ values: formValues });
                setSubmitMessage(t('messages.edited'));
                setSubmitStatus('Success');
              });
            },
            onError: e => {
              formikActions.setSubmitting(false);
              setSubmitMessage(e.message);
              setSubmitStatus('Failure');
            },
          });
        }}
        validationSchema={amenityAvailabilityValidationSchema(t)}
        render={({ values, dirty, errors, setFieldValue, touched, handleSubmit, isSubmitting }) => (
          <>
            <EditAmenityRouteLeavingGuard when={dirty || isAddingTemporaryClosing} navigate={history.push} />
            <CancelAllReservationsModal
              onClose={() => {
                setShowCancelReservationsModal(false);
              }}
              onCancelReservation={reason => {
                setFieldValue('availability', 'NONE');
                setReasonForCancelAll(reason);
                setShowCancelReservationsModal(false);
              }}
              amenityId={amenity.id}
              visible={!!showCancelReservationsModal}
              cancelModalType={CANCEL_ALL_RESERVATIONS_MODAL_TYPES.NO_AVAILABILITY}
            />
            <div className="container">
              <ErrorBanner hasError={Object.keys(errors).some(fieldName => touched[fieldName])} />
              {amenity.isError ? (
                <AlertMessage
                  type="error"
                  msg="There was an error when attempting to edit the amenity. Please contact SmartExperience support."
                />
              ) : null}
              <form onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius top-right">
                  <AmenityAvailability
                    {...{
                      amenity,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values,
                      mode: 'edit',
                      isAddingTemporaryClosing,
                      setIsAddingTemporaryClosing,
                      refetchAmenity,
                      setReasonForCancelAll,
                      setShowCancelReservationsModal,
                      amenityHasActiveReservations,
                    }}
                  />
                </div>

                <div className="paper__footer wizard radius-bottom-left radius-bottom-right">
                  <div className="button--wrapper__grouping edit-button-margin">
                    {userPermission['Edit/Delete Amenity'] && (
                      <button
                        type="button"
                        className="button"
                        disabled={isSubmitting}
                        onClick={() => {
                          setSubmitMessage();
                          handleSubmit();
                        }}
                      >
                        {isSubmitting ? tCommon('saving') : t('saveButton')}
                      </button>
                    )}
                    <button
                      type="button"
                      className="button button--secondary"
                      onClick={() => history.push(`/properties/${match.params.propertyId}/community/amenities`)}
                      disabled={isSubmitting}
                    >
                      {tCommon('cancel')}
                    </button>
                    <NotificationStatusIndicator
                      hideNotification={!submitMessage}
                      message={submitMessage}
                      type={submitStatus}
                    />
                  </div>
                  <div className="button--wrapper__grouping edit-button-margin">
                    {submitMessage && (
                      <button
                        type="button"
                        className="button button--icon"
                        onClick={e => {
                          history.push(
                            `/properties/${match.params.propertyId}/community/amenities/${match.params.amenityId}/reservation-type`
                          );
                          e.preventDefault();
                        }}
                      >
                        <div className="button--children">
                          <span>{tCommon('next')}</span>
                          <Icon icon="ArrowLongRight" />
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      />
    );
  }
);
