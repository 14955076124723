import React from 'react';
import { Field } from 'formik';
import InputScaffold from './InputScaffold';
import Select from 'react-select';
import SubHeader from '../SubHeader';
import { FormList, FormListItem } from './FormList';
import { withTranslation } from 'react-i18next';
export default withTranslation('residents')(function({
  property_credentials,
  credentialTypes,
  touched,
  errors,
  setFieldValue,
  invalidCodeDetails,
  accessControlProfileId1,
  accessControlProfileId2,
  accessControlProfileId3,
  accessControlProfileId4,
  t,
}) {
  return (
    <>
      <SubHeader underline title={t('edit.credentialsTab.title')} />
      <FormList
        name="property_credentials"
        addButtonLabel={t('edit.credentialsTab.addCredential')}
        addButtonDisabled={credentialTypes.length === 0}
        defaultItemValue={{
          code: '',
          credential_type_id: '',
          note: '',
          access_control_profile_id1: accessControlProfileId1 || null,
          access_control_profile_id2: accessControlProfileId2 || null,
          access_control_profile_id3: accessControlProfileId3 || null,
          access_control_profile_id4: accessControlProfileId4 || null,
        }}
        items={property_credentials}
        renderItem={(credential, index) => {
          const credentialTypeOptions = credentialTypes.map(type => ({
            value: type.id,
            label: type.name,
          }));

          // Allow existing credentials created with disabled types to be updated
          const isActiveTypeForProperty = !!credentialTypes.find(type => credential.credential_type_id === type.id);
          if (credential.credential_type_id && !isActiveTypeForProperty) {
            credentialTypeOptions.push({
              value: credential.type.id,
              label: credential.type.name,
            });
          }

          const fieldTouched = touched.property_credentials && touched.property_credentials[index];
          const fieldError = errors.property_credentials && errors.property_credentials[index];

          const codeValidation = (() => {
            // check for validation errors coming from the server before Yup validation errors
            if (invalidCodeDetails && credential.code === invalidCodeDetails.code) {
              return invalidCodeDetails.reason;
            }
            return fieldTouched && fieldTouched.code && fieldError && fieldError && fieldError.code;
          })();

          return (
            <FormListItem>
              <InputScaffold
                label={t('edit.credentialsTab.credentialType')}
                validation={
                  fieldTouched && fieldTouched.credential_type_id && fieldError && fieldError.credential_type_id
                }
              >
                <Field
                  name={`property_credentials[${index}].credential_type_id`}
                  component={Select}
                  clearable={false}
                  closeOnSelect={true}
                  placeholder={t('edit.credentialsTab.credentialType')}
                  searchable={false}
                  options={credentialTypeOptions}
                  onChange={type => setFieldValue(`property_credentials[${index}].credential_type_id`, type.value)}
                  value={credential.credential_type_id}
                />
              </InputScaffold>
              <InputScaffold label={t('edit.credentialsTab.code')} validation={codeValidation}>
                <Field
                  name={`property_credentials[${index}].code`}
                  onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                />
              </InputScaffold>
              <InputScaffold label={t('edit.credentialsTab.note')} validation={fieldError && fieldError.note}>
                <Field
                  placeholder={t('edit.credentialsTab.leave')}
                  name={`property_credentials[${index}].note`}
                  value={credential.note || ''}
                />
              </InputScaffold>
            </FormListItem>
          );
        }}
      />
    </>
  );
});
