import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import UploadIcon from '../../../../../../../../common/icons/Upload';
import { DiscussionImageCropperModal } from './DiscussionImageCropperModal';
import AlertMessage from '../../../../../../../../common/AlertMessage';

const MAX_FILES = 5;

const ONE_MEGA_BYTE = 1024 * 1024;

const formattedErrorMessages = {
  'file-too-large': 'File is larger than 1 MB',
};

export const DiscussionImageInput = ({ images, onChangeImages, maxImage, error }) => {
  const [readyToCropFiles, setReadyToCropFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation('discussions');

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setReadyToCropFiles(acceptedFiles.slice(0, maxImage || MAX_FILES - images.length));

      setErrors([
        ...rejectedFiles.map(
          rejectedFile =>
            `Could not accept "${rejectedFile.file.name}". ${formattedErrorMessages[rejectedFile.errors[0].code] ??
              rejectedFile.errors[0].message}.`
        ),
        ...acceptedFiles
          .slice(maxImage || MAX_FILES - images.length)
          .map(acceptedFile => `Could not accept "${acceptedFile.name}". Too many files.`),
      ]);
    },
    [images, maxImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxSize: ONE_MEGA_BYTE,
  });

  return (
    <>
      {readyToCropFiles.length > 0 && (
        <DiscussionImageCropperModal
          imagesToCrop={readyToCropFiles}
          onConfirm={dataUrl => onChangeImages([...images, { tempId: Date.now().toString(), url: dataUrl }])}
          onClose={() => setReadyToCropFiles([])}
        />
      )}

      <div
        {...getRootProps({
          className: `${error ? 'error-images' : 'dropzone'}`,
          style: { width: '100%' },
        })}
      >
        <input {...getInputProps()} />
        <div>
          <UploadIcon />
          <p>{t('Drop your files here, or click to browse')}</p>
          <p>{t('SUPPORTED FILES')}</p>
          <p>{t('PNG or JPG | 1MB or less')}</p>
        </div>
      </div>
      {errors.map((error, i) => (
        <AlertMessage type="error" msg={error} icon key={i} />
      ))}
    </>
  );
};
