import React from 'react';
import { Formik, Field } from 'formik';
import moment from 'moment-timezone';
import Yup from 'yup';
import DatePicker from 'react-datepicker';

import InputScaffold from '../../../../../../common/forms/InputScaffold';
import { useTranslation } from 'react-i18next';

export default function ResidentGuestsFilterForm({ initialValues = {}, onSubmit, onCancel }) {
  const { t } = useTranslation(['residentGuests', 'time', 'buttons']);

  const STATUSES = [
    { value: 'Active', display: t('filter.statuses.active') },
    { value: 'Inactive', display: t('filter.statuses.inactive') },
    { value: 'Future', display: t('filter.statuses.future') },
    { value: 'Revoked', display: t('filter.statuses.revoked') },
  ];

  return (
    <Formik
      initialValues={{
        residentName: initialValues.residentName || '',
        residentUnit: initialValues.residentUnit || '',
        guestName: initialValues.guestName || '',
        startDate: initialValues.startDate ? moment(initialValues.startDate, 'YYYY-MM-DD').toDate() : '',
        endDate: initialValues.endDate ? moment(initialValues.endDate, 'YYYY-MM-DD').toDate() : '',
        communityAccessStatus: initialValues.communityAccessStatus || '',
      }}
      validationSchema={Yup.object().shape({
        startDate: Yup.date().nullable(),
        endDate: Yup.date()
          .nullable()
          .when('startDate', (startDate, schema) => startDate && schema.min(startDate, t('filter.dateError'))),
      })}
      onSubmit={values => {
        const submitValues = {};

        Object.keys(values).forEach(key => {
          if (values[key] instanceof Date) {
            submitValues[key] = moment(values[key]).format('YYYY-MM-DD');
          } else {
            submitValues[key] = values[key].trim();
          }
        });

        return onSubmit(submitValues);
      }}
      render={({
        values,
        errors,
        handleChange,
        handleSubmit,
        touched,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
      }) => {
        return (
          <form className="access-request__filter-form" onSubmit={handleSubmit}>
            <div className="filter-top-row">
              <InputScaffold label={t('filter.residentName')} validation={touched.residentName && errors.residentName}>
                <Field type="text" name="residentName" onChange={handleChange} value={values.residentName} />
              </InputScaffold>
              <InputScaffold label={t('filter.residentUnit')} validation={touched.residentUnit && errors.residentUnit}>
                <Field type="text" name="residentUnit" onChange={handleChange} value={values.residentUnit} />
              </InputScaffold>
              <InputScaffold label={t('filter.guestName')} validation={touched.guestName && errors.guestName}>
                <Field type="text" name="guestName" onChange={handleChange} value={values.guestName} />
              </InputScaffold>

              <InputScaffold
                label={t('filter.status')}
                validation={touched.communityAccessStatus && errors.communityAccessStatus}
              >
                <Field
                  component="select"
                  name="communityAccessStatus"
                  onChange={handleChange}
                  value={values.communityAccessStatus}
                >
                  <option value="" />
                  {STATUSES.map(({ value, display }) => (
                    <option value={value} key={value}>
                      {display}
                    </option>
                  ))}
                </Field>
              </InputScaffold>
            </div>
            <div className="filter-date-picker">
              <InputScaffold label={t('filter.guestsAccessStart')} validation={touched.startDate && errors.startDate}>
                <DatePicker
                  isClearable
                  onChange={date => {
                    setFieldTouched('startDate', true);
                    setFieldValue('startDate', date || '');
                  }}
                  selected={values.startDate || null}
                  dateFormat={t('dateFormatV2', { ns: 'time' })}
                />
              </InputScaffold>
              <div className="dash">&ndash;</div>
              <InputScaffold label={t('filter.guestsAccessEnd')} validation={touched.endDate && errors.endDate}>
                <DatePicker
                  isClearable
                  onChange={date => {
                    setFieldTouched('endDate', true);
                    setFieldValue('endDate', date || '');
                  }}
                  selected={values.endDate || null}
                  dateFormat={t('dateFormatV2', { ns: 'time' })}
                />
              </InputScaffold>
            </div>

            <div className="button__wrapper">
              <button
                type="button"
                className="button button--secondary"
                onClick={() => {
                  onCancel();
                }}
              >
                {t('cancel', { ns: 'buttons' })}
              </button>
              <button disabled={isSubmitting || Object.keys(errors).length} className="button button--secondary">
                {t('apply', { ns: 'buttons' })}
              </button>
            </div>
          </form>
        );
      }}
    />
  );
}
