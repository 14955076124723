import React from 'react';
import { Link } from 'react-router-dom';
import { helpFromManagementMocksRu, helpFromManagementMocksEng } from '../../../mock';
import SubHeader from '../../../../../../../common/SubHeader';
import { useTranslation } from 'react-i18next';

const HelpFromManagementList = () => {
  const { t } = useTranslation('residentMessages');

  const requests = t('typeOfMockData') === 'EN' ? helpFromManagementMocksEng : helpFromManagementMocksRu;

  return (
    <div className="messages-list__wrapper">
      <div className="container">
        <SubHeader title={t('helpFromManagementTitle')} />

        <table className="table-list">
          <thead>
            <tr>
              <th>{t('tableHeaders.authorID')}</th>
              <th>{t('tableHeaders.authorName')}</th>
              <th>{t('tableHeaders.authorType')}</th>
              <th>{t('tableHeaders.authorUnit')}</th>
              <th>{t('tableHeaders.requestType')}</th>
              <th>{t('tableHeaders.status')}</th>
            </tr>
          </thead>

          <tbody>
            {requests.map(request => {
              return (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>
                    <Link to={`help-from-management/${request.id}`}>{request.residentName}</Link>
                  </td>
                  <td>{request.typeClient}</td>
                  <td className="message-table-title">
                    <div className="message-table-title__inner">{request.unitNumber}</div>
                  </td>
                  <td>
                    <div className="table-messages-list__resident">{request.type}</div>
                  </td>
                  <td>
                    <div className="table-messages-list__resident">{t(`statuses.${request.status.toLowerCase()}`)}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HelpFromManagementList;
