import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Yup from 'yup';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import DeleteWithPrompt from '../DeleteWithPrompt';
// import RadioFormField from './RadioFormField';
import InputScaffold from './InputScaffold';
import { trimAndSetNullFalsyValues } from '../utils/helpers';
import { InputNumber } from './InputNumber';
import { SGUnitLabel } from '../../views/Property/views/Units/views/SGTours/common';
import Icon from '../icons/icon';
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  number: Yup.string()
    .trim()
    .max(64, 'Unit number must be 64 characters or less.')
    .required('Please enter a unit number/name'),
  square: Yup.number(),
});

class UnitDetailsForm extends Component {
  state = { submitError: '' };

  setSubmitError = err => this.setState({ submitError: err });

  deleteUnit = () => {
    this.props.deleteUnit().then(action => {
      if (action.response.ok) {
        this.props.history.push(`/properties/${this.props.unit.get('property_id')}/units`, {
          deletedUnit: true,
        });
      }
    });
  };

  renderDeleteButton() {
    if (!this.props.deleteUnit) {
      return null;
    }

    const { unit, t } = this.props;
    const deleteRequirements = [];
    if (unit.get('panel')) {
      deleteRequirements.push('Unit has a panel');
    }
    if (unit.get('residents').size > 0) {
      deleteRequirements.push('Unit has residents');
    }
    if (unit.get('unit_type') === 'model') {
      deleteRequirements.push('Unit is included in the Self-Guided Tours');
    }
    return (
      <div className="delete-unit__wrapper">
        <DeleteWithPrompt
          disabled={deleteRequirements.length > 0}
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this unit</strong>?
            </span>
          }
          buttonLabel={t('unitRemoveBtn')}
          deleteFunc={this.deleteUnit}
        />
        {deleteRequirements.length > 0 ? (
          <div className="delete-reqs__wrapper">
            <div className="delete-reqs--title">Can't delete because:</div>
            <div className="delete-reqs--list">
              {deleteRequirements.map(req => (
                <div className="delete-req" key={req}>
                  • {req}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  renderTourLink() {
    const { property, tour, unit, match } = this.props;
    if (property) {
      if (!property.get('admin_enabled_self_guided_tour') && unit.get('residents').size === 0) {
        return (
          <div className="areas-available m-t25">
            <h5 className="label">TOUR OPTIONS</h5>
            <div className="tour-link-div">
              <div className="tour-link-title">Unit available for Self-Guided tours</div>
              <div className="tour-link-content">
                This <b>unit is vacant</b> and can be available for tours. Do you want to set up{' '}
                <b>self-guided tours</b>?
              </div>
              <div className="tour-link-button-div">
                <Link
                  className="button button--icon tour-link-button"
                  to={'/properties/' + match.params.propertyId + '/units/tours'}
                >
                  <div className="button--children">
                    <span>Set up self-guided tours</span>
                    <Icon icon="ArrowShortRight" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        );
      } else if (
        property.get('admin_enabled_self_guided_tour') &&
        unit.get('residents').size === 0 &&
        unit.get('unit_type') !== 'model'
      ) {
        return (
          <div className="areas-available m-t25">
            <h5 className="label">TOUR OPTIONS</h5>
            <div className="tour-link-div">
              <div className="tour-link-title">Unit available for Self-Guided tours</div>
              <div className="tour-link-content">
                This <b>unit is vacant</b> and can be available for tours. Do you want to <b>add it to your tours</b>?
              </div>
              <div className="tour-link-button-div">
                <Link
                  className="button button--icon tour-link-button"
                  to={`/properties/${match.params.propertyId}/units/tours${tour ? '/automated/edit/settings' : ''}`}
                >
                  <div className="button--children">
                    <span>View in Self-Guided Tours</span>
                    <Icon icon="ArrowShortRight" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        );
      } else if (property.get('admin_enabled_self_guided_tour') && unit.get('unit_type') === 'model') {
        return (
          <div className="areas-available m-t25">
            <h5 className="label">TOUR OPTIONS</h5>
            <div className="tour-link-div">
              <div className="tour-link-title">Would you like to remove this unit from Self-Guided Tours?</div>
              <div className="tour-link-content">
                Please visit your <b>Self-Guided Tour</b> settings to remove this unit.
              </div>
              <div className="tour-link-button-div">
                <Link
                  className="button button--icon tour-link-button"
                  to={`/properties/${match.params.propertyId}/units/tours/automated/edit/settings`}
                >
                  <div className="button--children">
                    <span>View in Self-Guided Tours</span>
                    <Icon icon="ArrowShortRight" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  render() {
    const { initialValues, history, submitFunction, handleGroupSelectChange, groupOptions, t } = this.props;
    const { submitError } = this.state;
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) =>
          submitFunction(trimAndSetNullFalsyValues(values)).catch(err => {
            actions.setSubmitting(false);
            return this.setSubmitError(err);
          })
        }
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="unit-detail-form">
              <div className="container">
                <div className="paper radius-top-left radius-top-right">
                  <section>
                    <div className="unit-number-center ">
                      <div className="section-header h4">{t('informationUnitsTitle')}</div>
                      {values.unit_type === 'model' && <SGUnitLabel title="Model Unit" />}
                    </div>
                    <InputScaffold
                      className={classNames({
                        'error-border': touched.number && errors.number,
                      })}
                      label={t('unitNumber')}
                      required
                    >
                      <input
                        placeholder={t('unitNumberPlaceholder')}
                        type="text"
                        name="number"
                        maxLength="64"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.number}
                      />
                    </InputScaffold>
                    <div className="react-select-scaffold">
                      <div className="label">{t('unitGroups')}</div>
                      <Select
                        closeOnSelect={true}
                        multi
                        onChange={handleGroupSelectChange.bind(this, setFieldValue)}
                        options={groupOptions}
                        placeholder={t('unitGroups')}
                        value={values.groups}
                        simpleValue={true}
                      />
                    </div>
                    <InputScaffold
                      className={classNames({
                        'error-border': touched.square && errors.square,
                      })}
                      label={t('unitSquareFootage')}
                    >
                      <InputNumber
                        placeholder={t('unitSquareFootagePlaceholder')}
                        name="square"
                        maxLength={4}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.square || ''}
                      />
                    </InputScaffold>
                  </section>
                  {this.renderTourLink()}
                  {/* <div className="areas-available m-t15">
                    <h5 className="label">TOUR OPTIONS</h5>
                    <div className="checkbox-list--item box">
                      <RadioFormField
                        className="tour-options"
                        delimiter={() => (
                          <div key="delimiter" className="or-container">
                            <div className="or">OR</div>
                          </div>
                        )}
                        activeValue={values.unit_type}
                        onChange={activeField => {
                          setFieldValue('unit_type', activeField ? activeField.value : activeField);
                        }}
                        fields={[
                          {
                            id: 1,
                            name: 'unit_type',
                            value: 'model',
                            label: 'Designate as model unit',
                            labelText:
                              'This unit is <b class="label-strong">consistently available</b> for showing to prospective residents through <b class="label-strong">self-guided tours.</b>',
                            disabled: false,
                          },
                          {
                            id: 2,
                            name: 'unit_type',
                            value: 'tourable',
                            label: 'Designate as tourable unit',
                            labelText:
                              'This unit is <b class="label-strong">temporarily available</b> for showing to prospective residents through <b class="label-strong">staff approval.</b>',
                            disabled: true,
                          },
                        ]}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="container paper__footer radius-bottom-left radius-bottom-right">
                  {submitError}
                  <button
                    disabled={isSubmitting || Object.keys(errors).length}
                    className="button unit-details-btn-save"
                  >
                    <Icon icon="SaveIcon" />
                    {t('detailsSaveBtn')}
                  </button>
                  <button className="button button--secondary--destructive " type="button" onClick={history.goBack}>
                    {t('detailsCancelBtn')}
                  </button>
                  {this.renderDeleteButton()}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

UnitDetailsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  submitFunction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('units')(withRouter(UnitDetailsForm));
