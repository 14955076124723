import React from 'react';
import Icon from '../../../../../../../../common/icons/icon';
import { useTranslation } from 'react-i18next';

export const ConfirmTemporaryClosingModal = ({ onClose, onCreateTemporaryClosing, visible }) => {
  const { t } = useTranslation('amenities', {
    keyPrefix: 'amenityPage.modals.confirmCreating',
  });
  const { t: tCommon } = useTranslation('buttons');

  if (!visible) return null;

  return (
    <div className="modal modal--visible">
      <div className="modal__children">
        <div className="modal__header">
          <h3>{t('title')}</h3>
          <div className="modal__close" onClick={onClose}>
            <Icon icon="Clear" />
          </div>
        </div>
        <div className="modal__body">
          <p className="modal__body--text">{t('subtitle')}</p>
        </div>
        <div className="modal__button--container">
          <button className="button" type="submit" onClick={onCreateTemporaryClosing}>
            {t('confirm')}
          </button>
          <button className="button button--secondary" type="button" onClick={onClose}>
            {tCommon('cancel')}
          </button>
        </div>
      </div>
      <button type="button" className="modal__overlay" onClick={onClose} />
    </div>
  );
};
