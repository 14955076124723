import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Icon from '../icons/icon';

class PropertyPageHeader extends Component {
  render() {
    const { title, children, subtitle, backLink, className, editLink, t } = this.props;

    return (
      <div className={classNames('pm-page-header', className)}>
        <div className="container">
          {backLink && (
            <Link to={backLink} className="back-arrow">
              <Icon icon="ArrowLeft" />
              {t('back')}
            </Link>
          )}
          {editLink && subtitle ? (
            <div className="details-edit-link__wrapper">
              <h3 className="h3">{subtitle}</h3>
              <Link to={editLink}>Edit</Link>
            </div>
          ) : null}
          {subtitle && !editLink ? <h3 className="h3">{subtitle}</h3> : null}
          <h1 className="h1">{title}</h1>
          {children}
        </div>
      </div>
    );
  }
}

PropertyPageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backLink: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  editLink: PropTypes.node,
};

export default withTranslation('buttons')(PropertyPageHeader);
