import { connect } from 'react-redux';
import GuestRegistration from './guestRegistration';
import { getGuestList, uploadEventCsv, eventRegister } from './reducers/actions';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    guestList: state.property.event.GuestList,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getGuestList: keyword => getGuestList(ownProps.event.id, keyword),
    uploadEventCsv: file => uploadEventCsv(ownProps.event.id, file),
    eventRegister: (state, data) => eventRegister(ownProps.event.id, state, data),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['events', 'buttons'])(GuestRegistration));
