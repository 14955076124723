import React, { useState } from 'react';
import PropertyPageHeader from '../../../../../common/PropertyPageHeader';
import InputScaffold from '../../../../../common/forms/InputScaffold';
import Close from '../../../../../common/icons/Close';
import { useTranslation } from 'react-i18next';

const ResidentMessagesDetails = ({ request }) => {
  const { t } = useTranslation('residentMessages');
  const { t: tCommon } = useTranslation('buttons');
  const [response, setResponse] = useState('');
  const [clickedImage, setClickedImage] = useState(false);

  const {
    residentName,
    unitNumber,
    type,
    description,
    accessInstructions,
    permission,
    photo,
    status: initialStatus,
  } = request;
  const TYPES_OF_BUTTONS = [
    { key: 'New', display: t('statuses.new') },
    { key: 'Responded', display: t('statuses.responded') },
    { key: 'Pending', display: t('statuses.pending') },
    { key: 'Resolved', display: t('statuses.resolved') },
    { key: 'Problem', display: t('statuses.problem') },
  ];

  const [status, setStatus] = useState(TYPES_OF_BUTTONS.find(({ key }) => key === initialStatus));
  const headerTitle = `${residentName} - ${unitNumber} - ${type}`;

  // State handlers: start
  const handleChangeResponse = ({ currentTarget }) => setResponse(currentTarget.value);
  const handleClearResponse = () => setResponse('');
  const handleClickImage = () => setClickedImage(true);
  const handleDismiss = () => setClickedImage(false);
  const handleStatus = newStatus => setStatus(newStatus);
  // State handlers: end

  // Renders: start
  const renderResponseButtons = () => {
    return (
      <div className="comment-buttons">
        <button className="button" type="submit" disabled={!response}>
          {t('details.responseButton')}
        </button>
        {response && (
          <button className="button button--secondary" disabled={!response} type="button" onClick={handleClearResponse}>
            {tCommon('cancel')}
          </button>
        )}
      </div>
    );
  };
  const renderResponse = () => {
    return (
      <div className="comments__section">
        <div className="comment-box">
          <h2>{t('details.responseField')}</h2>
          <form>
            <InputScaffold>
              <textarea
                style={{ resize: 'none' }}
                name="description"
                rows="6"
                maxLength="400"
                placeholder={t('details.addResponsePlaceholder')}
                value={response}
                onChange={handleChangeResponse}
              />
            </InputScaffold>
            {renderResponseButtons()}
          </form>
        </div>
      </div>
    );
  };
  const renderImages = () => {
    return (
      <div className="comments__section">
        <div className="comment-box">
          <h2>{t('details.photos')}</h2>
          <div style={{ marginTop: '24px' }}>
            <img src={photo} onClick={handleClickImage} className="gallery-image" alt="Response image"></img>
          </div>
        </div>
      </div>
    );
  };
  const renderClickedModal = () => {
    return (
      <div className="image-overlay" onClick={handleDismiss}>
        <img src={photo} alt="Larger size" />
        <button className="overlay-close" onClick={handleDismiss}>
          <Close />
        </button>
      </div>
    );
  };
  const renderTypeSpecificFields = () => {
    return (
      <div className="single-post-event-details">
        <div className="single-post__flex-label">
          <div className="inline-label">{t('details.description')}:</div> {description}
        </div>
        <div className="single-post__flex-label">
          <div className="inline-label">{t('details.accessInstructions')}:</div> {accessInstructions}
        </div>
        <div className="single-post__flex-label">
          <div className="inline-label">{t('details.permission')}:</div>{' '}
          {permission ? t('details.yes') : t('details.no')}
        </div>
      </div>
    );
  };
  const renderStatusButtons = () => {
    return (
      <div className="comments__section" style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
        {TYPES_OF_BUTTONS.map(({ key, display }) => (
          <button
            key={key}
            className={`button`}
            onClick={() => handleStatus({ key, display })}
            disabled={key === status.key}
            type="button"
          >
            {display}
          </button>
        ))}
      </div>
    );
  };
  // Renders: end

  return (
    <div>
      <PropertyPageHeader title={headerTitle} backLink="./" />
      <div className={`container paper radius-top-left radius-top-right`}>
        <div className="single-post__wrapper">
          <div className="single-post-details">{renderTypeSpecificFields()}</div>
        </div>
      </div>
      <div className="container">{renderImages()}</div>
      <div className="container">{renderStatusButtons()}</div>
      <div className="container">{renderResponse()}</div>
      {clickedImage && renderClickedModal()}
    </div>
  );
};
export default ResidentMessagesDetails;
