import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import InputScaffold from '../forms/InputScaffold';
import querystring from 'query-string';

class SearchText extends Component {
  state = { search: '' };

  componentDidMount() {
    const { location, name } = this.props;
    const parsedUrl = querystring.parse(location.search);
    if (parsedUrl[name]) {
      return this.setState({ search: parsedUrl[name] });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, name } = this.props;
    if (prevProps.location !== location) {
      const parsedUrl = querystring.parse(location.search);
      if (parsedUrl[name]) {
        return this.setState({ search: parsedUrl[name] });
      }
      return this.setState({ search: '' });
    }
    return true;
  }

  onKeyEnter = event => {
    const { history, location, name } = this.props;
    const { search } = this.state;
    const parsedUrl = querystring.parse(location.search);
    let newSearch;
    if (event.key === 'Enter') {
      if (search.length > 0) {
        newSearch = Object.assign(parsedUrl, { [name]: search, page: 0 });
        this.setState({ search });
        return history.push({ pathname: location.pathName, search: querystring.stringify(newSearch) });
      }
      newSearch = Object.assign(parsedUrl, { [name]: '', page: 0 });
      return history.push({ pathname: location.pathname, search: querystring.stringify(newSearch) });
    }
    return true;
  };

  onUpdateSearch = event => {
    this.setState({ search: event.target.value });
  };

  render() {
    const { placeHolder, t } = this.props;
    const { search } = this.state;
    return (
      <InputScaffold label={t('searchTitle')} className="search-bar--scaffold input-scaffold--full-width">
        <input
          type="text"
          name="search"
          placeholder={placeHolder}
          className="search-box__large"
          value={search}
          onChange={this.onUpdateSearch}
          onKeyPress={this.onKeyEnter}
        />
      </InputScaffold>
    );
  }
}

SearchText.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation('units')(withRouter(SearchText));
