import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { useTranslation } from 'react-i18next';

export const EventRsvpCutoffInput = ({ disabled, errors, isSubmitting, setFieldValue, touched, values }) => {
  const { t } = useTranslation('events');
  const { t: tTime } = useTranslation('time');

  return (
    <div className="double-input__wrapper">
      <InputScaffold
        className="double-input-item"
        label={t('addDetailsTab.inputs.rsvpDate')}
        required
        validation={touched.rsvp_cutoff_date && errors.rsvp_cutoff_date}
      >
        <DayPickerInput
          inputProps={{
            autoComplete: 'false',
            disabled: isSubmitting,
          }}
          name="rsvp_cutoff_date"
          value={values.rsvp_cutoff_date}
          placeholder=""
          format={tTime('dateFormat')}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            numberOfMonths: 1,
            disabledDays: date => moment(date).isBefore(moment().subtract(1, 'day')),
          }}
          onDayChange={date => setFieldValue('rsvp_cutoff_date', date)}
          disabled={isSubmitting}
        />
      </InputScaffold>

      <InputScaffold
        className="double-input-item"
        label={t('addDetailsTab.inputs.rsvpTime')}
        required
        validation={touched.rsvp_cutoff_time && errors.rsvp_cutoff_time}
      >
        <TimePicker
          name="rsvp_cutoff_time"
          showSecond={false}
          defaultValue={values.rsvp_cutoff_time}
          onChange={time => setFieldValue('rsvp_cutoff_time', time)}
          format={tTime('timeFormat')}
          allowEmpty={false}
          use12Hours={tTime('is12HoursTime')}
          inputReadOnly
          disabled={isSubmitting}
        />
      </InputScaffold>
    </div>
  );
};
