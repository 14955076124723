import React from 'react';
import localstorage from 'store2';
import querystring from 'query-string';
import PropTypes from 'prop-types';
import config from '../../../../../../../../common/config';
import { useTranslation } from 'react-i18next';

export const GenerateCSVBtn = ({ title, filePath, queryParams, style }) => {
  const { t } = useTranslation('amenities', { keyPrefix: 'mainScreen' });

  title = title || t('downloadCsvButton');
  const onGenerateCSV = () => {
    const qs = {
      token: localstorage.get('idToken'),
      ...queryParams,
    };

    window.location = `${config.apiUrl}${filePath}?${querystring.stringify(qs)}`;
  };

  return (
    <button type="button" className="button" style={style} onClick={onGenerateCSV}>
      {title}
    </button>
  );
};

GenerateCSVBtn.propTypes = {
  title: PropTypes.string,
  filePath: PropTypes.string,
  queryParams: PropTypes.object,
  style: PropTypes.object,
};
