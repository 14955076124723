import React from 'react';
import RouteLeavingGuard from '../../../../../../../common/RouteLeavingGuard';
import { useTranslation } from 'react-i18next';

const EditAmenityRouteLeavingGuard = ({ when, navigate }) => {
  const { t } = useTranslation('amenities', {
    keyPrefix: 'amenityPage.modals.unsavedChanges',
  });
  const { t: tCommon } = useTranslation('buttons');

  return (
    <RouteLeavingGuard
      when={when}
      navigate={navigate}
      modalAlertProps={{
        title: t('title'),
        body: t('subtitle'),
        confirmButtonTitle: t('submit'),
        cancelButtonTitle: tCommon('cancel'),
      }}
    />
  );
};

export default EditAmenityRouteLeavingGuard;
