import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class UploadField extends Component {
  state = {
    fileName: '',
  };

  static propTypes = {
    onChange: PropTypes.func,
  };

  onFileSelected = () => {
    this.setState({ fileName: this.fileInput.value.replace('C:\\fakepath\\', '') });
    this.props.onChange(this.fileInput.files[0]);
  };

  render() {
    const { t } = this.props;
    return (
      <div className="file-upload">
        <div className="file-upload__name">
          {!this.state.fileName ? t('importUnitsChooseFile') : this.state.fileName}
        </div>
        <div className="file-upload__wrapper button">
          {t('importUnitsChooseBtn')}
          <input name="fileInput" ref={input => (this.fileInput = input)} type="file" onChange={this.onFileSelected} />
        </div>
      </div>
    );
  }
}

export default withTranslation('units')(UploadField);
