import React from 'react';
import Icon from '../../../../../../../../common/icons/icon';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { Formik, Field } from 'formik';
import { ErrorBanner } from '../../../../../../../../common/ErrorBanner';
import { closeAmenityValidationSchema } from './validationSchema';
import { useTranslation } from 'react-i18next';

export const CloseAmenityModal = ({ onClose, onConfirm, visible }) => {
  const { t } = useTranslation('amenities', {
    keyPrefix: 'amenityPage.modals.close',
  });
  const { t: tCommon } = useTranslation('buttons');

  if (!visible) return null;

  return (
    <Formik
      initialValues={{
        announcement_title: '',
        announcement_message: '',
      }}
      onSubmit={(formValues, formikActions) => {
        onConfirm(formValues);
      }}
      validationSchema={closeAmenityValidationSchema}
      render={({ values, dirty, errors, handleChange, touched, handleSubmit, isSubmitting }) => (
        <>
          <div className="modal modal--visible">
            <div className="modal__children">
              <div className="modal__header">
                <h3>{t('title')}</h3>
                <div className="modal__close" onClick={onClose}>
                  <Icon icon="Clear" />
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal__body">
                  <p className="modal__body--text">{t('subtitle')}</p>
                  <InputScaffold
                    label={t('announcementTitleField')}
                    disabled={isSubmitting}
                    validation={touched.announcement_title && errors.announcement_title}
                  >
                    <Field
                      disabled={isSubmitting}
                      type="text"
                      name="announcement_title"
                      maxLength="120"
                      onChange={handleChange}
                      value={values.announcement_title}
                    />
                  </InputScaffold>
                  <InputScaffold
                    label={t('announcementField')}
                    disabled={isSubmitting}
                    validation={touched.announcement_message && errors.announcement_message}
                  >
                    <textarea
                      disabled={isSubmitting}
                      name="announcement_message"
                      maxLength="2000"
                      onChange={handleChange}
                      value={values.announcement_message}
                      rows={3}
                    />
                  </InputScaffold>
                </div>
                <ErrorBanner hasError={Object.keys(errors).some(fieldName => touched[fieldName])} />

                <div className="modal__button--container">
                  <button className="button button--secondary" type="button" onClick={onClose}>
                    {tCommon('cancel')}
                  </button>
                  <button className="button" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
                    {t('submit')}
                  </button>
                </div>
              </form>
            </div>
            <button type="button" className="modal__overlay" onClick={onClose} />
          </div>
        </>
      )}
    />
  );
};
