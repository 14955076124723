import React from 'react';
import { useSelector } from 'react-redux';
import { dollarsToCents } from '../util';
import { FinancialSummary } from './Financials/FinancialSummary';
import cn from 'classnames';
import Icon from '../../../../../../../common/icons/icon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const EventSummaryModal = ({ formValues, visible, isSubmitting, handleSubmit, onClose, vendorsList }) => {
  const unitGroupList = useSelector(state => state.property.groupList);
  const selectedUnitGroups = unitGroupList.filter(unitGroup =>
    formValues.event_group_ids.includes(unitGroup.get('id'))
  );
  const { t } = useTranslation('events');
  const { t: tTime } = useTranslation('time');

  if (!visible) {
    return null;
  }

  const eventDate = moment(formValues.event_date).format(tTime('dateFormat'));
  const startTime = moment(formValues.start_time).format(tTime('timeFormat'));
  const endTime = moment(formValues.end_time).format(tTime('timeFormat'));

  const rsvpCutoffDate = moment(formValues.rsvp_cutoff_date).format(tTime('dateFormat'));
  const rsvpCutoffTime = moment(formValues.rsvp_cutoff_time).format(tTime('timeFormat'));

  const refundCutoffDate = moment(formValues.refund_cutoff_date).format(tTime('dateFormat'));
  const refundCutoffTime = moment(formValues.refund_cutoff_time).format(tTime('timeFormat'));

  const hasVendor = !!formValues.vendor_id;
  const selectedVendorName =
    hasVendor && vendorsList?.data && vendorsList.data.find(vendor => vendor.id === formValues.vendor_id)?.name;

  return (
    visible && (
      <div className="modal modal--visible event-summary--modal">
        <div className="modal__children summary--modal__children">
          <div className="modal__header">
            <h3>{t('eventSummary.title')}</h3>
            <div className="modal__close" onClick={onClose}>
              <Icon icon="Clear" />
            </div>
          </div>
          <div className="modal__body">
            <p className="modal__body--text">{t('eventSummary.subHeader')}</p>
            <div className="paper__grid">
              <div className="paper__section--grid-item">
                <section className="paper__section">
                  <h4 className="section-header h4">{t('eventSummary.details')}</h4>
                  <div className="details--read-only">
                    <div className="read-only--label">{t('eventSummary.eventTitle')}</div>
                    <div className="read-only--value title">{formValues.title}</div>
                  </div>
                  <div className="details--read-only__wrapper">
                    <div className="details--read-only">
                      <div className="read-only--label">{t('eventSummary.description')}</div>
                      <div className="read-only--value">{formValues.description}</div>
                    </div>

                    <div className="details--read-only">
                      <div className="read-only--label">{t('eventSummary.date')}</div>
                      <div className="read-only--value">{eventDate}</div>
                    </div>
                    <div className="details--read-only">
                      <div className="read-only--label">{t('eventSummary.location')}</div>
                      <div className="read-only--value">{formValues.location}</div>
                    </div>
                    <div className="details--read-only">
                      <div className="read-only--label">{t('eventSummary.time')}</div>
                      <div className="read-only--value flex">
                        <span>{startTime}</span>
                        <Icon icon="ArrowLongRight" />
                        <span>{endTime}</span>
                      </div>
                    </div>
                    <div className="details--read-only">
                      <div className="read-only--label event-half-label">{t('eventSummary.type')}</div>
                      <div className="read-only--label event-half-label">{t('eventSummary.waitList')}</div>
                      <div className="read-only--value event-half-value">
                        {formValues.is_paid_event ? t('eventSummary.paid') : t('eventSummary.free')}
                      </div>
                      <div className="read-only--value event-half-value">
                        {formValues.waitlist_allowed ? t('eventSummary.waitListON') : t('eventSummary.waitListOFF')}
                      </div>
                    </div>
                    <div className="details--read-only">
                      <div className="read-only--label">{t('eventSummary.rspvTime')}</div>
                      <div className="read-only--value flex">
                        <span>{rsvpCutoffDate}</span>
                        <Icon icon="ArrowLongRight" />
                        <span>{rsvpCutoffTime}</span>
                      </div>
                    </div>
                  </div>
                </section>
                <hr className="section-hr" />
                <section className="paper__section">
                  <h4 className="section-header h4">{t('eventSummary.settings')}</h4>
                  <div className="details--read-only__wrapper">
                    <div className="details--read-only">
                      <div className="read-only--label">{t('eventSummary.groups')}</div>
                      <div className="event-preview--unit-groups">
                        {selectedUnitGroups.size === 0 ? (
                          <div className="event-preview--unit-group__wrapper">
                            <Icon icon="FatCheck" />
                            <span className="unit-group--label">{t('eventSummary.allResidents')}</span>
                          </div>
                        ) : (
                          selectedUnitGroups.map(unitGroup => (
                            <div key={unitGroup.get('id')} className="event-preview--unit-group__wrapper">
                              <Icon icon="FatCheck" />
                              <span className="unit-group--label">{unitGroup.get('name')}</span>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="paper__section--grid-item">
                <section className="paper__section">
                  <h4 className="section-header h4">{t('eventSummary.image')}</h4>
                  <div className="details--read-only__wrapper">
                    <div className="details--read-only">
                      <div className="read-only--label">{t('eventSummary.image')}</div>
                      {formValues.image_data.result ? (
                        <div
                          className="event-image-preview"
                          style={{ backgroundImage: `url(${formValues.image_data.result})` }}
                        />
                      ) : (
                        <div className="no-image-provided">{t('eventSummary.noImage')}</div>
                      )}
                    </div>
                  </div>
                </section>
                <hr className="section-hr" />
                <section className="paper__section">
                  <h4 className="section-header h4">{t('eventSummary.instructionsTitle')}</h4>
                  <div className="details--read-only__wrapper">
                    <div className="details--read-only">
                      <div className="read-only--label">{t('eventSummary.instructionsText')}</div>
                      <div className={cn(formValues.confirmation_details ? '' : 'italic', 'read-only--value')}>
                        {formValues.confirmation_details
                          ? formValues.confirmation_details
                          : t('eventSummary.noInstructions')}
                      </div>
                    </div>
                  </div>
                </section>

                {formValues.is_paid_event && (
                  <>
                    <hr className="section-hr" />
                    <section className="paper__section">
                      <h4 className="section-header h4">{t('eventSummary.financials')}</h4>
                      <FinancialSummary
                        t={t}
                        priceInCents={formValues.price_amount ? dollarsToCents(formValues.price_amount) : null}
                        communityFee={formValues.community_fee_percent}
                        hasVendor={hasVendor}
                      />
                      <div className="details--read-only__wrapper">
                        {hasVendor && (
                          <div className="details--read-only">
                            <div className="read-only--label">{t('eventSummary.vendor')}</div>
                            <div className="read-only--value">{selectedVendorName}</div>
                          </div>
                        )}
                        <div className="details--read-only">
                          <div className="read-only--label">{t('eventSummary.cutOffDate')}</div>
                          <div className="read-only--value">{refundCutoffDate}</div>
                        </div>
                        <div className="details--read-only">
                          <div className="read-only--label">{t('eventSummary.cutOffTime')}</div>
                          <div className="read-only--value">{refundCutoffTime}</div>
                        </div>
                        <div className="details--read-only">
                          <div className="read-only--label">{t('eventSummary.fee')}</div>
                          <div className="read-only--value">{formValues.cancel_fee_percent}%</div>
                        </div>
                      </div>
                    </section>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="modal__button--container">
            <button type="button" className="button" disabled={isSubmitting} onClick={handleSubmit}>
              {isSubmitting ? t('eventSummary.savingBtn') : t('eventSummary.confirmBtn')}
            </button>
            <button type="button" className="button button--secondary" disabled={isSubmitting} onClick={onClose}>
              {t('eventSummary.cancelBtn')}
            </button>
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={onClose} />
      </div>
    )
  );
};
