import React, { useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';

import Icon from '../../../../../../../../common/icons/icon';
import Loading from '../../../../../../../../common/Loading';

export const AmenityImageCropperModal = ({ imagesToCrop, onClose, onConfirm }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageSrc, setCurrentImageSrc] = useState(null);
  const [cropper, setCropper] = useState();
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.images.sections.cropperModal' });
  const { t: tCommon } = useTranslation('buttons');

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      setCurrentImageSrc(reader.result);
    };
    reader.readAsDataURL(imagesToCrop[currentImageIndex]);
  }, [currentImageIndex, imagesToCrop]);

  return (
    <div className="modal modal--visible">
      <div className="modal__children">
        <div className="modal__header">
          <h3>{t('title')}</h3>
          <div className="modal__close" onClick={onClose}>
            <Icon icon="Clear" />
          </div>
        </div>
        <div className="modal__body">
          {currentImageSrc ? (
            <Cropper
              src={currentImageSrc}
              style={{ height: 400, width: '100%' }}
              // Cropper.js options
              aspectRatio={16 / 9}
              guides={true}
              viewMode={0}
              autoCropArea={1}
              onInitialized={setCropper}
            />
          ) : (
            <Loading />
          )}
        </div>
        <div className="modal__button--container">
          <button
            disabled={!cropper}
            type="button"
            className="button"
            onClick={() => {
              const croppedCanvas = cropper.getCroppedCanvas();
              // Prevent errors from clicking the button before the cropper has initialized and the canvas is available
              if (!croppedCanvas) {
                return;
              }
              onConfirm(croppedCanvas.toDataURL(imagesToCrop[currentImageIndex].type));
              currentImageIndex < imagesToCrop.length - 1 ? setCurrentImageIndex(currentImageIndex + 1) : onClose();
            }}
          >
            {tCommon('confirm')}
          </button>
          <button type="button" className="button button--secondary" onClick={onClose}>
            {tCommon('cancel')}
          </button>
        </div>
      </div>
      <button type="button" className="modal__overlay" onClick={onClose} />
    </div>
  );
};
