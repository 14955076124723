import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Map } from 'immutable';
import UnitDetailsForm from '../../../../../../../../common/forms/UnitDetailsForm';
import PropertyPageHeader from '../../../../../../../../common/PropertyPageHeader';

class EditUnit extends Component {
  handleGroupSelectChange = (setFieldValue, value) => {
    setFieldValue('groups', value);
  };

  render() {
    const {
      deleteUnit,
      editUnit,
      history,
      isAdmin,
      property,
      unit,
      tour,
      groupOptions,
      selectedGroupOptions,
      t,
    } = this.props;
    const initialGroups = selectedGroupOptions.map(group => group.value).join();

    return (
      <div>
        <PropertyPageHeader title={t('detailsUnitsTitle')} subtitle={property.get('name')} />
        <UnitDetailsForm
          property={property}
          tour={tour}
          deleteUnit={deleteUnit}
          unit={unit}
          isAdmin={isAdmin}
          initialValues={{
            square: unit.get('square')?.toString(),
            unit_type: unit.get('unit_type'),
            number: unit.get('number'),
            groups: initialGroups,
            panel_id: unit.get('panel_id'),
          }}
          groupOptions={groupOptions}
          handleGroupSelectChange={this.handleGroupSelectChange}
          submitFunction={submitValues => {
            if (submitValues.groups && submitValues.groups.length) {
              submitValues.groups = submitValues.groups.split(',').map(group => {
                return { id: group };
              });
            } else {
              submitValues.groups = [];
            }
            return editUnit(submitValues).then(res => {
              if (res.response.ok) {
                return history.push(`/properties/${property.get('id')}/units/`);
              }
              throw res.json.message;
            });
          }}
        />
      </div>
    );
  }
}

EditUnit.propTypes = {
  editUnit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  property: PropTypes.instanceOf(Map).isRequired,
  unit: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation('units')(EditUnit);
