import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withTranslation } from 'react-i18next';
import UnitDetailsForm from '../../../../../../common/forms/UnitDetailsForm';
import { trimAndSetNullFalsyValues } from '../../../../../../common/utils/helpers';
import PropertyPageHeader from '../../../../../../common/PropertyPageHeader';

class AddUnit extends Component {
  state = { value: '' };
  handleGroupSelectChange = (setFieldValue, value) => {
    setFieldValue('groups', value);
  };

  render() {
    const { createUnit, history, isAdmin, property, groupOptions, t } = this.props;
    return (
      <div>
        <PropertyPageHeader
          className="units"
          title={t('detailsUnitsTitle')}
          subtitle={this.props.property.get('name')}
        />
        <UnitDetailsForm
          isAdmin={isAdmin}
          initialValues={{
            square: '',
            unit_type: '',
            number: '',
            groups: '',
          }}
          groupOptions={groupOptions}
          handleGroupSelectChange={this.handleGroupSelectChange}
          submitFunction={submitValues => {
            if (submitValues.groups && submitValues.groups.length) {
              submitValues.groups = submitValues.groups.split(',').map(group => {
                return { id: group };
              });
            }
            return createUnit(trimAndSetNullFalsyValues(submitValues), property.get('id')).then(res => {
              if (res.response.ok) {
                return history.push(`/properties/${property.get('id')}/units`);
              }
              throw res.json.message;
            });
          }}
        />
      </div>
    );
  }
}

AddUnit.propTypes = {
  createUnit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  property: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation('units')(AddUnit);
