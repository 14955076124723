import React from 'react';
import { connect } from 'react-redux';
import { selectActivePropertyGuest } from '../../views/Property/redux/selectors';
import SubHeader from '../SubHeader';
import { CommunityAccessCode } from './AccessCode';
import { setCommunityAccessCodeOnCommunityGuest } from '../../views/Property/redux/actions';
import { useCommunityAccessCodeMutations } from './mutations/useCommunityAccessCodeMutations';
import { getUserPermission } from '../../redux/selectors';
import { useTranslation } from 'react-i18next';

const CommunityGuestAccessCodesComponent = ({
  communityGuest,
  setCommunityAccessCodeOnCommunityGuest,
  userPermissionList,
}) => {
  const userPermission = {
    showUnhideUsersAccessCodes: userPermissionList.includes("SHOW_(UNHIDE)_USER'S_ACCESS_CODES"),
    resendResidentsAccessCodes: userPermissionList.includes("RESEND_A_RESIDENT'S_ACCESS_CODES"),
    resetAResidentsAccessCodes: userPermissionList.includes("RESET_A_RESIDENT'S_ACCESS_CODES"),
    removeAResidentsAccessCodes: userPermissionList.includes("REMOVE_A_RESIDENT'S_ACCESS_CODES"),
    ChangResidentAccessControlProfile: userPermissionList.includes("CHANGE_A_RESIDENT'S_ACCESS_CONTROL_PROFILE"),
    EditResidentPersonalDetail: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
  };
  const { t } = useTranslation('propertyGuests');
  const communityAccessCodeMutations = useCommunityAccessCodeMutations(
    communityGuest,
    'access_code_id',
    setCommunityAccessCodeOnCommunityGuest,
    { communityGuestId: communityGuest.get('id') }
  );

  return (
    <div>
      <SubHeader underline paperSubheader title={t('property.accessProfiles')} />
      <CommunityAccessCode
        code={communityGuest.getIn(['property_access_code', 'code'])}
        hasCode={communityGuest.get('access_code_id')}
        error={communityAccessCodeMutations.error}
        isError={communityAccessCodeMutations.isError}
        isLoading={communityAccessCodeMutations.isLoading}
        isSuccess={communityAccessCodeMutations.isSuccess}
        onGenerate={communityAccessCodeMutations.create}
        onRemove={communityAccessCodeMutations.remove}
        onReset={communityAccessCodeMutations.resetCode}
        onSendReminder={communityAccessCodeMutations.sendReminder}
        userPermission={userPermission}
      />
    </div>
  );
};

export const CommunityGuestAccessCodes = connect(
  (state, props) => {
    return {
      communityGuest: selectActivePropertyGuest(state, props.communityGuestId),
      userPermissionList: getUserPermission(state),
    };
  },
  { setCommunityAccessCodeOnCommunityGuest }
)(CommunityGuestAccessCodesComponent);
