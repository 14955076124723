import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import InputScaffold from '../../../../../../common/forms/InputScaffold';
import { withTranslation } from 'react-i18next';

class GroupsForm extends Component {
  handleCancel = () => this.props.switchMode('view');

  render() {
    const { data, editGroups, switchMode, validationSchema, t } = this.props;
    return (
      <Formik
        initialValues={{
          name: data.get('name'),
          id: data.get('id'),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const payload = { name: values.name, description: values.description };
          return editGroups(data.get('property_id'), values.id, payload)
            .then(action => {
              if (action.response.ok) {
                this.props.getGroups();
                return switchMode('view');
              } else {
                const message = action.response.status === 422 ? action.json.message : 'Could not edit group';
                actions.setErrors({ name: message });
                actions.setSubmitting(false);
              }
            })
            .catch(err => actions.setErrors({ submitting: 'Update Failed' }));
        }}
        render={({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <td>
            <form onSubmit={handleSubmit} className="edit-group-form">
              <InputScaffold label={t('main.name')} required validation={errors.name}>
                <input type="text" name="name" maxLength="64" onChange={handleChange} value={values.name} />
              </InputScaffold>

              <div>{errors.submitting}</div>

              <button type="submit" disabled={isSubmitting || Object.keys(errors).length} className="button">
                {t('update', { ns: 'buttons' })}
              </button>

              <button className="button button--secondary" onClick={this.handleCancel}>
                {t('cancel', { ns: 'buttons' })}
              </button>
            </form>
          </td>
        )}
      />
    );
  }
}

GroupsForm.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.string.isRequired,
  editGroups: PropTypes.func.isRequired,
  showingMenu: PropTypes.bool.isRequired,
  switchMode: PropTypes.func.isRequired,
  toggleRemove: PropTypes.func.isRequired,
  toggleShowingMenu: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default withTranslation(['unitGroups', 'buttons'])(GroupsForm);
