import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';

import moment from 'moment';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { EventRsvpCutoffInput } from '../../AddEvent/EventDetails/EventRsvpCutoffInput';
import { getEditEventDetailsValidationSchema } from './validationSchema';
import RouteLeavingGuard from '../../../../../../../../common/RouteLeavingGuard';
import { useRefetchEventById, useUpdateEvent } from '../../hooks';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../../../../../common/Loading';
import NotificationStatusIndicator from '../../../../../../../../common/forms/NotificationStatusIndicator';
import Icon from '../../../../../../../../common/icons/icon';
import ClearIcon from '../../../../../../../../common/icons/Clear';
import { CancelEventModal } from '../../CancelEventModal';
import { EventImageInput } from '../../../../../../common/EventImageInput';
import { emptyImageData } from '../../../../../../common/EventImageInput';
import AlertMessage from '../../../../../../../../common/AlertMessage/';
import { getUserPermission } from '../../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import Switch from '../../../../../../../../common/forms/Switch';
import CloseWaitListModel from './CloseWaitListModel';
import { useTranslation } from 'react-i18next';

const formattedErrorMessages = {
  MaxAttendeesCannotBeLowerThanRegistrations: 'Cannot set max attendees lower than the number of registrations.',
};

export const EventDetails = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ event, isEventLoaded, navigateToEventListPage, userPermissionList }) => {
    const userPermission = {
      'Edit/Cancel Event': userPermissionList.includes('EDIT/CANCEL_EVENT'),
    };
    const [closeWaitListModel, setCloseWaitListModel] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);
    const updateEvent = useUpdateEvent(event?.id);
    const [cancelModalEvent, setCancelModalEvent] = useState(null);
    const refetchEvent = useRefetchEventById(event?.id);
    const history = useHistory();
    const { t } = useTranslation('events');
    const { t: tTime } = useTranslation('time');

    useEffect(() => {
      refetchEvent();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    if (!isEventLoaded) {
      return <Loading />;
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: event.title,
          description: event.description,
          location: event.location,
          min_attendees: event.min_attendees,
          max_attendees: event.max_attendees,
          image_url: event.image_url,
          image_s3_key: event.image_s3_key,
          image_data: emptyImageData,
          rsvp_cutoff_date: moment(event.rsvp_cutoff_date).format('MM/DD/YYYY'),
          rsvp_cutoff_time: moment(event.rsvp_cutoff_time, 'HH:mm'),
          confirmation_details: event.confirmation_details,
          waitlist_allowed: event.waitlist_allowed,
          pending_duration: event.pending_duration,
        }}
        validationSchema={getEditEventDetailsValidationSchema(event.event_date, moment(event.start_time, 'HH:mm'))}
        onSubmit={(formValues, formikActions) => {
          setSubmitMessage('');
          setSubmitStatus(null);

          const submitValues = cloneDeep(formValues);
          if (!submitValues.image_data.result) {
            delete submitValues.image_data;
          } else {
            submitValues.image_data = submitValues.image_data.result;
          }
          if (submitValues.image_url && submitValues.image_s3_key) {
            delete submitValues.image_url;
            delete submitValues.image_s3_key;
          }

          submitValues.rsvp_cutoff_date = moment(submitValues.rsvp_cutoff_date).format('YYYY-MM-DD');
          submitValues.rsvp_cutoff_time = moment(submitValues.rsvp_cutoff_time).format('HH:mm');

          updateEvent.mutate(submitValues, {
            onSuccess: () => {
              refetchEvent().then(() => {
                formikActions.resetForm();
                setSubmitMessage('Event edited successfully!');
                setSubmitStatus('Success');
              });
            },
            onError: e => {
              formikActions.setSubmitting(false);
              setSubmitMessage(e.message);
              setSubmitStatus('Failure');
            },
          });
        }}
        render={({ values, dirty, errors, handleChange, setFieldValue, touched, isSubmitting, handleSubmit }) => (
          <div className="container">
            <CloseWaitListModel
              onConfirm={() => {
                setFieldValue('waitlist_allowed', false);
                setCloseWaitListModel(false);
              }}
              onCancel={() => {
                setCloseWaitListModel(false);
              }}
              visible={closeWaitListModel}
            ></CloseWaitListModel>
            <RouteLeavingGuard
              when={dirty}
              navigate={history.push}
              modalAlertProps={{
                title: t('editEvent.cancelEditModal.title'),
                body: t('editEvent.cancelEditModal.body'),
                confirmButtonTitle: t('editEvent.cancelEditModal.confirmButtonTitle'),
                cancelButtonTitle: t('editEvent.cancelEditModal.cancelButtonTitle'),
              }}
            />
            {!event.canceled_at && event.staff_notified_when_attendee_count_is_below_minimum && (
              <>
                <CancelEventModal
                  eventId={cancelModalEvent?.id}
                  visible={!!cancelModalEvent}
                  onCancel={() => {
                    setCancelModalEvent(null);
                    history.push(`/properties/${event.community_id}/community/events`);
                  }}
                  onClose={() => {
                    setCancelModalEvent(null);
                  }}
                />
                <AlertMessage
                  type="warning"
                  icon
                  msg={
                    <div className="event-min-registrants__wrapper">
                      <div>{t('editEvent.alertMinRegistrants.body')}</div>
                      <button className="button button--destructive" onClick={() => setCancelModalEvent(event)}>
                        {t('editEvent.alertMinRegistrants.cancel')}
                      </button>
                    </div>
                  }
                />
              </>
            )}
            <form onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius top-right">
                <div className="paper__grid">
                  <div className="paper__section--grid-item">
                    <section className="paper__section">
                      <div className="section-header h4">{t('editEvent.eventDetails.header')}</div>
                      <InputScaffold
                        label={t('editEvent.eventDetails.title')}
                        required
                        validation={touched.title && errors.title}
                      >
                        <input
                          type="text"
                          name="title"
                          maxLength="120"
                          disabled={isSubmitting}
                          onChange={handleChange}
                          value={values.title}
                        />
                      </InputScaffold>
                      <InputScaffold
                        label={t('editEvent.eventDetails.descr')}
                        required
                        validation={touched.description && errors.description}
                      >
                        <textarea
                          className="event-description"
                          name="description"
                          maxLength="2000"
                          disabled={isSubmitting}
                          onChange={handleChange}
                          value={values.description}
                        />
                      </InputScaffold>

                      <InputScaffold
                        label={t('editEvent.eventDetails.location')}
                        required
                        validation={touched.location && errors.location}
                      >
                        <input
                          type="text"
                          name="location"
                          maxLength="120"
                          disabled={isSubmitting}
                          onChange={handleChange}
                          value={values.location}
                        />
                      </InputScaffold>

                      <div className="details--read-only__wrapper">
                        <div className="details--read-only">
                          <div className="read-only--label">{t('editEvent.eventDetails.dateTitle')}</div>
                          <div className="read-only--value">
                            {t('editEvent.eventDetails.date', { date: new Date(event.event_date) })}
                          </div>
                        </div>

                        <div className="details--read-only">
                          <div className="read-only--label">{t('editEvent.eventDetails.timeTitle')}</div>
                          <div className="read-only--value flex">
                            <div className="">{moment(event.start_time, 'HH:mm').format(tTime('timeFormat'))}</div>
                            <Icon icon="ArrowLongRight" />
                            <div className="">{moment(event.end_time, 'HH:mm').format(tTime('timeFormat'))}</div>
                          </div>
                        </div>
                      </div>

                      {/* @Design I duct taped the styles together, please help :)
                    https://www.figma.com/file/i067e1YgrlOegT0HAg2yUt/Littlebird-Admin?node-id=589%3A201
                    */}
                      <div style={{ marginTop: 32 }}>
                        <div className="section-header h4">{t('editEvent.eventDetails.instructionsHead')}</div>
                        <p className="font-small">{t('editEvent.eventDetails.instructionsContent')}</p>
                        <InputScaffold
                          label={t('editEvent.eventDetails.instructionsTitle')}
                          validation={touched.confirmation_details && errors.confirmation_details}
                        >
                          <textarea
                            name="confirmation_details"
                            maxLength="2000"
                            rows="4"
                            cols="50"
                            disabled={isSubmitting}
                            onChange={handleChange}
                            value={values.confirmation_details || ''}
                          />
                        </InputScaffold>
                      </div>
                    </section>
                  </div>
                  <div className="paper__section--grid-item">
                    <section className="paper__section event-image__section">
                      {values.image_url ? (
                        <div>
                          <div className="section-header h4">{t('editEvent.eventDetails.imageTitle')}</div>
                          <p className="help-text">{t('editEvent.eventDetails.imageContent')}</p>
                          <img alt="Event" src={values.image_url} />
                          <div className="uploader--actions__wrapper">
                            <div
                              className="button--stripped button--stripped--destructive"
                              onClick={() => {
                                setFieldValue('image_url', null);
                                setFieldValue('image_s3_key', null);
                              }}
                            >
                              <ClearIcon />
                              <span>{t('editEvent.eventDetails.removeImgage')}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="paper__section--grid-item">
                          <section className="paper__section">
                            <EventImageInput
                              errors={errors}
                              values={values}
                              onChange={image_data => {
                                setFieldValue('image_data', image_data);
                              }}
                              touched={touched}
                            />
                          </section>
                        </div>
                      )}
                    </section>

                    <section className="paper__section">
                      <div className="section-header h4">{t('editEvent.eventDetails.attendeeSettings')}</div>
                      <EventRsvpCutoffInput
                        disabled={isSubmitting}
                        errors={errors}
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                      <div className="double-input__wrapper">
                        <InputScaffold
                          label={t('editEvent.eventDetails.minAttendees')}
                          required
                          validation={touched.min_attendees && errors.min_attendees}
                          className="double-input-item"
                        >
                          <input
                            type="text"
                            name="min_attendees"
                            disabled={isSubmitting}
                            onChange={event => {
                              setFieldValue('min_attendees', event.target.value.replace(/[^0-9]+/g, ''));
                            }}
                            value={values.min_attendees}
                          />
                        </InputScaffold>
                        <InputScaffold
                          label={t('editEvent.eventDetails.maxAttendees')}
                          required
                          validation={touched.max_attendees && errors.max_attendees}
                          className="double-input-item"
                        >
                          <input
                            type="text"
                            name="max_attendees"
                            disabled={isSubmitting}
                            onChange={event => {
                              setFieldValue('max_attendees', event.target.value.replace(/[^0-9]+/g, ''));
                            }}
                            value={values.max_attendees}
                          />
                        </InputScaffold>
                        <InputScaffold className="double-input-item">
                          <Switch
                            label={t('editEvent.eventDetails.waitList')}
                            input={{
                              value: values.waitlist_allowed,
                              name: 'waitlist_allowed',
                              onChange: value => {
                                if (value === false) {
                                  setCloseWaitListModel(true);
                                } else {
                                  setFieldValue('waitlist_allowed', value);
                                }
                              },
                            }}
                          />
                        </InputScaffold>
                        {values.waitlist_allowed && (
                          <InputScaffold
                            label={t('editEvent.eventDetails.pendingDuration')}
                            required
                            validation={touched.pending_duration && errors.pending_duration}
                            className="double-input-item"
                          >
                            <input
                              type="text"
                              name="pending_duration"
                              disabled={isSubmitting}
                              onChange={event => {
                                setFieldValue('pending_duration', event.target.value.replace(/[^0-9]+/g, ''));
                              }}
                              value={values.pending_duration}
                            />
                          </InputScaffold>
                        )}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                {userPermission['Edit/Cancel Event'] && (
                  <button type="submit" disabled={isSubmitting || Object.keys(errors).length} className="button">
                    {t('editEvent.eventDetails.saveBtn')}
                  </button>
                )}
                <button
                  type="button"
                  className="button button--secondary"
                  onClick={() => {
                    navigateToEventListPage();
                  }}
                >
                  {t('editEvent.eventDetails.cancelBtn')}
                </button>
                <NotificationStatusIndicator
                  hideNotification={!submitMessage}
                  message={formattedErrorMessages[submitMessage] || submitMessage}
                  type={submitStatus}
                />
              </div>
            </form>
          </div>
        )}
      />
    );
  }
);
