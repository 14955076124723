import { connect } from 'react-redux';
import { compose } from 'redux';
import PropertyManagersList from './PropertyManagersList';
import { withRouter } from 'react-router-dom';
import { removePropertyManager, resendInvite } from '../../../../../../redux/actions';
import { getUserPermission } from '../../../../../..../../../../../redux/selectors';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    propertyManagers: state.property.activeProperty.get('staff'),
    removePropertyManagerLoading: state.property.removePropertyManagerLoading,
    userPermissionList: getUserPermission(state),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const propertyId = ownProps.match.params.propertyId;

  return {
    removePropertyManager: propertyManagerId => dispatch(removePropertyManager(propertyId, propertyManagerId)),
    resendInvite: userId => dispatch(resendInvite(propertyId, userId, 'property-manager')),
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(withTranslation(['propertyManagers', 'buttons'])(PropertyManagersList));
