import React from 'react';
import { connect } from 'react-redux';
import SubHeader from '../SubHeader';
import { CommunityAccessCode } from './AccessCode';
import { setCommunityAccessCodeOnCommunityStaff } from '../../views/Property/redux/actions';
import { useCommunityAccessCodeMutations } from './mutations/useCommunityAccessCodeMutations';
import { getUserPermission } from '../../redux/selectors';
import { withTranslation } from 'react-i18next';

const CommunityStaffAccessCodesComponent = ({
  communityStaff,
  userPermissionList,
  setCommunityAccessCodeOnCommunityStaff,
  t,
}) => {
  const communityAccessCodeMutations = useCommunityAccessCodeMutations(
    communityStaff,
    'property_access_code_id',
    setCommunityAccessCodeOnCommunityStaff,
    { communityStaffId: communityStaff.get('id') }
  );

  const userPermission = {
    showUnhideUsersAccessCodes: userPermissionList.includes("SHOW_(UNHIDE)_USER'S_ACCESS_CODES"),
    resendResidentsAccessCodes: userPermissionList.includes("RESEND_A_RESIDENT'S_ACCESS_CODES"),
    resetAResidentsAccessCodes: userPermissionList.includes("RESET_A_RESIDENT'S_ACCESS_CODES"),
    removeAResidentsAccessCodes: userPermissionList.includes("REMOVE_A_RESIDENT'S_ACCESS_CODES"),
    ChangResidentAccessControlProfile: userPermissionList.includes("CHANGE_A_RESIDENT'S_ACCESS_CONTROL_PROFILE"),
    EditResidentPersonalDetail: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
  };
  return (
    <div>
      <SubHeader underline title={t('details.accessCodes')} />
      <CommunityAccessCode
        code={communityStaff.getIn(['property_access_code', 'code'])}
        hasCode={communityStaff.get('property_access_code_id')}
        error={communityAccessCodeMutations.error}
        isError={communityAccessCodeMutations.isError}
        isLoading={communityAccessCodeMutations.isLoading}
        isSuccess={communityAccessCodeMutations.isSuccess}
        onGenerate={communityAccessCodeMutations.create}
        onRemove={communityAccessCodeMutations.remove}
        onReset={communityAccessCodeMutations.resetCode}
        onSendReminder={communityAccessCodeMutations.sendReminder}
        userPermission={userPermission}
      />
    </div>
  );
};

export const CommunityStaffAccessCodes = connect(
  state => ({
    communityStaff: state.property.propertyManager,
    userPermissionList: getUserPermission(state),
  }),
  { setCommunityAccessCodeOnCommunityStaff }
)(withTranslation('propertyManagers')(CommunityStaffAccessCodesComponent));
