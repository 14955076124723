import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment-ru';

import en from './locales/en';
import ru from './locales/ru';

export const languages = [
  { value: 'en', label: 'EN' },
  { value: 'ru', label: 'РУ' },
];

const resources = {
  en,
  ru,
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    format: function(value, formatting, lng) {
      // eslint-disable-next-line curly
      if (value instanceof Date) return moment(value).format(formatting);
      return value.toString();
    },
  },
});

i18n.on('languageChanged', function(lng) {
  moment.locale(lng);
});

export default i18n;
