import React, { useState } from 'react';
import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';

import { amenityReservationTypeValidationSchema } from '../validationSchema';
import { ErrorBanner } from '../../../../../../../common/ErrorBanner';
import AlertMessage from '../../../../../../../common/AlertMessage';
import NotificationStatusIndicator from '../../../../../../../common/forms/NotificationStatusIndicator';
import { ReservationType } from '../tabs/ReservationType';
import { useRefetchAmenityById, useUpdateAmenity, useAmenityHasReservations } from '../hooks';
import Dinero from 'dinero.js';
import { dollarsToCents } from '../../Events/util';
import EditAmenityRouteLeavingGuard from '../tabs/EditAmenityRouteLeavingGuard';
import { getUserPermission } from '../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const EditReservationTypeForm = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ amenity, history, match, userPermissionList }) => {
    const userPermission = {
      'Edit/Delete Amenity': userPermissionList.includes('EDIT/DELETE_AMENITY'),
    };
    const [submitMessage, setSubmitMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);
    const [reasonForCancelAll, setReasonForCancelAll] = useState('');

    const updateAmenity = useUpdateAmenity(amenity?.id);
    const refetchAmenity = useRefetchAmenityById(amenity?.id);
    const amenityHasReservations = useAmenityHasReservations(amenity.id);
    const { t } = useTranslation('amenities', {
      keyPrefix: 'amenityPage',
    });
    const { t: tCommon } = useTranslation('buttons');

    const priceInDollars =
      amenity.price && !isNaN(amenity.price) ? Dinero({ amount: Number(amenity.price) }).toUnit() : '';

    return (
      <Formik
        initialValues={{
          reservation_type: amenity.reservation_type,
          min_reservation: amenity.min_reservation || '',
          max_reservation: amenity.max_reservation || '',
          cancelation_policy: amenity.cancelation_policy || '',
          refund_policy: amenity.refund_policy || '',
          price: priceInDollars || '',
          price_currency: amenity.price_currency || 'USD',
          refund_cutoff_time: amenity.refund_cutoff_time || '',
          cancel_fee_percent: amenity.cancel_fee_percent || '',
        }}
        onSubmit={(formValues, formikActions) => {
          const submitValues = cloneDeep(formValues);

          if (submitValues.reservation_type === 'NON_RESERVABLE') {
            submitValues.min_reservation = null;
            submitValues.max_reservation = null;
            submitValues.cancelation_policy = null;

            if (reasonForCancelAll) {
              submitValues.cancel_all_reservations_reason = reasonForCancelAll;
            }
          }

          if (submitValues.price) {
            submitValues.price = dollarsToCents(submitValues.price);
          } else {
            submitValues.price = 0;
          }

          if (submitValues.cancel_fee_percent) {
            submitValues.cancel_fee_percent = Number(submitValues.cancel_fee_percent);
          } else {
            submitValues.cancel_fee_percent = 0;
          }

          if (submitValues.refund_cutoff_time) {
            submitValues.refund_cutoff_time = Number(submitValues.refund_cutoff_time);
          } else {
            submitValues.refund_cutoff_time = null;
          }

          if (!submitValues.refund_policy) {
            submitValues.refund_policy = null;
          }

          updateAmenity.mutate(submitValues, {
            onSuccess: () => {
              refetchAmenity().then(() => {
                formikActions.resetForm({ values: formValues });
                setSubmitMessage(t('messages.edited'));
                setSubmitStatus('Success');
              });
            },
            onError: e => {
              formikActions.setSubmitting(false);
              setSubmitMessage(e.message);
              setSubmitStatus('Failure');
            },
          });
        }}
        validationSchema={amenityReservationTypeValidationSchema(t)}
        render={({ values, dirty, errors, handleChange, setFieldValue, touched, handleSubmit, isSubmitting }) => (
          <>
            <EditAmenityRouteLeavingGuard when={dirty} navigate={history.push} />
            <div className="container">
              <ErrorBanner hasError={Object.keys(errors).some(fieldName => touched[fieldName])} />
              {amenity.isError ? (
                <AlertMessage
                  type="error"
                  msg={
                    'There was an error when attempting to edit the amenity. Please contact SmartExperience support.'
                  }
                />
              ) : null}
              <form onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius top-right">
                  <ReservationType
                    {...{
                      mode: 'edit',
                      errors,
                      handleChange,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values,
                      amenityHasReservations,
                      amenity,
                      setReasonForCancelAll,
                    }}
                  />
                </div>

                <div className="paper__footer radius-bottom-left radius-bottom-right">
                  {userPermission['Edit/Delete Amenity'] && (
                    <button
                      type="button"
                      className="button"
                      disabled={isSubmitting}
                      onClick={() => {
                        setSubmitMessage();
                        handleSubmit();
                      }}
                    >
                      {isSubmitting ? tCommon('saving') : t('saveButton')}
                    </button>
                  )}
                  <button
                    type="button"
                    className="button button--secondary"
                    onClick={() => history.push(`/properties/${match.params.propertyId}/community/amenities`)}
                    disabled={isSubmitting}
                  >
                    {tCommon('cancel')}
                  </button>
                  <NotificationStatusIndicator
                    hideNotification={!submitMessage}
                    message={submitMessage}
                    type={submitStatus}
                  />
                </div>
              </form>
            </div>
          </>
        )}
      />
    );
  }
);
