import React, { useCallback, useEffect, useState } from 'react';
import querystring from 'query-string';
import localstorage from 'store2';

import config from '../../../../../../common/config';
import Loading from '../../../../../../common/Loading';
import SubHeader from '../../../../../../common/SubHeader';
import ModalAlert from '../../../../../../common/ModalAlert';
import Icon from '../../../../../../common/icons/icon';
import ResidentGuestsFilterForm from './ResidentGuestsFilterForm';
import ResidentGuestsTable from './ResidentGuestsTable';
import { useTranslation } from 'react-i18next';

const revokeErrors = {
  CannotRevokeExpiredOrRemovedGuests:
    "Unable to revoke community access. Guest's access has already expired or they been removed by the resident. Please refresh your browser to see the changes.",
};

export default function ResidentGuests({
  getUnitGuestList,
  history,
  location,
  match,
  revokeUnitGuestCommunityAccess,
  unitGuests,
  unitGuestsLoaded,
}) {
  const { t } = useTranslation(['residentGuests', 'buttons']);
  const [idOfGuestToRevokeCommunityAccess, setIdOfGuestToRevokeCommunityAccess] = useState(null);
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [revokeError, setRevokeError] = useState(null);

  const filterParams = querystring.parse(location.search);

  useFetchUnitGuests(getUnitGuestList, filterParams);

  return (
    <div>
      <div className="container">
        <ModalAlert
          title={t('alertModal.title')}
          body={t('alertModal.body')}
          confirmButtonTitle={t('alertModal.confirmButtonTitle')}
          cancelButtonTitle={t('cancel', { ns: 'buttons' })}
          inputForm
          destructive
          handleConfirm={comment =>
            revokeUnitGuestCommunityAccess(idOfGuestToRevokeCommunityAccess, comment).then(action => {
              if (action.response.ok) {
                setIdOfGuestToRevokeCommunityAccess(null);
                setRevokeError(null);
              } else {
                setRevokeError(action.json.message);
              }
            })
          }
          handleCancel={() => {
            setIdOfGuestToRevokeCommunityAccess(null);
            setRevokeError(null);
          }}
          visible={!!idOfGuestToRevokeCommunityAccess}
          error={revokeErrors[revokeError] ?? revokeError}
        />
        <SubHeader
          title={t('main.title')}
          actions={[
            <button
              type="button"
              disabled={!unitGuests.size}
              className="button button--outline csv-button"
              style={{ minHeight: '48px' }}
              onClick={() => {
                const qs = {
                  token: localstorage.get('idToken'),
                  ...filterParams,
                };

                window.location = `${config.apiUrl}properties/${
                  match.params.propertyId
                }/guest-list.csv?${querystring.stringify(qs)}`;
              }}
            >
              {t('main.downloadCsv')}
            </button>,
            <button
              type="button"
              className="button button--outline button--icon"
              onClick={() => {
                setIsShowingFilters(isShowingFilters => !isShowingFilters);
              }}
            >
              <div className="button--children">
                <span>{t('main.filters')}</span>
                <Icon icon="Filter" />
              </div>
            </button>,
          ]}
        />

        {isShowingFilters && (
          <ResidentGuestsFilterForm
            onSubmit={values => {
              history.push({
                pathname: location.pathname,
                search: querystring.stringify(values),
              });
              setIsShowingFilters(false);
            }}
            initialValues={filterParams}
            onCancel={() => setIsShowingFilters(false)}
          />
        )}

        {!unitGuestsLoaded ? (
          <Loading />
        ) : (
          <ResidentGuestsTable
            accessLogPathname={`/properties/${match.params.propertyId}/access-control/access-requests`}
            unitGuests={unitGuests}
            onClickRevoke={setIdOfGuestToRevokeCommunityAccess}
          />
        )}
      </div>
    </div>
  );
}

const useFetchUnitGuests = (
  fetchAction,
  { residentName, residentUnit, guestName, startDate, endDate, communityAccessStatus }
) => {
  const memoizedFetchAction = useCallback(fetchAction, []);

  useEffect(() => {
    memoizedFetchAction({
      residentName: residentName || '',
      residentUnit: residentUnit || '',
      guestName: guestName || '',
      startDate: startDate || '',
      endDate: endDate || '',
      communityAccessStatus: communityAccessStatus || '',
    });
  }, [memoizedFetchAction, residentName, residentUnit, guestName, startDate, endDate, communityAccessStatus]);
};
