import React, { useState } from 'react';
import Icon from '../../../../../../common/icons/icon';
import { RESERVATION_TYPES } from './constants/reservationTypes';
import { useTranslation } from 'react-i18next';

const SummaryModalImageGallery = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  return (
    <div className="summary-modal-image-gallery">
      <div>
        <img alt="selected-amenity" src={images[selectedImageIndex].url} />
      </div>
      <div className="summary-modal-image-gallery__list">
        {images.map((image, i) => (
          <div
            className="summary-modal-image-gallery__mini-preview"
            key={image.id || image.tempId}
            onClick={() => setSelectedImageIndex(i)}
            style={{
              backgroundImage: `url(${image.url})`,
            }}
          >
            {selectedImageIndex === i && (
              <div className="active-filter">
                <Icon icon="ImgCrosshair" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const SummaryModalAvailability = ({ availability, availabilitySchedule }) => {
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.availability' });
  const { t: tTime } = useTranslation('time');

  const weeks = [
    { key: 'sunday', display: t('weeks.sun') },
    { key: 'monday', display: t('weeks.mon') },
    { key: 'tuesday', display: t('weeks.tue') },
    { key: 'wednesday', display: t('weeks.wed') },
    { key: 'thursday', display: t('weeks.thu') },
    { key: 'friday', display: t('weeks.fri') },
    { key: 'saturday', display: t('weeks.sat') },
  ];

  return (
    <section className="paper__section">
      <h4 className="section-header h4">{t('title')}</h4>
      <div className="details--read-only__wrapper">
        <div className="details--read-only">
          <div className="read-only--label">{t('title')}</div>
          <div className="read-only--value capitalize">{availability.toLowerCase()}</div>
        </div>
        {['DAILY', 'CUSTOM'].includes(availability) && (
          <div className="details--read-only">
            <div className="read-only--label">{t('subtitle')}</div>
            <div className="availability--days-of-week--list">
              {weeks.map(({ key, display }) => {
                const scheduleForDay = availabilitySchedule[key];

                let hoursText = tTime('closed');
                if (scheduleForDay) {
                  const openTimeFormatString = tTime('timeFormat') || 'HH:mm';
                  const closeTimeFormatString = tTime('timeFormat') || 'HH:mm';
                  hoursText = `${scheduleForDay.open.format(openTimeFormatString)} - ${scheduleForDay.close.format(
                    closeTimeFormatString
                  )}`;
                }

                return (
                  <div key={key} className="availability--day-of-week">
                    <div>{display}</div>
                    <div>{hoursText}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const SummaryModalReservationType = ({
  availability,
  cancelationPolicy,
  maxReservation,
  minReservation,
  reservationType,
  price,
  cancelFeePercent,
  refundCutoffTime,
  refundPolicy,
}) => {
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.reservationType' });
  const { t: tTime } = useTranslation('time');

  const reservationTypeDisplayValue = RESERVATION_TYPES(t).find(item => item.name === reservationType)?.display;

  const AvailabilityIsNone = () => {
    return (
      <section className="paper__section">
        <h4 className="section-header h4">{t('details')}</h4>
        <div className="details--read-only__wrapper">
          <div className="details--read-only">
            <div className="read-only--label">{t('title')}</div>
            <div className="read-only--value">{t('types.default.title')}</div>
          </div>
        </div>
      </section>
    );
  };

  const AvailabilityIsOther = () => {
    return (
      <section className="paper__section">
        <h4 className="section-header h4">{t('details')}</h4>
        <div className="details--read-only__wrapper">
          <div className="details--read-only">
            <div className="read-only--label">{t('title')}</div>
            <div className="read-only--value">{reservationTypeDisplayValue}</div>
          </div>
          {['FREE', 'PAID'].includes(reservationType) && (
            <>
              <div className="details--read-only">
                <div className="read-only--label">{t('types.free.minReservationField')}</div>
                <div className="read-only--value">
                  {minReservation >= 60
                    ? minReservation % 60 > 0
                      ? minReservation > 120
                        ? Math.floor(minReservation / 60) +
                          ` ${tTime('hours')} ${tTime('and')} ` +
                          (minReservation % 60) +
                          ` ${tTime('minutes')}`
                        : `1 ${tTime('hour')} ${tTime('and')} ` + (minReservation % 60) + ` ${tTime('minutes')}`
                      : minReservation > 60
                      ? minReservation / 60 + ` ${tTime('hours')}`
                      : `1 ${tTime('hour')}`
                    : minReservation + ` ${tTime('minutes')}`}
                </div>
              </div>
              <div className="details--read-only">
                <div className="read-only--label">{t('types.free.maxReservationField')}</div>
                <div className="read-only--value">
                  {maxReservation >= 60
                    ? maxReservation % 60 > 0
                      ? maxReservation > 120
                        ? Math.floor(maxReservation / 60) +
                          ` ${tTime('hours')} ${tTime('and')} ` +
                          (maxReservation % 60) +
                          ` ${tTime('minutes')}`
                        : `1 ${tTime('hour')} ${tTime('and')} ` + (maxReservation % 60) + ` ${tTime('minutes')}`
                      : maxReservation > 60
                      ? maxReservation / 60 + ` ${tTime('hours')}`
                      : `1 ${tTime('hour')}`
                    : maxReservation + ` ${tTime('minutes')}`}
                </div>
              </div>
              <div className="details--read-only">
                <div className="read-only--label">{t('types.free.cancelationPolicy.title')}</div>
                <div className="read-only--value">{cancelationPolicy}</div>
              </div>
            </>
          )}

          {['PAID'].includes(reservationType) && (
            <>
              <div className="details--read-only">
                <div className="read-only--label">{t('types.paid.pricePerIncrementField')}</div>
                <div className="read-only--value">${price}</div>
              </div>
              <div className="details--read-only">
                <div className="read-only--label">{t('types.paid.cancelationFeeField')}</div>
                <div className="read-only--value">{cancelFeePercent}%</div>
              </div>
              {refundPolicy && (
                <div className="details--read-only">
                  <div className="read-only--label">{t('types.paid.cancelationPolicy.refundField')}</div>
                  <div className="read-only--value">{refundPolicy}</div>
                </div>
              )}
              {refundCutoffTime && (
                <div className="details--read-only">
                  <div className="read-only--label">{t('types.paid.refundField')}</div>
                  <div className="read-only--value">
                    {refundCutoffTime} {refundCutoffTime === '1' ? tTime('hour') : tTime('hours')}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    );
  };

  return <div>{availability === 'NONE' ? AvailabilityIsNone() : AvailabilityIsOther()}</div>;
};

export const AmenitySummaryModal = ({ formValues, isSubmitting, handleSubmit, onClose }) => {
  const { t: tModal } = useTranslation('amenities', { keyPrefix: 'amenityPage.modals.preview' });
  const { t: tCommon } = useTranslation('buttons');
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs' });

  return (
    <div className="modal modal--visible amenity-summary-modal">
      <div className="modal__children summary--modal__children">
        <div className="modal__header">
          <h3>{tModal('title')}</h3>
          <div className="modal__close" onClick={onClose}>
            <Icon icon="Clear" />
          </div>
        </div>
        <div className="modal__body">
          <p className="modal__body--text">{tModal('subtitle')}</p>
          <div className="paper__grid">
            <div className="paper__section--grid-item">
              <section className="paper__section">
                <h4 className="section-header h4">{t('amenityDetails.title')}</h4>
                <div className="details--read-only__wrapper">
                  <div className="details--read-only">
                    <div className="read-only--label">{t('amenityDetails.sections.amenityDetails.nameField')}</div>
                    <div className="read-only--value title">{formValues.name}</div>
                  </div>
                  <div className="details--read-only">
                    <div className="read-only--label">
                      {t('amenityDetails.sections.amenityDetails.descriptionField')}
                    </div>
                    <div className="read-only--value">{formValues.description}</div>
                  </div>
                  <div className="details--read-only">
                    <div className="read-only--label">
                      {t('amenityDetails.sections.amenityDetails.maxOccupancyField')}
                    </div>
                    <div className="read-only--value">{formValues.max_occupancy || '--'}</div>
                  </div>
                  <div className="details--read-only">
                    <div className="read-only--label">{t('amenityDetails.sections.termsOfUse.termOfUseField')}</div>
                    <div className="read-only--value">{formValues.terms_of_use || '--'}</div>
                  </div>
                </div>
                <div className="details--read-only__wrapper">
                  <SummaryModalReservationType
                    availability={formValues.availability}
                    cancelationPolicy={formValues.cancelation_policy}
                    minReservation={formValues.min_reservation}
                    maxReservation={formValues.max_reservation}
                    reservationType={formValues.reservation_type}
                    price={formValues.price}
                    cancelFeePercent={formValues.cancel_fee_percent}
                    refundCutoffTime={formValues.refund_cutoff_time}
                    refundPolicy={formValues.refund_policy}
                  />
                </div>
              </section>
            </div>
            <div className="paper__section--grid-item">
              <section className="paper__section">
                <h4 className="section-header h4">{t('images.title')}</h4>
                <div className="details--read-only__wrapper">
                  <div className="details--read-only">
                    {formValues.images.length > 0 ? (
                      <SummaryModalImageGallery images={formValues.images} />
                    ) : (
                      <div className="read-only--value">{t('images.none')}</div>
                    )}
                  </div>
                  <SummaryModalAvailability
                    availability={formValues.availability}
                    availabilitySchedule={formValues.availability_schedule}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="modal__button--container">
          <button type="button" className="button" disabled={isSubmitting} onClick={handleSubmit}>
            {isSubmitting ? tCommon('saving') : tCommon('confirm')}
          </button>
          <button type="button" className="button button--secondary" disabled={isSubmitting} onClick={onClose}>
            {tCommon('cancel')}
          </button>
        </div>
      </div>
      <button type="button" className="modal__overlay" onClick={onClose} />
    </div>
  );
};
