import React, { useState } from 'react';
import PageHeader from '../../../../../../../common/PageHeader';
import { Tab, TabsContainer } from '../../../../../../../common/Tabs';
import {
  useGetAmenityById,
  useRefetchAmenityById,
  useCloseAmenity,
  useOpenAmenity,
  useCreateClosureAnnouncement,
} from '../hooks';
import Loading from '../../../../../../../common/Loading';
import { Reservations } from '../tabs/Reservations';
import Kebab from '../../../../../../../common/Kebab';
import Icon from '../../../../../../../common/icons/icon';
import NotificationStatusIndicator from '../../../../../../../common/forms/NotificationStatusIndicator';
import { DeleteAmenityModal } from '../DeleteAmenityModal';
import { CloseAmenityModal } from './CloseAmenityModal';
import EditAmenityTabRoutes from './EditAmenityTabRoutes';
import { useTranslation } from 'react-i18next';

const formattedErrorMessages = {
  AlreadyOpen: 'Amenity has already been already opened.',
  AlreadyClosed: 'Amenity has already been closed.',
};

export const EditAmenity = ({ history, match, location }) => {
  const [kebabMenuOpen, setKebabMenuOpen] = useState(false);
  const [closeAmenityModalVisible, setCloseAmenityModalVisible] = useState(false);

  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage' });

  const amenityId = match.params.amenityId;
  const amenityById = useGetAmenityById(amenityId);
  const amenity = amenityById.data;
  const refetchAmenity = useRefetchAmenityById(amenity?.id);
  const closeAmenity = useCloseAmenity(amenityId);
  const openAmenity = useOpenAmenity(amenityId);
  const [deleteModalAmenity, setDeleteModalAmenity] = useState(false);

  const sendAnnouncement = useCreateClosureAnnouncement(amenity?.community_id);
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [announcementStatus, setAnnouncementStatus] = useState(null);

  const createAnnouncement = announcementData => {
    if (!announcementData?.announcement_message) {
      return;
    }

    setAnnouncementStatus(null);
    sendAnnouncement.mutate(
      {
        title: announcementData.announcement_title || `${amenity.name} has closed indefinitely.`,
        description: announcementData.announcement_message,
        all_residents_selected: true,
      },
      {
        onError: e => {
          setAnnouncementStatus('There was an issue sending the announcement: ' + e.message);
        },
      }
    );
  };

  const tabs = [
    {
      label: t('tabs.amenityDetails.title'),
      route: `details`,
    },
    {
      label: t('tabs.images.title'),
      route: `images`,
    },
    {
      label: t('tabs.availability.title'),
      route: `availability`,
    },
    {
      label: t('tabs.reservationType.title'),
      route: `reservation-type`,
    },
    ...(amenity && amenity.reservation_type !== 'NON_RESERVABLE'
      ? [
          {
            label: t('tabs.reservations.title'),
            component: Reservations,
            route: `reservations`,
          },
        ]
      : []),
  ];

  return (
    <>
      <PageHeader
        title={amenity ? amenity.name : t('loading')}
        white
        withTabs
        backLink={`/properties/${match.params.propertyId}/community/amenities`}
        tag={amenity?.closed_on ? t('tags.closed') : t('tags.open')}
      />

      {!amenityById.isFetchedAfterMount && <Loading />}

      {amenityById.isFetchedAfterMount && amenity && (
        <>
          {deleteModalAmenity && (
            <DeleteAmenityModal
              amenityId={amenityId}
              onDelete={() => {
                setDeleteModalAmenity(false);
                history.push(`/properties/${match.params.propertyId}/community/amenities`);
              }}
              onClose={() => setDeleteModalAmenity(false)}
            />
          )}
          <CloseAmenityModal
            onClose={() => {
              setCloseAmenityModalVisible(false);
            }}
            onConfirm={formValues => {
              setCloseAmenityModalVisible(false);
              createAnnouncement(formValues);
              closeAmenity.mutate(null, {
                onSuccess: () => {
                  setNotificationStatus({ type: 'Success', message: 'Amenity closed successfully!' });

                  refetchAmenity();
                  createAnnouncement();
                },
                onError: e => {
                  setNotificationStatus({ type: 'Failure', message: e.message });
                },
              });
            }}
            visible={closeAmenityModalVisible}
          />
          <TabsContainer
            kebab={
              <Kebab
                onClick={() => {
                  setKebabMenuOpen(true);
                  setNotificationStatus(null);
                }}
                onDisengage={() => setKebabMenuOpen(false)}
                menuOpened={kebabMenuOpen}
              >
                <div className="kebab__menu">
                  <div
                    className="kebab__menu--item"
                    onClick={() => {
                      if (!amenity.closed_on) {
                        setCloseAmenityModalVisible(true);
                      } else {
                        setKebabMenuOpen(false);
                        openAmenity.mutate(null, {
                          onSuccess: () => {
                            setNotificationStatus({ type: 'Success', message: 'Amenity opened successfully!' });
                            refetchAmenity();
                          },
                          onError: e => {
                            setNotificationStatus({ type: 'Failure', message: e.message });
                          },
                        });
                      }
                    }}
                  >
                    <Icon className="amenity-closed--toggle" icon={amenity.closed_on ? 'SwitchOff' : 'SwitchOn'} />
                    <span>{`${t('simpleTitle')} ${amenity.closed_on ? t('tags.closed') : t('tags.open')}`}</span>
                  </div>
                  <div
                    className="kebab__menu--item"
                    onClick={() => {
                      setKebabMenuOpen(false);
                      setDeleteModalAmenity(true);
                    }}
                  >
                    <Icon icon="TrashOutline" />
                    <span>{t('modals.amenityListItem.delete')}</span>
                  </div>
                </div>
              </Kebab>
            }
          >
            {tabs.map((tab, i) => (
              <Tab
                key={tab.label}
                label={tab.label}
                isActive={location.pathname.endsWith(tab.route)}
                onPress={() => history.push(`${match.url}/${tab.route}`)}
              />
            ))}
          </TabsContainer>
          <div className="container">
            <NotificationStatusIndicator
              hideNotification={!notificationStatus}
              message={
                formattedErrorMessages[notificationStatus?.message]
                  ? formattedErrorMessages[notificationStatus?.message]
                  : notificationStatus?.message
              }
              type={notificationStatus?.type}
            />
            <NotificationStatusIndicator
              hideNotification={!announcementStatus}
              message={announcementStatus}
              type={'Failure'}
            />
          </div>

          <EditAmenityTabRoutes match={match} amenity={amenity} />
        </>
      )}
    </>
  );
};
