import React, { useState } from 'react';
import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';

import { ErrorBanner } from '../../../../../../../common/ErrorBanner';
import AlertMessage from '../../../../../../../common/AlertMessage';
import NotificationStatusIndicator from '../../../../../../../common/forms/NotificationStatusIndicator';
import { Images } from '../tabs/Images';
import { useRefetchAmenityById, useUpdateAmenity } from '../hooks';
import EditAmenityRouteLeavingGuard from '../tabs/EditAmenityRouteLeavingGuard';
import { getUserPermission } from '../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import Icon from '../../../../../../../common/icons/icon';
import { useTranslation } from 'react-i18next';

export const EditImagesForm = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ amenity, history, match, userPermissionList }) => {
    const userPermission = {
      'Edit/Delete Amenity': userPermissionList.includes('EDIT/DELETE_AMENITY'),
    };
    const { t: tCommon } = useTranslation('buttons');
    const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage' });
    const [submitMessage, setSubmitMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);

    const updateAmenity = useUpdateAmenity(amenity?.id);
    const refetchAmenity = useRefetchAmenityById(amenity?.id);

    const getImageData = images => images.map(image => ({ id: image.id, url: image.image_url }));

    return (
      <Formik
        initialValues={{
          images: getImageData(amenity.images),
        }}
        onSubmit={(formValues, formikActions) => {
          const submitValues = cloneDeep(formValues);

          submitValues.images = submitValues.images.map(image => {
            return image.id ? { id: image.id } : { url: image.url };
          });

          updateAmenity.mutate(submitValues, {
            onSuccess: data => {
              refetchAmenity().then(() => {
                formikActions.resetForm({
                  values: { images: getImageData(data.images) },
                });
                setSubmitMessage(t('messages.edited'));
                setSubmitStatus('Success');
              });
            },
            onError: e => {
              formikActions.setSubmitting(false);
              setSubmitMessage(e.message);
              setSubmitStatus('Failure');
            },
          });
        }}
      >
        {({ values, dirty, errors, handleChange, setFieldValue, touched, handleSubmit, isSubmitting }) => (
          <>
            <EditAmenityRouteLeavingGuard when={dirty} navigate={history.push} />
            <div className="container">
              <ErrorBanner hasError={Object.keys(errors).some(fieldName => touched[fieldName])} />
              {amenity.isError ? (
                <AlertMessage
                  type="error"
                  msg="There was an error when attempting to edit the amenity. Please contact SmartExperience support."
                />
              ) : null}
              <form onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius top-right">
                  <Images
                    {...{
                      errors,
                      handleChange,
                      setFieldValue,
                      touched,
                      values,
                    }}
                  />
                </div>

                <div className="paper__footer wizard radius-bottom-left radius-bottom-right">
                  <div className="button--wrapper__grouping edit-button-margin">
                    {userPermission['Edit/Delete Amenity'] && (
                      <button
                        type="button"
                        className="button"
                        disabled={isSubmitting}
                        onClick={() => {
                          setSubmitMessage();
                          handleSubmit();
                        }}
                      >
                        {isSubmitting ? tCommon('saving') : t('saveButton')}
                      </button>
                    )}
                    <button
                      type="button"
                      className="button button--secondary"
                      onClick={() => history.push(`/properties/${match.params.propertyId}/community/amenities`)}
                      disabled={isSubmitting}
                    >
                      {tCommon('cancel')}
                    </button>
                    <NotificationStatusIndicator
                      hideNotification={!submitMessage}
                      message={submitMessage}
                      type={submitStatus}
                    />
                  </div>
                  <div className="button--wrapper__grouping edit-button-margin">
                    {submitMessage && (
                      <button
                        type="button"
                        className="button button--icon"
                        onClick={e => {
                          e.preventDefault();
                          history.push(
                            `/properties/${match.params.propertyId}/community/amenities/${match.params.amenityId}/availability`
                          );
                        }}
                      >
                        <div className="button--children">
                          <span>{tCommon('next')}</span>
                          <Icon icon="ArrowLongRight" />
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </Formik>
    );
  }
);
