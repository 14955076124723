import React from 'react';
import { connect } from 'react-redux';
import PageHeader from '../../../../../../../../../../common/PageHeader';
import { Tab, TabsContainer } from '../../../../../../../../../../common/Tabs';
import { Route, Switch } from 'react-router-dom';
import { ResidentAccessCodes } from './ResidentAccessCodes';
import { ResidentCredentials } from './ResidentCredentials';
import ResidentInformation from './ResidentInformation';
import { withTranslation } from 'react-i18next';

const EditResident = ({ property, location, history, match, t }) => {
  const propertyHasAccessControl =
    property.property.activeProperty && property.property.activeProperty.get('admin_enabled_access_control');
  let UserPermission = property.user.get('permissions');
  return (
    <div>
      <PageHeader
        title={t('edit.title')}
        white
        withTabs
        backLink={location.pathname === match.url ? '../../' : '../../../'}
      />
      <TabsContainer>
        {UserPermission.includes('VIEW_RESIDENT_CONTACT_INFORMATION') && (
          <Tab
            label={t('edit.informationTab.title')}
            isActive={location.pathname === match.url}
            onPress={() => {
              history.push(`${match.url}`);
            }}
          />
        )}
        <Tab
          label={t('edit.accessCodesTab.title')}
          isActive={location.pathname === `${match.url}/access-codes`}
          onPress={() => {
            history.push(`${match.url}/access-codes`);
          }}
        />
        {UserPermission.includes("ADD/EDIT/REMOVE_A_RESIDENT'S_PHYSICAL_ACCESS_CREDENTIAL") &&
        propertyHasAccessControl ? (
          <Tab
            label={t('edit.credentialsTab.title')}
            isActive={location.pathname === `${match.url}/credentials`}
            onPress={() => {
              history.push(`${match.url}/credentials`);
            }}
          />
        ) : null}
      </TabsContainer>
      <Switch>
        {UserPermission.includes('VIEW_RESIDENT_CONTACT_INFORMATION') && (
          <Route exact path={`${match.path}/`} component={ResidentInformation} />
        )}
        <Route exact path={`${match.path}/access-codes`} component={ResidentAccessCodes} />
        {UserPermission.includes("ADD/EDIT/REMOVE_A_RESIDENT'S_PHYSICAL_ACCESS_CREDENTIAL") &&
        propertyHasAccessControl ? (
          <Route exact path={`${match.path}/credentials`} component={ResidentCredentials} />
        ) : null}
      </Switch>
    </div>
  );
};

export default connect(state => ({ property: state }))(withTranslation('residents')(EditResident));
