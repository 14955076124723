import React from 'react';

import moment from 'moment';
import Loading from '../../../../../../../../common/Loading';
import Icon from '../../../../../../../../common/icons/icon';
import EventField from '../../../../../../../../common/ReadOnlyField';
import { useTranslation } from 'react-i18next';

export const ReadOnlyEventDetails = ({ event, isEventLoaded, navigateToEventListPage }) => {
  const { t } = useTranslation('events');

  if (!isEventLoaded) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div>
        <div className="paper radius-top-left radius top-right">
          <div className="paper__grid">
            <div className="paper__section--grid-item">
              <section className="paper__section">
                <h4 className="section-header h4">{t('editEvent.readOnlyDetails.header')}</h4>
                <div className="details--read-only__wrapper">
                  <EventField label={t('editEvent.readOnlyDetails.title')} value={event.title} />
                  <EventField label={t('editEvent.readOnlyDetails.descr')} value={event.description} />
                  <EventField label={t('editEvent.readOnlyDetails.location')} value={event.location} />
                  <EventField
                    label={t('editEvent.readOnlyDetails.dateTitle')}
                    value={moment(event.event_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                  />

                  <div className="details--read-only">
                    <div className="read-only--label">{t('editEvent.readOnlyDetails.time')}</div>
                    <div className="read-only--value flex">
                      <div className="">{moment(event.start_time, 'HH:mm').format('hh:mma')}</div>
                      <Icon icon="ArrowLongRight" />
                      <div className="">{moment(event.end_time, 'HH:mm').format('hh:mma')}</div>
                    </div>
                  </div>
                  <div className="paper__section">
                    <h4 className="section-header h4">{t('editEvent.readOnlyDetails.instructions')}</h4>
                    <div className="details--read-only">
                      <div className="read-only--value">
                        {!event.confirmation_details
                          ? t('editEvent.readOnlyDetails.noInstructions')
                          : event.confirmation_details}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="paper__section--grid-item">
              <section className="paper__section event-image__section">
                <div className="section-header h4">{t('editEvent.readOnlyDetails.image')}</div>
                <p className="help-text">{t('editEvent.readOnlyDetails.body')}</p>
                {event.image_url ? (
                  <img alt="Event" src={event.image_url} />
                ) : (
                  <div className="event-details__no-image-placeholder">
                    <Icon icon="ImageOff" />
                    <p>{t('editEvent.readOnlyDetails.noImage')}</p>
                  </div>
                )}
              </section>

              <section className="paper__section">
                <div className="section-header h4">{t('editEvent.readOnlyDetails.attendees')}</div>

                <div className="details--read-only__wrapper">
                  <div className="details--read-only">
                    <div className="read-only--label">{t('editEvent.readOnlyDetails.rspv')}</div>
                    <div className="read-only--value flex">
                      <div className="">{moment(event.rsvp_cutoff_date).format('MM/DD/YYYY')}</div>
                      <div className="">{moment(event.rsvp_cutoff_time, 'HH:mm').format('hh:mma')}</div>
                    </div>
                  </div>

                  <EventField label={t('editEvent.readOnlyDetails.atendeesMin')} value={event.min_attendees} />
                  <EventField label={t('editEvent.readOnlyDetails.atendeesMax')} value={event.max_attendees} />
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="paper__footer radius-bottom-left radius-bottom-right">
          <button
            type="button"
            className="button button--secondary"
            onClick={() => {
              navigateToEventListPage();
            }}
          >
            {t('editEvent.readOnlyDetails.backBtn')}
          </button>
        </div>
      </div>
    </div>
  );
};
