import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import UploadField from '../../../../../../common/forms/UploadField';
import NotificationStatusIndicator from '../../../../../../common/forms/NotificationStatusIndicator';
import PropertyPageHeader from '../../../../../../common/PropertyPageHeader';
import localstorage from 'store2';
import querystring from 'query-string';
import config from '../../../../../../common/config';

class ImportUnits extends Component {
  state = {
    success: false,
    error: false,
    file: '',
  };

  fileSelected = file => {
    this.setState({ file });
  };

  uploadCsv = () => {
    this.setState({
      success: false,
      error: false,
    });
    this.props.uploadUnitCsv(this.state.file).promise.then(response => {
      if (response.ok) {
        this.setState({ success: true });
      } else {
        response
          .json()
          .then(json => {
            this.setState({ error: json.message });
          })
          .catch(error => {
            this.setState({ error: 'Error importing CSV' });
          });
      }
    });
  };

  renderStatus() {
    return (
      <div>
        <NotificationStatusIndicator
          hideNotification={!this.state.success}
          message={'Import Successful!'}
          type="Success"
          updateFunc={() => this.setState({ success: false })}
        />
        <NotificationStatusIndicator
          hideNotification={!this.state.error}
          message={this.state.error}
          type="Failure"
          updateFunc={() => this.setState({ error: false })}
        />
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className="import-units__page">
        <PropertyPageHeader title={t('importUnitsTitle')} backLink="./" />
        <div className="container">
          <div className="paper radius-top-left radius-top-right">
            <h4 className="h4" style={{ marginBottom: 24 }}>
              {t('importUnitsSubTitle')}
            </h4>
            <UploadField onChange={this.fileSelected} />
            <div
              style={{ marginTop: 24 }}
              onClick={() => {
                const qs = {
                  token: localstorage.get('idToken'),
                };

                delete qs.page;
                delete qs.page_size;

                window.location = `${config.apiUrl}properties/${
                  this.props.match.params.propertyId
                }/import-unit-sample.csv?${querystring.stringify(qs)}`;
              }}
            >
              <p className="download-csv-link">{t('importUnitsText')}</p>
            </div>
          </div>
          <div className="paper__footer radius-bottom-left radius-bottom-right">
            <button disabled={this.state.file ? false : true} type="button" className="button" onClick={this.uploadCsv}>
              {t('importUnitsSubmitBtn')}
            </button>
            {this.renderStatus()}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('units')(ImportUnits);
