import React from 'react';
import moment from 'moment';
import Kebab from '../../../../../../../../common/Kebab';
import Icon from '../../../../../../../../common/icons/icon';
import { useTranslation } from 'react-i18next';

export const EventRegistrantItem = ({
  eventRegistration,
  isKebabMenuOpen,
  onCancelRegistration,
  openKebabMenu,
  closeKebabMenu,
  cancelEventRegistrationPermission,
}) => {
  const { t } = useTranslation('events', { keyPrefix: 'editEvent.registrations' });

  return (
    <tr>
      <td>{eventRegistration.users[0].first_name + ' ' + eventRegistration.users[0].last_name}</td>
      <td>{moment(eventRegistration.created_at, 'YYYY-MM-DD').format('MMMM D, YYYY')}</td>
      <td>{eventRegistration.tickets + 1}</td>
      <td>{eventRegistration.is_canceled ? <>{eventRegistration.canceled_by}</> : <>{eventRegistration.status}</>}</td>
      <td>
        {!eventRegistration.is_canceled && eventRegistration.status === 'ACTIVE' ? (
          <>
            {cancelEventRegistrationPermission && (
              <Kebab onClick={openKebabMenu} onDisengage={closeKebabMenu} menuOpened={isKebabMenuOpen}>
                <div className="kebab__menu">
                  <div className="kebab__menu--item" onClick={onCancelRegistration}>
                    <Icon icon="CircleX" /> {t('cancelKebab')}
                  </div>
                </div>
              </Kebab>
            )}
          </>
        ) : null}
      </td>
    </tr>
  );
};
