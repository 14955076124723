import React, { useState } from 'react';
import { Formik } from 'formik';

import { amenityDetailsValidationSchema } from '../validationSchema';
import { ErrorBanner } from '../../../../../../../common/ErrorBanner';
import AlertMessage from '../../../../../../../common/AlertMessage';
import NotificationStatusIndicator from '../../../../../../../common/forms/NotificationStatusIndicator';
import { AmenityDetails } from '../tabs/AmenityDetails';
import { useRefetchAmenityById, useUpdateAmenity } from '../hooks';
import EditAmenityRouteLeavingGuard from '../tabs/EditAmenityRouteLeavingGuard';
import { getUserPermission } from '../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import Icon from '../../../../../../../common/icons/icon';
import { useTranslation } from 'react-i18next';

export const EditAmenityDetailsForm = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ amenity, history, match, userPermissionList }) => {
    const userPermission = {
      'Edit/Delete Amenity': userPermissionList.includes('EDIT/DELETE_AMENITY'),
    };
    const [submitMessage, setSubmitMessage] = useState('');
    const [submitStatus, setSubmitStatus] = useState(null);

    const updateAmenity = useUpdateAmenity(amenity?.id);
    const refetchAmenity = useRefetchAmenityById(amenity?.id);
    const { t } = useTranslation('amenities', {
      keyPrefix: 'amenityPage',
    });
    const { t: tCommon } = useTranslation('buttons');

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: amenity.name,
          description: amenity.description,
          max_occupancy: amenity.max_occupancy || amenity.max_occupancy === 0 ? amenity.max_occupancy : '',
          terms_of_use: amenity.terms_of_use ? amenity.terms_of_use : '',
        }}
        onSubmit={(formValues, formikActions) => {
          updateAmenity.mutate(
            {
              ...formValues,
              max_occupancy: formValues.max_occupancy || null,
              terms_of_use: formValues.terms_of_use || null,
            },
            {
              onSuccess: () => {
                refetchAmenity().then(() => {
                  formikActions.resetForm();
                  setSubmitMessage(t('messages.edited'));
                  setSubmitStatus('Success');
                });
              },
              onError: e => {
                formikActions.setSubmitting(false);
                setSubmitMessage(e.message);
                setSubmitStatus('Failure');
              },
            }
          );
        }}
        validationSchema={amenityDetailsValidationSchema(t)}
        render={({ values, dirty, errors, handleChange, setFieldValue, touched, handleSubmit, isSubmitting }) => (
          <>
            <EditAmenityRouteLeavingGuard when={dirty} navigate={history.push} />

            <div className="container">
              <ErrorBanner hasError={Object.keys(errors).some(fieldName => touched[fieldName])} />
              {amenity.isError ? (
                <AlertMessage
                  type="error"
                  msg="There was an error when attempting to edit the amenity. Please contact SmartExperience support."
                />
              ) : null}
              <form onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius top-right">
                  <AmenityDetails
                    {...{
                      errors,
                      handleChange,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values,
                    }}
                  />
                </div>

                <div className="paper__footer wizard radius-bottom-left radius-bottom-right">
                  <div className="button--wrapper__grouping edit-button-margin">
                    {userPermission['Edit/Delete Amenity'] && (
                      <button
                        type="button"
                        className="button"
                        disabled={isSubmitting}
                        onClick={() => {
                          setSubmitMessage();
                          handleSubmit();
                        }}
                      >
                        {isSubmitting ? tCommon('saving') : t('saveButton')}
                      </button>
                    )}
                    <button
                      type="button"
                      className="button button--secondary"
                      onClick={() => history.push(`/properties/${match.params.propertyId}/community/amenities`)}
                      disabled={isSubmitting}
                    >
                      {tCommon('cancel')}
                    </button>
                    <NotificationStatusIndicator
                      hideNotification={!submitMessage}
                      message={submitMessage}
                      type={submitStatus}
                    />
                  </div>
                  <div className="button--wrapper__grouping edit-button-margin">
                    {submitMessage && (
                      <button
                        type="button"
                        className="button button--icon"
                        onClick={e => {
                          history.push(
                            `/properties/${match.params.propertyId}/community/amenities/${match.params.amenityId}/images`
                          );
                          e.preventDefault();
                        }}
                      >
                        <div className="button--children">
                          <span>{tCommon('next')}</span>
                          <Icon icon="ArrowLongRight" />
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      />
    );
  }
);
