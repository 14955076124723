import { connect } from 'react-redux';
import { createGroups, getGroups, removeGroups, editGroups } from '../../../../redux/actions';
import Groups from './Groups';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    groupsList: state.property.groupList,
    groupListLoaded: state.property.groupListLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGroups: propertyId => dispatch(getGroups(propertyId)),
    createGroups: (propertyId, payload) => dispatch(createGroups(propertyId, payload)),
    removeGroups: (propertyId, groupId) => dispatch(removeGroups(propertyId, groupId)),
    editGroups: (propertyId, groupId, payload) => dispatch(editGroups(propertyId, groupId, payload)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getGroups: () => dispatchProps.getGroups(ownProps.match.params.propertyId),
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withTranslation(['unitGroups', 'buttons'])(Groups));
