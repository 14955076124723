import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import UploadIcon from '../../../../../../../../common/icons/Upload';
import { AmenityImageCropperModal } from './AmenityImageCropperModal';
import AlertMessage from '../../../../../../../../common/AlertMessage';

const MAX_FILES = 5;

const ONE_MEGA_BYTE = 1024 * 1024;

const formattedErrorMessages = {
  'file-too-large': 'File is larger than 1 MB',
};

export const AmenityImageInput = ({ images, onChangeImages, maxImage, error }) => {
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.images' });
  const [readyToCropFiles, setReadyToCropFiles] = useState([]);
  const [errors, setErrors] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setReadyToCropFiles(acceptedFiles.slice(0, maxImage || MAX_FILES - images.length));

      setErrors([
        ...rejectedFiles.map(rejectedFile =>
          t('errors.custom', {
            fileName: rejectedFile.file.name,
            message: formattedErrorMessages[rejectedFile.errors[0].code] ?? rejectedFile.errors[0].message,
          })
        ),
        ...acceptedFiles
          .slice(maxImage || MAX_FILES - images.length)
          .map(acceptedFile => t('errors.tooManyItems', { fileName: acceptedFile.name })),
      ]);
    },
    [images, maxImage, t]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxSize: ONE_MEGA_BYTE,
  });

  return (
    <>
      {readyToCropFiles.length > 0 && (
        <AmenityImageCropperModal
          imagesToCrop={readyToCropFiles}
          onConfirm={dataUrl => onChangeImages([...images, { tempId: Date.now().toString(), url: dataUrl }])}
          onClose={() => setReadyToCropFiles([])}
        />
      )}

      <div
        {...getRootProps({
          className: `${error ? 'error-images' : 'dropzone'}`,
          style: { width: '100%' },
        })}
      >
        <input {...getInputProps()} />
        <div>
          <UploadIcon />
          <p>{t('sections.upload.dragAndDrop.title')}</p>
          <p>{t('sections.upload.dragAndDrop.subtitle')}</p>
          <p>{t('sections.upload.dragAndDrop.condition')}</p>
        </div>
      </div>
      {errors.map((error, i) => (
        <AlertMessage type="error" msg={error} icon key={i} />
      ))}
    </>
  );
};
