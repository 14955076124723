import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import config from '../config';
import Avatar from '../Avatar/index';
import { withTranslation } from 'react-i18next';
import { LanguageSelector } from './LanguageSelector';

class UserMenu extends Component {
  render() {
    const { title, isAdmin, user, t } = this.props;

    const resetEmailUrl = `${config.auth.domain}/user/email-settings?client_id=${config.auth.clientId}&redirect_uri=${config.auth.redirectUri}`;
    const resetPasswordUrl = `${config.auth.domain}/user/password?client_id=${config.auth.clientId}&redirect_uri=${config.auth.redirectUri}`;

    return (
      <div className="navbar-pm">
        <h3 className="h3">{title}</h3>
        <div className="top-menu-wrapper">
          <LanguageSelector />
          <div className="user-actions">
            <span className="user h4">
              <Avatar
                name={user.get('first_name') + ' ' + user.get('last_name')}
                email={user.getIn(['oidcUser', 'email'])}
                imageSource={user.getIn(['oidcUser', 'profile', 'picture'])}
              />
              <div className="user-menu">
                {!isAdmin ? (
                  <a target="_blank" rel="noopener noreferrer" href="http://manager-support.golittlebird.com">
                    Support &amp; FAQ
                  </a>
                ) : null}
                <b className="user-menu-roles" style={{ fontSize: '12px' }}>
                  {user.get('roles').size > 0 &&
                    user
                      .get('roles')
                      .get(0)
                      .get('role')}
                </b>
                <a href={resetEmailUrl}>{t('changeEmail')}</a>
                <a href={resetPasswordUrl}>{t('changePassword')}</a>
                <Link to={`/logout`}>{t('logout')}</Link>
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

UserMenu.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(withTranslation('userMenu')(UserMenu));
