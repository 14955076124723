import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { maintenanceRequestsMocksEng, maintenanceRequestsMocksRu } from '../../../mock';
import ResidentMessagesDetails from '../../../components/ResidentMessagesDetails';
import { useTranslation } from 'react-i18next';

const MaintenanceRequest = () => {
  const { t } = useTranslation('residentMessages');
  const { params } = useRouteMatch();

  const { maintenanceRequestId } = params;

  const requests = t('typeOfMockData') === 'EN' ? maintenanceRequestsMocksEng : maintenanceRequestsMocksRu;
  const request = requests.find(({ id }) => id === +maintenanceRequestId);

  return <ResidentMessagesDetails request={request} />;
};
export default MaintenanceRequest;
