import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { helpFromManagementMocksEng, helpFromManagementMocksRu } from '../../../mock';
import ResidentMessagesDetails from '../../../components/ResidentMessagesDetails';
import { useTranslation } from 'react-i18next';

const HelpFromManagementItem = () => {
  const { params } = useRouteMatch();
  const { t } = useTranslation('residentMessages');

  const { helpFromManagementId } = params;

  const requests = t('typeOfMockData') === 'EN' ? helpFromManagementMocksEng : helpFromManagementMocksRu;
  const request = requests.find(({ id }) => id === +helpFromManagementId);

  return <ResidentMessagesDetails request={request} />;
};
export default HelpFromManagementItem;
