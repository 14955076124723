import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import querystring from 'query-string';
import Loading from '../../../../../../common/Loading';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import SubHeader from '../../../../../../common/SubHeader/';
import Pager from '../../../../../../common/Pager';
import PendingPostRow from './components/PendingPostRow';
import ModalAlert from '../../../../../../common/ModalAlert';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const DEFAULT_PAGE_SIZE = 30;

class PendingPostList extends Component {
  static propTypes = {
    getPendingPosts: PropTypes.func.isRequired,
    pendingPostList: PropTypes.instanceOf(List).isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map).isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    showRejectModal: false,
    rejectPost: null,
  };

  constructor(props) {
    super(props);
    this.t = props.t;
  }

  componentDidMount() {
    this.loadPosts();
  }

  loadPosts() {
    if (this.props.location.search) {
      const locationObj = querystring.parse(this.props.location.search);
      return this.props.getPendingPosts(
        parseInt(locationObj.page, 10) + 1,
        parseInt(locationObj.page_size, 10),
        locationObj.type,
        true
      );
    }
    this.props.getPendingPosts();
  }

  componentDidUpdate(prevProps) {
    const { location, history, pendingPostTotal } = this.props;
    const searchParams = querystring.parse(location.search);
    const page = parseInt(searchParams.page, 10);
    const pageSize = parseInt(searchParams.page_size, 10);
    const finalPage = pendingPostTotal === 0 ? 0 : Math.ceil(pendingPostTotal / pageSize) - 1; // -1 because pages are zero indexed

    if (page > finalPage) {
      history.replace({ search: querystring.stringify({ ...searchParams, page: finalPage }) });
    } else if (prevProps.location.search !== location.search) {
      this.loadPosts();
    }
  }

  renderPostTable = () => {
    const { location, pendingPostList, pendingPostTotal, userPermissionList } = this.props;
    const locationObj = querystring.parse(location.search);
    const userPermission = {
      'Approve/Deny Pending Discussion': userPermissionList.includes('APPROVE/DENY_PENDING_DISCUSSION'),
    };

    if (!pendingPostTotal || pendingPostTotal === 0) {
      return <EmptyPlaceholder>{this.t('No Pending Discussions right now.')}</EmptyPlaceholder>;
    } else {
      return (
        <div className="pm-pending-posts">
          <table className="table-list">
            <thead>
              <tr>
                <th>{this.t("Author's Name")}</th>
                <th>{this.t("Author's Unit #")}</th>
                <th>{this.t('Post Type')}</th>
                <th>{this.t('Headline')}</th>
                <th>{this.t('Body')}</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {pendingPostList.map(post => {
                const id = post.get('id');
                return (
                  <PendingPostRow
                    key={id}
                    post={post}
                    onApprove={() => {
                      this.props.approvePost(id).then(() => {
                        this.loadPosts();
                      });
                    }}
                    onReject={() => {
                      this.setState({ showRejectModal: true, rejectPost: post });
                    }}
                    approveDenyPendingDiscussionPermission={userPermission['Approve/Deny Pending Discussion']}
                  />
                );
              })}
            </tbody>
          </table>
          <Pager
            history={this.props.history}
            initialPage={parseInt(locationObj.page, 10) || 0}
            location={this.props.location}
            pageSize={parseInt(locationObj.page_size, 10) || DEFAULT_PAGE_SIZE}
            totalItems={pendingPostTotal}
          />
        </div>
      );
    }
  };

  renderRejectModal = () => {
    return (
      <ModalAlert
        title={this.t('Reject Post?')}
        body={this.t('Enter a comment to your resident to let them know why their post was rejected.')}
        confirmButtonTitle={this.t('Reject')}
        cancelButtonTitle={this.t('Cancel')}
        inputForm
        destructive
        handleConfirm={comment => {
          const id = this.state.rejectPost.get('id');
          this.props.rejectPost(id, comment).then(() => {
            this.loadPosts();
          });
          this.setState({ showRejectModal: false, rejectPost: null });
        }}
        handleCancel={() => {
          this.setState({ showRejectModal: false, rejectPost: null });
        }}
        visible={this.state.showRejectModal}
      />
    );
  };

  render() {
    const { loading } = this.props;

    return (
      <div className="pending-post-list__wrapper">
        <div className="container">
          <SubHeader title={this.t('Pending Discussions')} />
          {loading ? <Loading /> : this.renderPostTable()}
          {this.state.showRejectModal && this.renderRejectModal()}
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation('pendingDiscussions')(PendingPostList));
