import React, { useState, useEffect } from 'react';
import SubHeader from '../../../../../../../common/SubHeader';
import EmptyPlaceholder from '../../../../../../../common/EmptyPlaceholder';
import { useAmenitiesList, useRefetchAmenitiesList } from '../hooks';
import Loading from '../../../../../../../common/Loading';
import AmenityListItem from './AmenityListItem';
import { GenerateCSVBtn } from '../../Events/EditEvent/Registrations/GenerateCSVBtn';
import Icon from '../../../../../../../common/icons/icon';
import { Link } from 'react-router-dom';
import { DeleteAmenityModal } from '../DeleteAmenityModal';
import AlertMessage from '../../../../../../../common/AlertMessage';
import { getUserPermission } from '../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const AmenitiesList = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ match, userPermissionList }) => {
    const userPermission = {
      Add_Amenity: userPermissionList.includes('ADD_AMENITY'),
      'Edit/Delete Amenity': userPermissionList.includes('EDIT/DELETE_AMENITY'),
    };
    const communityId = match.params.propertyId;
    const amenitiesList = useAmenitiesList(communityId);
    const refetchAmenitiesList = useRefetchAmenitiesList(communityId);
    const [deleteModalAmenity, setDeleteModalAmenity] = useState(null);
    const [openKebabAmenityId, setOpenKebabAmenityId] = useState(null);
    const [deletedAmenityName, setDeletedAmenityName] = useState(null);
    const { t } = useTranslation('amenities', { keyPrefix: 'mainScreen' });

    useEffect(() => {
      let timeout;
      if (deletedAmenityName) {
        timeout = setTimeout(() => setDeletedAmenityName(null), 3000);
      }
      return () => !!timeout && clearTimeout(timeout);
    }, [deletedAmenityName]);

    return (
      <div className="container amenities-list__page">
        {!!deleteModalAmenity && (
          <DeleteAmenityModal
            amenityId={deleteModalAmenity.id}
            onDelete={() => {
              setDeletedAmenityName(deleteModalAmenity.name);
              refetchAmenitiesList();
              setDeleteModalAmenity(null);
            }}
            onClose={() => setDeleteModalAmenity(null)}
          />
        )}

        <SubHeader
          title={t('title')}
          actions={[
            amenitiesList.isSuccess && amenitiesList?.data.length === 0 ? null : (
              <>
                <GenerateCSVBtn
                  filePath={`amenities/amenities-csv-list.csv`}
                  queryParams={{ community_id: communityId }}
                  style={{ marginRight: '15px' }}
                />
                {userPermission.Add_Amenity && (
                  <Link className="button" to={`${match.url}/add`}>
                    {t('addAmenityButton')}
                  </Link>
                )}
              </>
            ),
          ]}
        />

        {amenitiesList.isLoading && <Loading />}

        {deletedAmenityName ? (
          <AlertMessage icon type="success" msg={`${deletedAmenityName} has been deleted`} />
        ) : null}

        {amenitiesList.isSuccess && amenitiesList.data?.length > 0 && (
          <table className="table-units-list">
            <thead>
              <tr>
                <th className="first-column">{t('amenitiesTable.amenityNameColumnTitle')}</th>
                <th>{t('amenitiesTable.availabilityColumnTitle')}</th>
                <th>{t('amenitiesTable.reservableColumnTitle')}</th>
                <th>{t('amenitiesTable.feeColumnTitle')}</th>
                <th>{t('amenitiesTable.maxOccupancyColumnTitle')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {amenitiesList.data.map(amenity => {
                return (
                  <AmenityListItem
                    key={amenity.id}
                    amenity={amenity}
                    isKebabMenuOpen={openKebabAmenityId === amenity.id}
                    openKebabMenu={() => setOpenKebabAmenityId(amenity.id)}
                    closeKebabMenu={() => setOpenKebabAmenityId(null)}
                    onClickDelete={() => setDeleteModalAmenity(amenity)}
                    editDeleteAmenityPermission={userPermission['Edit/Delete Amenity']}
                  />
                );
              })}
            </tbody>
          </table>
        )}

        {amenitiesList.isSuccess && amenitiesList?.data.length === 0 && (
          <EmptyPlaceholder
            buttonText={t('addAmenityButton')}
            linkTo={userPermission.Add_Amenity ? `${match.url}/add` : ''}
          >
            <div className="amenities-list__empty-placeholder">
              <Icon icon="Amenity" />
              <p>{t('noAmenitiesText')}</p>
            </div>
          </EmptyPlaceholder>
        )}
      </div>
    );
  }
);
