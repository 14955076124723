import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { List } from 'immutable';
import { formatPhoneNumber } from '../../../../../../../../../../common/utils/helpers';
import { withRouter } from 'react-router-dom';
import Avatar from '../../../../../../../../../../common/Avatar/index';
import Icon from '../../../../../../../../../../common/icons/icon';
import Kebab from '../../../../../../../../../../common/Kebab/index';
import SubHeader from '../../../../../../../../../../common/SubHeader/index';
import Delete from '../../../../../../../../../../common/Delete/index';
import AlertMessage from '../../../../../../../../../../common/AlertMessage';

const EmptyMasterResidentList = ({ onAddResident, userPermission, isUnitModel, t }) => (
  <div className="zero-content">
    {userPermission.addResidentToUnit && !isUnitModel && (
      <button onClick={onAddResident} className="button">
        {t('unitAddResidentBtn')}
      </button>
    )}
    {isUnitModel && <p>The resident can not be added to model units</p>}
  </div>
);

class ResidentList extends Component {
  state = {
    openKebabUserId: null,
    deleteConfirmationResidentId: null,
    successMessage: false,
    failMessage: false,
  };

  onAddClick = () => {
    const { match, history } = this.props;
    history.push(`${match.url}/add`);
  };

  onResendInviteClick = user_id => {
    const { onResendInvite } = this.props;
    onResendInvite(user_id);
    this.closeKebab();
  };

  renderDeleteConfirmation = residentId => {
    const { removeResident } = this.props;
    return (
      <div className="paper__footer radius-bottom-left radius-bottom-right t-removef-btn" style={{ display: 'block' }}>
        <Delete
          onCancel={() => this.setState({ deleteConfirmationResidentId: null })}
          deleteFunc={() => removeResident(residentId)}
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this Resident</strong>?
            </span>
          }
        />
      </div>
    );
  };

  onKebabClick = userId => this.setState({ openKebabUserId: userId });

  closeKebab = () => this.setState({ openKebabUserId: null });

  renderKebab = resident => {
    const { match, history, userPermission } = this.props;
    const { openKebabUserId } = this.state;

    let editUrl = `${match.url}/residents/${resident.get('id')}/edit`;

    if (!userPermission.viewResidentContactInformation) {
      editUrl = `${match.url}/residents/${resident.get('id')}/edit/access-codes`;
    }

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(resident.get('id'))}
          onDisengage={this.closeKebab}
          menuOpened={resident.get('id') === openKebabUserId}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={() => {
                history.push(editUrl);
                this.closeKebab();
              }}
            >
              <Icon icon="Edit" />
              Edit
            </div>

            {!resident.getIn(['user', 'email_verified']) && (
              <div className="kebab__menu--item" onClick={() => this.onResendInviteClick(resident.get('user_id'))}>
                <Icon icon="Envelope" />
                Resend Invite
              </div>
            )}
            {userPermission.removeResident && (
              <div
                className="kebab__menu--item"
                onClick={() => {
                  this.setState({ deleteConfirmationResidentId: resident.get('id') });
                  this.closeKebab();
                }}
              >
                <Icon icon="Remove" />
                Remove
              </div>
            )}
          </div>
        </Kebab>
      </div>
    );
  };

  renderAddMasterResidentButton = isUnitModel => {
    const { match, history, userPermission, t } = this.props;
    const addUrl = `${match.url}/residents/add`;

    return (
      <div className="page-header__actions">
        {isUnitModel && <p className="font-small text-error">The resident can not be added to model units</p>}
        {userPermission.addResidentToUnit && (
          <button onClick={() => history.push(addUrl)} className="button" disabled={isUnitModel}>
            {t('unitAddResidentBtn')}
          </button>
        )}
      </div>
    );
  };

  renderIdentificationColumn = resident => {
    const fullName = `${resident.getIn(['user', 'first_name'])} ${resident.getIn(['user', 'last_name'])}`;

    return (
      <div className="pm-list__name-column">
        <Avatar name={fullName} imageSource={resident.getIn(['user', 'profile', 'picture'])} />
        <div>
          <div>
            {fullName} {resident.get('approved_purchaser') && <Icon icon="CheckboxChecked" />}
          </div>
          <div className="pm-list__name-column__email">{resident.getIn(['user', 'email'])}</div>
        </div>
      </div>
    );
  };

  render() {
    const { residents, history, match, userPermission, unit, t } = this.props;
    const { deleteConfirmationResidentId, successMessage, failMessage } = this.state;
    const isUnitModel = unit.get('unit_type') === 'model';

    return (
      <div>
        {successMessage ? <AlertMessage type="success" msg={successMessage} /> : null}
        {failMessage ? <AlertMessage type="error" msg={failMessage} /> : null}
        <SubHeader
          title={t('unitResidentsTitle')}
          actions={residents.size ? [this.renderAddMasterResidentButton(isUnitModel)] : null}
        />
        {residents.size ? (
          <div className="table__wrapper">
            <table className="table-list">
              <thead>
                <tr>
                  <th>{t('unitResidentColumnNumber')}</th>
                  <th>{t('unitResidentColumnPhone')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {residents.map(resident => {
                  return (
                    <tr key={resident.get('id')}>
                      <td>{this.renderIdentificationColumn(resident)}</td>
                      <td>
                        <div className="resident--phone-number">
                          {formatPhoneNumber(resident.getIn(['user', 'phone']))}
                        </div>
                      </td>

                      <td className="table__align-right">
                        {deleteConfirmationResidentId === resident.get('id')
                          ? this.renderDeleteConfirmation(resident.get('id'))
                          : this.renderKebab(resident)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <EmptyMasterResidentList
            t={t}
            onAddResident={() => history.push(`${match.url}/residents/add`)}
            userPermission={userPermission}
            isUnitModel={isUnitModel}
          />
        )}
      </div>
    );
  }
}

ResidentList.propTypes = {
  removeResident: PropTypes.func.isRequired,
  onResendInvite: PropTypes.func.isRequired,
  residents: PropTypes.instanceOf(List).isRequired,
  property: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  userPermission: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('units')(withRouter(ResidentList));
