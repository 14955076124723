import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import querystring from 'query-string';
import { withTranslation } from 'react-i18next';
import Loading from '../../../../../../../../common/Loading';
import EmptyPlaceholder from '../../../../../../../../common/EmptyPlaceholder';
import PropertyPageHeader from '../../../../../../../../common/PropertyPageHeader';
import SubHeader from '../../../../../../../../common/SubHeader';
import Icon from '../../../../../../../../common/icons/icon';
import Pager from '../../../../../../../../common/Pager';
import { SearchList } from '../../../../../../../../common/Search';
import { GenerateCSVBtn } from '../../../Events/EditEvent/Registrations/GenerateCSVBtn';
import { postTypeOptions } from '../../postTypeOptions';

const DEFAULT_PAGE_SIZE = 30;

class PostList extends Component {
  static propTypes = {
    getPosts: PropTypes.func.isRequired,
    postList: PropTypes.instanceOf(List).isRequired,
    postListTotal: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map).isRequired,
  };

  constructor(props) {
    super(props);
    this.t = props.t;
  }

  componentDidMount() {
    this.loadPosts();
  }

  loadPosts() {
    if (this.props.location.search) {
      const locationObj = querystring.parse(this.props.location.search);
      return this.props.getPosts(parseInt(locationObj.page, 10) + 1, locationObj.pageSize, locationObj.type);
    }
    this.props.getPosts();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.loadPosts();
    }
  }

  renderPageHeaderActions() {
    const { location, postListTotal, match, userPermissionList } = this.props;
    const locationObj = querystring.parse(location.search);
    const queryCSVtype = locationObj.type !== 'all' ? { type: locationObj.type } : null;
    const userPermission = {
      add_Post: userPermissionList.includes('ADD_POST'),
    };
    const translatedPostTypeOptions = [{ value: 'all', label: 'All' }, ...postTypeOptions].map(({ value, label }) => ({
      value,
      label: this.t(label),
    }));

    if (postListTotal > 0 || locationObj.type) {
      return (
        <div className="discussion-post-filter__container">
          <div className="status-filter__container">
            <span className="status-filter--label">{this.t('Filter by type:')}</span>
            <SearchList name="type" placeHolder="some" options={translatedPostTypeOptions} />
          </div>
          <GenerateCSVBtn
            filePath={`properties/${match.params.propertyId}/discussion-posts-csv-list.csv`}
            queryParams={queryCSVtype}
            style={{ paddingBlock: '16px', marginLeft: '10px' }}
            title={this.t('Download CSV')}
          />
          <div>
            {userPermission.add_Post && (
              <Link className="button button--size--small unit-button" to={`${match.url}/add`}>
                {this.t('Add Post')}
              </Link>
            )}
          </div>
        </div>
      );
    }
  }

  renderPostTable = () => {
    const { location, property, postList, postListTotal, match, userPermissionList } = this.props;
    const locationObj = querystring.parse(location.search);
    const userPermission = {
      add_Post: userPermissionList.includes('ADD_POST'),
    };

    if (!postList.size) {
      return (
        <EmptyPlaceholder buttonText={this.t('Add Post')} linkTo={userPermission.add_Post ? `${match.url}/add` : ''} />
      );
    }
    return (
      <div>
        <div className="container pm-posts__content">
          <table className="table-list">
            <thead>
              <tr>
                <th>{this.t('HEADLINE')}</th>
                <th>{this.t('TYPE')}</th>
                <th>{this.t('AUTHOR')}</th>
                <th>{this.t('POSTED DATE')}</th>
              </tr>
            </thead>

            <tbody>
              {postList.map(post => {
                return (
                  <tr key={post.get('id')} className={post.get('deleted_at') ? 'deleted' : ''}>
                    <td className="post-table-title">
                      <Link to={`/properties/${property.get('id')}/community/discussion/${post.get('id')}`}>
                        {post.get('headline')}
                      </Link>
                    </td>
                    <td>{this.t(post.get('post_type'))}</td>
                    <td>
                      <div className="discussion-author">
                        {post.getIn(['author', 'isAdminOrPM']) ? <Icon icon="Person" /> : null}
                        <div>
                          {post.getIn(['author', 'first_name'])} {post.getIn(['author', 'last_name'])}
                        </div>
                      </div>
                    </td>
                    <td>
                      {moment
                        .tz(post.get('created_at'), property.get('timezone_id'))
                        .format(this.t('MM/DD/YYYY, hh:mm:ss A'))}
                      <div>{post.get('deleted_at') ? 'DELETED' : null}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pager
            history={this.props.history}
            initialPage={parseInt(locationObj.page, 10) || 0}
            location={this.props.location}
            pageSize={locationObj.pageSize || DEFAULT_PAGE_SIZE}
            totalItems={postListTotal}
          />
        </div>
      </div>
    );
  };

  render() {
    const { loading } = this.props;
    const postCountTitle = `${this.props.postListTotal || 0} ${this.t(
      this.props.postListTotal !== 1 ? 'Posts' : 'Post'
    )}`;

    return (
      <div className="messages-list__wrapper">
        <PropertyPageHeader title={this.t('Discussion Board')} />
        {loading ? (
          <Loading />
        ) : (
          <div className="container">
            <div className="discussion-subheader__wrapper">
              <SubHeader title={postCountTitle} />
              {this.renderPageHeaderActions()}
            </div>
            {this.renderPostTable()}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('discussions')(PostList);
