import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import querystring from 'query-string';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Loading from '../../../../../../../../common/Loading';
import PropertyPageHeader from '../../../../../../../../common/PropertyPageHeader';
import Icon from '../../../../../../../../common/icons/icon';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import ConfirmButton from '../../../../../../../../common/ConfirmButton';
import ModalAlert from '../../../../../../../../common/ModalAlert';
import AlertMessage from '../../../../../../../../common/AlertMessage';
import Close from '../../../../../../../../common/icons/Close';

// const DATE_FORMAT = 'MMM D, YYYY [at] hh:mm A';

class ViewPost extends Component {
  state = {
    comment: '',
    banPostModal: false,
    banCommentModal: false,
    unbanModal: false,
    selectedCommentOrPost: null,
    clickedImage: null,
    currentIndex: null,
  };

  constructor(props) {
    super(props);
    this.t = props.t;
    this.commentRefs = {};
  }

  componentDidMount() {
    this.props.getPost();
  }

  unbanUser(authorId) {
    this.props.unbanUser(authorId);
  }

  banUserForPost(postId) {
    this.props.banUserForPost(postId);
  }

  banUserForComment(commentId) {
    this.props.banUserForComment(commentId);
  }

  componentDidUpdate(prevProps) {
    if (!(prevProps.post && prevProps.post.get('id') === prevProps.match.params.postId) && this.getPost()) {
      if (this.isCommentHighlighted()) {
        const locationObj = querystring.parse(this.props.location.search);
        const node = ReactDOM.findDOMNode(this.commentRefs[locationObj.comment]);
        if (node) {
          window.scrollTo(0, node.offsetTop);
        }
      }
    }
  }

  handleDismiss = () => {
    this.setState({ clickedImage: null });
  };

  renderClickedModal = clickedImg => {
    return (
      <div className="image-overlay" onClick={() => this.handleDismiss()}>
        <img src={clickedImg} alt="Larger size" />
        <button className="overlay-close" onClick={() => this.handleDismiss()}>
          <Close />
        </button>
      </div>
    );
  };

  handleClick = (item, index) => {
    this.setState({ currentIndex: index });
    this.setState({ clickedImage: item.get('image_url') });
  };

  renderImages(post) {
    return (
      <div className="discussion-gallery">
        {post.get('images').map((image, index) => (
          <div key={index}>
            <img
              src={image.get('image_url')}
              onClick={() => this.handleClick(image, index)}
              className="gallery-image"
              alt="Discussion Post list"
            ></img>
          </div>
        ))}
      </div>
    );
  }

  getPost() {
    if (this.props.post && this.props.post.get('id') === this.props.match.params.postId) {
      return this.props.post;
    }

    return null;
  }

  submitComment = event => {
    event.preventDefault();
    this.props.createComment({ body: this.state.comment }).then(action => {
      if (action.response.ok) {
        this.setState({ comment: '' });
        this.props.getPost();
      }
    });
  };

  isCommentHighlighted = (commentId = null) => {
    const locationObj = querystring.parse(this.props.location.search);
    if (commentId) {
      // is this particular comment highlighted
      return locationObj.comment === commentId;
    } else {
      // is any comment highlighted
      return !!locationObj.comment;
    }
  };

  renderCommentButtons() {
    return (
      <div className="comment-buttons">
        <button className="button" type="submit" disabled={!this.state.comment}>
          {this.t('Comment')}
        </button>
        <button
          className="button button--secondary"
          style={{ display: !this.state.comment ? 'none' : 'inline-block' }}
          type="button"
          onClick={() => this.setState({ comment: '' })}
        >
          {this.t('Cancel')}
        </button>
      </div>
    );
  }

  deleteComment(commentId) {
    this.props.deleteComment(commentId).then(action => {
      if (action.response.ok) {
        this.props.getPost();
      }
    });
  }

  deletePost = () => {
    this.props.deletePost().then(action => {
      if (action.response.ok) {
        this.props.history.goBack();
      }
    });
  };

  renderComment = (comment, editDeleteBanPermission) => {
    const commentClasses = {
      paper: true,
      comment: true,
      'comment-highlighted': this.isCommentHighlighted(comment.get('id')),
      deleted: comment.get('deleted_at'),
    };

    return (
      <div
        className={classNames(commentClasses)}
        key={comment.get('id')}
        ref={ref => (this.commentRefs[comment.get('id')] = ref)}
      >
        <div className="single-post--top-info">
          <div className="single-post__author">
            <div className="discussion-author">
              {comment.getIn(['author', 'isAdminOrPM']) ? <Icon icon="Person" /> : null}
              <span className="comment__author">
                {comment.getIn(['author', 'first_name'])} {comment.getIn(['author', 'last_name'])} &bull;
              </span>
            </div>
            <div className="single-post__date-posted">{moment(comment.get('created_at')).fromNow()}</div>
          </div>
          <div className="discussion--delete-button__wrapper">
            <div className="delete-ban__wrapper">
              {editDeleteBanPermission && (
                <>
                  {comment.get('deleted_at') ? null : (
                    <ConfirmButton
                      iconButton
                      onConfirm={this.deleteComment.bind(this, comment.get('id'))}
                      confirmText="Are you sure you want to delete this comment?"
                    />
                  )}
                  {this.renderBanForCommentButton(comment)}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="comment-body">{comment.get('body')}</div>
        {!!comment.get('flags').size && (
          <div className="comment-flag">
            <Icon icon="Flag" />
            Flagged as inappropriate
          </div>
        )}
        {comment.getIn(['author', 'ban']) ? (
          <AlertMessage
            icon
            msg="Residents are unable to view posts or comments made by this user because this user has been banned."
          />
        ) : null}
      </div>
    );
  };

  renderComments(editDeleteBanPermission) {
    const post = this.getPost();
    return (
      <div className="comments__section">
        {editDeleteBanPermission && (
          <div className="comment-box">
            <h2>{this.t('Comments')}</h2>
            <form onSubmit={this.submitComment}>
              <InputScaffold label={this.t('Add a comment')}>
                <textarea
                  style={{ resize: 'none' }}
                  name="description"
                  rows="6"
                  maxLength="400"
                  placeholder={`${this.t('Comment text')}...`}
                  value={this.state.comment}
                  onChange={event => this.setState({ comment: event.currentTarget.value })}
                />
              </InputScaffold>
              {this.renderCommentButtons()}
            </form>
          </div>
        )}
        <div className="single-comments--container">
          {post.get('comments').map(item => this.renderComment(item, editDeleteBanPermission))}
        </div>
      </div>
    );
  }

  renderTypeSpecificFields() {
    const post = this.getPost();

    switch (post.get('post_type')) {
      case 'For Sale':
        return (
          <div className="single-post__flex-label">
            <div className="inline-label">{this.t('Price')}:</div>{' '}
            {post.get('price') === 0 ? 'Free' : `$${post.get('price')}.00`}
          </div>
        );
      case 'Event':
        return (
          <div className="single-post-event-details">
            {post.get('location') ? (
              <div className="single-post__flex-label">
                <div className="inline-label">{this.t('Location')}:</div> {post.get('location')}
              </div>
            ) : null}
            <div className="single-post__flex-label">
              <div className="inline-label">{this.t('Start')}:</div>{' '}
              {moment(post.get('start_time')).format(this.t('MMM D, YYYY [at] hh:mm A'))}
            </div>
            <div className="single-post__flex-label">
              <div className="inline-label">{this.t('End')}:</div>{' '}
              {moment(post.get('end_time')).format(this.t('MMM D, YYYY [at] hh:mm A'))}
            </div>
          </div>
        );
      case 'Recommendation':
        return (
          <div className="single-post__flex-label">
            <div className="inline-label">{this.t('Recommendation Type')}:</div>
            {this.t(post.get('recommendation_type')[0].toUpperCase() + post.get('recommendation_type').substr(1))}
          </div>
        );
      default:
        return null;
    }
  }

  _renderBanButton(isComment, commentOrPost) {
    if (
      commentOrPost
        .getIn(['author', 'roles'])
        .find(role => role.get('role') === 'ADMIN' || role.get('role') === 'PROPERTY_MANAGER')
    ) {
      return null;
    }

    if (commentOrPost.getIn(['author', 'ban'])) {
      return (
        <button className="button button--success ban-button" onClick={() => this.toggleUnbanModal(commentOrPost)}>
          Unban
        </button>
      );
    }

    return (
      <button
        className="button button--destructive ban-button"
        onClick={() => (isComment ? this.toggleBanCommentModal(commentOrPost) : this.toggleBanPostModal(commentOrPost))}
      >
        Ban
      </button>
    );
  }

  renderBanForCommentButton(comment) {
    return this._renderBanButton(true, comment);
  }

  renderBanForPostButton(post) {
    return this._renderBanButton(false, post);
  }

  toggleBanPostModal = commentOrPost => {
    this.setState({ banPostModal: !this.state.banPostModal, selectedCommentOrPost: commentOrPost });
  };

  toggleBanCommentModal = commentOrPost => {
    this.setState({ banCommentModal: !this.state.banCommentModal, selectedCommentOrPost: commentOrPost });
  };

  toggleUnbanModal = commentOrPost => {
    this.setState({ unbanModal: !this.state.unbanModal, selectedCommentOrPost: commentOrPost });
  };

  render() {
    const { userPermissionList } = this.props;
    const userPermission = {
      'EDIT/DELETE/BAN_POST': userPermissionList.includes('EDIT/DELETE/BAN_POST'),
    };

    const post = this.getPost();

    if (!post) {
      return <Loading />;
    }

    return (
      <div>
        <ModalAlert
          title="Ban User From Discussion Board?"
          body="Are you sure you want to ban this user from the discussion board? All posts and comments made by this user will no longer be available for residents to view."
          confirmButtonTitle="Ban User"
          cancelButtonTitle="Cancel"
          handleConfirm={() => {
            this.banUserForPost(this.state.selectedCommentOrPost.get('id'));
            this.toggleBanPostModal();
          }}
          handleCancel={() => {
            this.toggleBanPostModal();
          }}
          visible={this.state.banPostModal}
        />
        <ModalAlert
          title="Ban User From Discussion Board?"
          body="Are you sure you want to ban this user from the discussion board? All posts and comments made by this user will no longer be available for residents to view."
          confirmButtonTitle="Ban User"
          cancelButtonTitle="Cancel"
          handleConfirm={() => {
            this.banUserForComment(this.state.selectedCommentOrPost.get('id'));
            this.toggleBanCommentModal();
          }}
          handleCancel={() => {
            this.toggleBanCommentModal();
          }}
          visible={this.state.banCommentModal}
        />
        <ModalAlert
          title="Unban User From Discussion Board?"
          body="Are you sure you want to unban this user from the discussion board?"
          confirmButtonTitle="Unban User"
          cancelButtonTitle="Cancel"
          handleConfirm={() => {
            this.unbanUser(this.state.selectedCommentOrPost.get('author_id'));
            this.toggleUnbanModal();
          }}
          handleCancel={() => {
            this.toggleUnbanModal();
          }}
          visible={this.state.unbanModal}
        />
        <PropertyPageHeader title={this.t('Discussion Board')} backLink="./" />
        {post.getIn(['author', 'ban']) ? (
          <AlertMessage
            icon
            msg="Residents are unable to view posts or comments made by this user because this user has been banned."
          />
        ) : null}
        <div className={`container paper radius-top-left radius-top-right${post.get('deleted_at') ? ' deleted' : ''}`}>
          <div className="single-post__wrapper">
            <div className="single-post__title">
              <div className="single-post__title--left">
                <h1>
                  {post.get('headline')}
                  {post.get('post_type') === 'For Sale' ? (
                    <span className="for-sale--price">
                      {post.get('price') === 0 ? 'Free' : `$${post.get('price')}.00`}
                    </span>
                  ) : null}
                </h1>
                <div className="single-post--top-info">
                  <div className="single-post__author">
                    <div className="discussion-author__wrapper">
                      <span>{this.t('Posted by')}</span>
                      <div className="discussion-author">
                        {post.getIn(['author', 'isAdminOrPM']) ? <Icon icon="Person" /> : null}
                        <span>
                          {post.getIn(['author', 'first_name'])} {post.getIn(['author', 'last_name'])}
                        </span>
                      </div>
                    </div>
                    <div className="single-post__date-posted">{moment(post.get('created_at')).fromNow()}</div>
                  </div>
                </div>
              </div>
              <div className="delete-ban__wrapper">
                {userPermission['EDIT/DELETE/BAN_POST'] && (
                  <>
                    {post.get('deleted_at') ? null : (
                      <ConfirmButton
                        iconButton
                        onConfirm={this.deletePost}
                        confirmText="Are you sure you want to delete this post?"
                      />
                    )}
                    {this.renderBanForPostButton(post)}
                  </>
                )}
              </div>
            </div>
            <div className="single-post-details">
              <div className="single-post__flex-label">
                <div className="inline-label">{this.t('Type')}:</div> {this.t(post.get('post_type'))}
              </div>
              {this.renderTypeSpecificFields()}
              {post.get('link') ? (
                <div className="single-post__flex-label">
                  <div className="inline-label">{this.t('Link')}:</div>
                  <a href={post.get('link')}>{post.get('link')}</a>
                </div>
              ) : null}
              <div className="single-post-body">
                <p>{post.get('body')}</p>
              </div>
              <div className="inline-label"></div>
              {this.renderImages(post)}
            </div>
          </div>
        </div>
        <div className="container">{this.renderComments(userPermission['EDIT/DELETE/BAN_POST'])}</div>
        {this.state.clickedImage && this.renderClickedModal(this.state.clickedImage)}
      </div>
    );
  }
}

export default withTranslation('discussions')(ViewPost);
