import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import Kebab from '../../../../../../../common/Kebab';
import { useNavigateToEditPage, useNavigateToVendorEventDetailsPage } from '../hooks';
import Icon from '../../../../../../../common/icons/icon';
import { useTranslation } from 'react-i18next';

export const EventListItem = ({
  event,
  hasKebab,
  isKebabMenuOpen,
  openKebabMenu,
  closeKebabMenu,
  onClickCancelEvent,
  pageContext,
  editCancelEventPermission,
}) => {
  const navigateToEditPage = useNavigateToEditPage(event.id);
  const navigateToVendorEventDetailsPage = useNavigateToVendorEventDetailsPage(event.id);

  const contextNavigation = {
    eventList: navigateToEditPage,
    vendorEventList: navigateToVendorEventDetailsPage,
  };
  const { t } = useTranslation('events');
  const { t: tTime } = useTranslation('time');

  const eventDate = moment(event.event_date).format('YYYY-MM-DD');
  const eventTime = moment(event.end_time, 'HH:mm').format('HH:mm');

  const isEventCanceled = !!event.canceled_at;
  const hasEventPassed = moment(moment(eventDate + ' ' + eventTime)).isBefore(moment());
  let disabledEvent = isEventCanceled || hasEventPassed;

  const activeEventKebabItems = (
    <>
      <div className="kebab__menu--item" onClick={navigateToEditPage}>
        <Icon icon="Edit" />
        {t('eventItem.editEvent')}
      </div>
      <div
        className="kebab__menu--item"
        onClick={() => {
          onClickCancelEvent(event);
        }}
      >
        <Icon icon="CircleX" /> {t('eventItem.cancelEvent')}
      </div>
    </>
  );

  const titleClasses = {
    'event-title': true,
    'faux-link': !isEventCanceled,
  };

  const eventLabel = () => {
    if (hasEventPassed) {
      return <td>{t('eventItem.endedStatus')}</td>;
    }
    return <td>{isEventCanceled ? t('eventItem.canceledStatus') : t('eventItem.activeStatus')}</td>;
  };

  const totalEventRegistrations =
    event.event_registrations?.reduce((acc, cur) => {
      if (cur.is_canceled === true || cur.status !== 'ACTIVE') {
        return acc;
      }

      return acc + cur.tickets;
    }, 0) ?? 'N/A';

  return (
    <tr>
      <td className="first-column" style={{ overflowWrap: 'break-word', maxWidth: '240px' }}>
        <div className={cn(titleClasses)} onClick={!isEventCanceled ? contextNavigation[pageContext] : null}>
          {event.title}
        </div>
      </td>
      <td>{t('eventItem.date', { date: new Date(event.event_date) })}</td>
      <td>
        {moment(event.start_time, 'HH:mm').format(tTime('timeFormat'))} -{' '}
        {moment(event.end_time, 'HH:mm').format(tTime('timeFormat'))}
      </td>
      <td>{totalEventRegistrations}</td>
      <td>{event.max_attendees}</td>
      {eventLabel()}
      <td className="table__align-right">
        {!disabledEvent && hasKebab ? (
          <>
            {editCancelEventPermission && (
              <Kebab onClick={openKebabMenu} onDisengage={closeKebabMenu} menuOpened={isKebabMenuOpen}>
                <div className="kebab__menu">{activeEventKebabItems}</div>
              </Kebab>
            )}
          </>
        ) : null}
      </td>
    </tr>
  );
};
