import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';

import moment from 'moment';
import PageHeader from '../../../../../../../common/PageHeader';
import { Tab, TabsContainer } from '../../../../../../../common/Tabs';
import { EventDetails } from './EventDetails';
import { validationSchema } from './validationSchema';
import { useCreateEvent, useGetVendors } from '../hooks';
import RouteLeavingGuard from '../../../../../../../common/RouteLeavingGuard';
import { emptyImageData } from '../../../../../common/EventImageInput';
import { Financials } from './Financials';
import { dollarsToCents } from '../util';
import { Registrations } from './Registrations';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups } from '../../../../../redux/actions';
import { ErrorBanner } from '../../../../../../../common/ErrorBanner';
import AlertMessage from '../../../../../../../common/AlertMessage';
import Icon from '../../../../../../../common/icons/icon';
import { EventSummaryModal } from './EventSummaryModal';
import { getCommunityStripeConnectAccount } from '../../../../../redux/actions/communityStripeIntegrationActions';
import { useTranslation } from 'react-i18next';

const formattedErrorMessages = {
  NoStripeConnectAccount:
    'Paid Event cannot be created because the Stripe account for your property has not ' +
    'been connected. Please connect on the Community Settings page.',
  InvalidCombinedFeePercentage: 'Fees cannot add to more than 100%. Please update before continuing.',
};

const tabs = [
  {
    label: 'Event Details',
    component: EventDetails,
    fieldsToShowTabErrorBadgeWhenInvalid: [
      'title',
      'description',
      'location',
      'event_date',
      'start_time',
      'end_time',
      'min_attendees',
      'max_attendees',
      'rsvp_cutoff_date',
      'rsvp_cutoff_time',
      'pending_duration',
    ],
  },
  {
    label: 'Registrations',
    component: Registrations,
    fieldsToShowTabErrorBadgeWhenInvalid: ['event_group_ids'],
  },
  {
    label: 'Financials',
    component: Financials,
    fieldsToShowTabErrorBadgeWhenInvalid: [
      'price_amount',
      'refund_cutoff_date',
      'refund_cutoff_time',
      'cancel_fee_percent',
      'community_fee_percent',
    ],
  },
];

export const AddEvent = ({ history, match }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const propertyId = match.params.propertyId;
  const createEvent = useCreateEvent(propertyId);
  const [eventSummaryModalVisible, setEventSummaryModalVisible] = useState(false);
  const dispatch = useDispatch();
  const unitGroupList = useSelector(state => state.property.groupList);
  const communityStripeConnectAccount = useSelector(state => state.property.communityStripeConnectAccount);
  const communityStripeConnectAccountLoaded = useSelector(state => state.property.communityStripeConnectAccountLoaded);
  const stripeConnectAccountConnected = communityStripeConnectAccount && communityStripeConnectAccount.get('connected');
  const vendorsList = useGetVendors(propertyId);
  const { t } = useTranslation('events');
  const { t: tTime } = useTranslation('time');

  let errorMessage;
  if (createEvent?.error?.message) {
    errorMessage = formattedErrorMessages[createEvent.error.message]
      ? formattedErrorMessages[createEvent.error.message]
      : createEvent.error.message;
  } else {
    errorMessage = t('addEvent.errors.errorAddingEvent');
  }

  useEffect(() => {
    if (propertyId) {
      dispatch(getGroups(propertyId));
    }
  }, [dispatch, propertyId]);

  useEffect(() => {
    dispatch(getCommunityStripeConnectAccount(propertyId));
  }, [dispatch, propertyId]);

  return (
    <>
      <PageHeader
        title={t('addEvent.title')}
        white
        withTabs
        backLink={`/properties/${match.params.propertyId}/community/events`}
      />
      <Formik
        initialValues={{
          title: '',
          description: '',
          location: '',
          event_date: '',
          start_time: null,
          end_time: null,
          image_data: emptyImageData,
          min_attendees: '',
          max_attendees: '',
          rsvp_cutoff_date: '',
          rsvp_cutoff_time: null,
          is_paid_event: false,
          price_amount: '0',
          event_group_ids: [],
          refund_cutoff_date: '',
          refund_cutoff_time: null,
          cancel_fee_percent: '',
          confirmation_details: '',
          vendor_id: '',
          community_fee_percent: '',
          waitlist_allowed: false,
          pending_duration: 1,
        }}
        validationSchema={validationSchema}
        onSubmit={(formValues, formikActions) => {
          if (eventSummaryModalVisible) {
            setEventSummaryModalVisible(false);

            const submitValues = cloneDeep(formValues);
            delete submitValues.is_paid_event;

            createEvent.mutate(
              {
                ...submitValues,
                event_date: moment(submitValues.event_date).format('YYYY-MM-DD'),
                start_time: moment(submitValues.start_time).format('HH:mm'),
                end_time: moment(submitValues.end_time).format('HH:mm'),
                image_data: submitValues.image_data.result,
                rsvp_cutoff_date: moment(submitValues.rsvp_cutoff_date).format('YYYY-MM-DD'),
                rsvp_cutoff_time: moment(submitValues.rsvp_cutoff_time).format('HH:mm'),
                price_amount: dollarsToCents(submitValues.price_amount),
                refund_cutoff_date: submitValues.refund_cutoff_date
                  ? moment(submitValues.refund_cutoff_date).format('YYYY-MM-DD')
                  : null,
                refund_cutoff_time: submitValues.refund_cutoff_time
                  ? moment(formValues.refund_cutoff_time).format('HH:mm')
                  : null,
                vendor_id: submitValues.vendor_id ? submitValues.vendor_id : null,
                cancel_fee_percent: submitValues.cancel_fee_percent ? submitValues.cancel_fee_percent : null,
                community_fee_percent: submitValues.community_fee_percent ? submitValues.community_fee_percent : null,
              },
              {
                onSuccess: () => {
                  formikActions.resetForm();
                  history.push(`/properties/${match.params.propertyId}/community/events`);
                },
                onError: () => {
                  formikActions.setSubmitting(false);
                  setEventSummaryModalVisible(false);
                },
              }
            );
          } else {
            setEventSummaryModalVisible(true);
            formikActions.setSubmitting(false);
          }
        }}
        render={({
          values,
          dirty,
          errors,
          handleChange,
          setFieldValue,
          touched,
          handleSubmit,
          isSubmitting,
          setSubmitting,
        }) => (
          <>
            <TabsContainer>
              {tabs.map((tab, i) => {
                return (
                  <Tab
                    key={i}
                    label={t(`addEvent.tabs.${tab.label}`)}
                    isActive={currentTabIndex === i}
                    onPress={() => setCurrentTabIndex(i)}
                    showErrorBadge={tab.fieldsToShowTabErrorBadgeWhenInvalid?.some(
                      fieldName => touched[fieldName] && errors[fieldName]
                    )}
                  />
                );
              })}
            </TabsContainer>

            <div className="container add-edit-event--page">
              <ErrorBanner hasError={Object.keys(errors).some(fieldName => touched[fieldName])} />
              {createEvent.isError ? <AlertMessage type="error" msg={errorMessage} /> : null}

              <RouteLeavingGuard
                when={dirty}
                navigate={history.push}
                modalAlertProps={{
                  title: t('modals.cancelAddEvent.title'),
                  body: t('modals.cancelAddEvent.body'),
                  confirmButtonTitle: t('modals.cancelAddEvent.confirmButtonTitle'),
                  cancelButtonTitle: t('modals.cancelAddEvent.cancelButtonTitle'),
                }}
              />

              <form className="event-form" onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius top-right">
                  {tabs[currentTabIndex].component({
                    propertyId,
                    errors,
                    handleChange,
                    isSubmitting,
                    setFieldValue,
                    touched,
                    values,
                    t,
                    tTime,
                    unitGroupList,
                    stripeConnectAccountConnected,
                    communityStripeConnectAccountLoaded,
                    vendorsList: vendorsList?.data ?? [],
                  })}
                </div>

                <div
                  className="paper__footer radius-bottom-left radius-bottom-right"
                  style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                >
                  <div className="button--wrapper__grouping">
                    {currentTabIndex > 0 ? (
                      <button
                        type="button"
                        className="button button--icon"
                        onClick={() => setCurrentTabIndex(currentTabIndex - 1)}
                        disabled={isSubmitting}
                      >
                        <div className="button--children">
                          <Icon icon="ArrowLongLeft" />
                          <span>{t('addEvent.backBtn')}</span>
                        </div>
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="button button--secondary"
                      onClick={() => history.push(`/properties/${match.params.propertyId}/community/events`)}
                      disabled={isSubmitting}
                    >
                      {t('addEvent.cancelBtn')}
                    </button>
                  </div>
                  <div className="button--wrapper__grouping">
                    {currentTabIndex < tabs.length - 1 ? (
                      <button
                        type="button"
                        className="button button--icon"
                        onClick={e => {
                          e.preventDefault();
                          setCurrentTabIndex(currentTabIndex + 1);
                        }}
                        disabled={isSubmitting}
                      >
                        <div className="button--children">
                          <span>{t('addEvent.nextBtn')}</span>
                          <Icon icon="ArrowLongRight" />
                        </div>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="button"
                        disabled={isSubmitting || eventSummaryModalVisible}
                        onClick={handleSubmit}
                      >
                        {isSubmitting ? t('addEvent.savingBtn') : t('addEvent.saveEventBtn')}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <EventSummaryModal
              {...{
                formValues: values,
                handleSubmit,
                visible: eventSummaryModalVisible,
                isSubmitting,
                onClose: () => {
                  setSubmitting(false);
                  setEventSummaryModalVisible(false);
                },
                vendorsList,
              }}
            />
          </>
        )}
      />
    </>
  );
};

export default AddEvent;
