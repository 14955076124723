import React from 'react';
import AlertMessage from '../AlertMessage';
import { useTranslation } from 'react-i18next';

export const ErrorBanner = ({ hasError }) => {
  const { t } = useTranslation('errors');
  if (!hasError) return null;

  return <AlertMessage icon msg={t('correctErrors')} type="error" />;
};
