import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import AnnouncementDetailsForm from '../../../../../../../../common/forms/AnnouncementDetailsForm';
import Loading from '../../../../../../../../common/Loading';
import PropertyPageHeader from '../../../../../../../../common/PropertyPageHeader';

class EditAnnouncement extends Component {
  state = { showRemove: false };
  componentDidMount() {
    this.props.getMessage();
  }
  toggleRemove = () => {
    this.setState({ showRemove: !this.state.showRemove });
  };
  render() {
    if (this.props.loaded) {
      const {
        editMessage,
        history,
        property,
        groupOptions,
        chosenGroups,
        message,
        removeMessage,
        userPermissionList,
        t,
      } = this.props;
      const userPermission = {
        'Save/Remove Announcement': userPermissionList.includes('SAVE/REMOVE_ANNOUNCEMENT'),
      };
      const createdAt = moment
        .tz(message.get('created_at'), property.get('timezone_id'))
        .format('MM/DD/YYYY, hh:mm:ss A');
      return (
        <div>
          <PropertyPageHeader title={t('pageTitle')} subtitle={this.props.property.get('name')} backLink="./" />
          <AnnouncementDetailsForm
            initialValues={{
              title: message.get('title'),
              description: message.get('description') || '',
              groups: chosenGroups,
              all_residents_selected: message.get('all_residents_selected'),
            }}
            editMode={true}
            toggleRemove={this.toggleRemove}
            showRemove={this.state.showRemove}
            groupOptions={groupOptions}
            createdAt={createdAt}
            submitFunction={submitValues => {
              if (submitValues.groups && submitValues.groups.length) {
                submitValues.groups = submitValues.groups.map(group => {
                  return { id: group };
                });
              }
              editMessage(submitValues, message.get('id'), property.get('id')).then(action => {
                if (action.response.ok) {
                  history.push(`./`);
                }
              });
            }}
            removeMessage={() => {
              removeMessage(message.get('id'), property.get('id')).then(action => {
                if (action.response.ok) {
                  history.push(`./`);
                }
              });
            }}
            saveRemoveAnnouncementPermission={userPermission['Save/Remove Announcement']}
          />
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

EditAnnouncement.propTypes = {
  editMessage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  property: PropTypes.instanceOf(Map).isRequired,
  groupOptions: PropTypes.array,
  loaded: PropTypes.bool,
  chosenGroups: PropTypes.array,
  message: PropTypes.object,
  t: PropTypes.func.isRequired,
};
export default withTranslation('announcements')(EditAnnouncement);
