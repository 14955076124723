import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
class CloseWaitListModel extends Component {
  render() {
    const { onCancel, visible, onConfirm, t } = this.props;
    if (!visible) return null;

    return (
      <div className="modal modal--visible">
        <div className="modal__children">
          <div className="modal__header">
            <h3>{t('editEvent.closeWaitListModal.header')}</h3>
          </div>
          <div className="modal__body">
            <div className="modal__body--text">{t('editEvent.closeWaitListModal.body')}</div>
          </div>
          <div className="modal__button--container">
            <button className="button" type="button" onClick={onConfirm}>
              {t('editEvent.closeWaitListModal.remove')}
            </button>
            <button className="button button--secondary" type="button" onClick={onCancel}>
              {t('editEvent.closeWaitListModal.cancel')}
            </button>
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={onCancel} />
      </div>
    );
  }
}

export default withTranslation('events')(CloseWaitListModel);
