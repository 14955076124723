import React, { useCallback, useState, useEffect } from 'react';
import PageHeader from '../../../../../../../common/PageHeader';
import { Tab, TabsContainer } from '../../../../../../../common/Tabs';
import {
  useCancelEventSuccessFromEditPage,
  useEventById,
  useNavigateToEventListPage,
  useNavigateToEventListPageIfEventIsCanceled,
} from '../hooks';
import { EventDetails } from './EventDetails';
import { ReadOnlyEventDetails } from './EventDetails/ReadOnlyDetails';
import ComponentLoading from '../../../../../../../common/Loading/ComponentLoading';
import Kebab from '../../../../../../../common/Kebab';
import Icon from '../../../../../../../common/icons/icon';
import { CancelEventModal } from '../CancelEventModal';
import Communications from './Communications';
import { Registrations } from './Registrations';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups } from '../../../../../redux/actions';
import RefundPolicy from './RefundPolicy';
import { RevenueDetails } from './RevenueDetails';
import moment from 'moment';
import { getUserPermission } from '../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const EditEvent = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ match, userPermissionList }) => {
    const userPermission = {
      'Edit/Cancel Event': userPermissionList.includes('EDIT/CANCEL_EVENT'),
    };
    const [isKebabOpen, setKebabOpen] = useState(false);
    const [isCanceling, setCanceling] = useState(false);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const event = useEventById(match.params.eventId);
    const onCloseCancelEventModal = useCallback(() => {
      setCanceling(false);
    }, []);
    const onCancelEvent = useCancelEventSuccessFromEditPage(event?.data?.title);
    const navigateToEventListPage = useNavigateToEventListPage();
    useNavigateToEventListPageIfEventIsCanceled(event?.data?.canceled_at);
    const propertyId = match.params.propertyId;
    const dispatch = useDispatch();
    const unitGroupList = useSelector(state => state.property.groupList);
    const { t } = useTranslation('events');

    let eventDetails = EventDetails;
    if (event.data != null) {
      const eventDate = moment(event.data.event_date).format('YYYY-MM-DD');
      const eventTime = moment(event.data.start_time, 'HH:mm').format('HH:mm');
      const eventDateTime = moment(eventDate + ' ' + eventTime);

      if (eventDateTime.isBefore(moment())) {
        eventDetails = ReadOnlyEventDetails;
      }
    }
    const cancelEventKebab = () => {
      if (eventDetails === EventDetails && userPermission['Edit/Cancel Event']) {
        return (
          <Kebab
            vertical
            onClick={() => {
              setKebabOpen(true);
            }}
            onDisengage={() => {
              setKebabOpen(false);
            }}
            menuOpened={isKebabOpen}
          >
            <div className="kebab__menu">
              <div
                className="kebab__menu--item"
                onClick={() => {
                  setCanceling(true);
                }}
              >
                <Icon icon="CircleX" /> {t('editEvent.cancelKebab')}
              </div>
            </div>
          </Kebab>
        );
      }
    };

    const tabs = [
      {
        label: 'Event Details',
        component: eventDetails,
      },
      ...(event && event?.data?.price_amount > 0
        ? [
            {
              label: 'Revenue Details',
              component: RevenueDetails,
            },
          ]
        : []),
      {
        label: 'Registrations',
        component: Registrations,
      },
      ...(event && event?.data?.price_amount > 0
        ? [
            {
              label: 'Refund Policy',
              component: RefundPolicy,
            },
          ]
        : []),
      {
        label: 'Communications',
        component: Communications,
      },
    ];

    useEffect(() => {
      if (propertyId) {
        dispatch(getGroups(propertyId));
      }
    }, [dispatch, propertyId]);

    const CurrentComponent = tabs[currentTabIndex].component;
    return (
      <>
        <CancelEventModal
          eventId={match.params.eventId}
          visible={isCanceling}
          onCancel={onCancelEvent}
          onClose={onCloseCancelEventModal}
        />
        <PageHeader
          title={event.isFetchedAfterMount ? event.data.title : <ComponentLoading />}
          white
          withTabs
          backLink={`/properties/${match.params.propertyId}/community/events`}
        />
        <div className="event-details--subnav__wrapper">
          <TabsContainer noBorder>
            {tabs.map((tab, i) => (
              <Tab
                key={tab.label}
                label={t(`editEvent.tabs.${tab.label}`)}
                isActive={currentTabIndex === i}
                onPress={() => setCurrentTabIndex(i)}
              />
            ))}
          </TabsContainer>
          {cancelEventKebab()}
        </div>
        <CurrentComponent
          {...{
            event: event.data,
            isEventLoaded: event.isFetchedAfterMount,
            navigateToEventListPage,
            unitGroupList,
          }}
        />
      </>
    );
  }
);
