import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../../../common/icons/icon';
import 'cropperjs/dist/cropper.css';
import { AmenityImageInput } from './AmenityImageInput';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const AmenityImagesList = ({ images, onChangeImages }) => {
  return (
    <DragDropContext
      onDragEnd={result => {
        const wasDroppedOutsideList = !result.destination;
        if (wasDroppedOutsideList || result.destination.index === result.source.index) {
          return;
        }

        onChangeImages(reorder(images, result.source.index, result.destination.index));
      }}
    >
      <Droppable droppableId="droppable" direction="horizontal">
        {provided => (
          <div className="image-upload__list" ref={provided.innerRef} {...provided.droppableProps}>
            {images.map((image, index) => (
              <Draggable
                key={image.id || image.tempId}
                draggableId={image.id || image.tempId}
                index={index}
                isDragDisabled={images.length === 1}
              >
                {({ draggableProps, dragHandleProps, innerRef }) => (
                  <div ref={innerRef} {...draggableProps}>
                    <div className="amenity__draggable--img-preview" style={{ backgroundImage: `url(${image.url})` }}>
                      <div className="draggable-actions__container">
                        {images.length > 1 && (
                          <div className="image-upload__drag-handle" {...dragHandleProps}>
                            <Icon icon="HorizontalDragDrop" />
                          </div>
                        )}
                        <div
                          className="image-upload__remove"
                          onClick={() => onChangeImages([...images.slice(0, index), ...images.slice(index + 1)])}
                        >
                          <Icon icon="TrashOutline" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const Images = ({ errors, handleChange, setFieldValue, touched, values }) => {
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.images.sections' });

  return (
    <div className="paper__grid amenities__images--page">
      <div className="paper__section--grid-item full">
        <div className="paper__section">
          <h4 className="section-header h4">{t('upload.title')}</h4>
          <p className="help-text tighter">{t('upload.helperText')}</p>
          <AmenityImageInput
            images={values.images}
            onChangeImages={images => {
              setFieldValue('images', images);
            }}
          />
        </div>
        {values.images.length > 0 && (
          <div className="paper__section">
            <h4 className="section-header h4">{t('amenity.title')}</h4>
            <p className="help-text tighter">{t('amenity.subtitle')}</p>
            <AmenityImagesList images={values.images} onChangeImages={images => setFieldValue('images', images)} />
          </div>
        )}
      </div>
    </div>
  );
};
