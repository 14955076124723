import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { addPropertyGuest, removePropertyGuest } from '../../../../../../redux/actions';
import PropertyGuestsList from './PropertyGuestsList';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    propertyGuests: state.property.propertyGuests,
    propertyGuestsLoaded: state.property.propertyGuestsLoaded,
  };
}

function mapDispatchToProps(dispatch, props) {
  const { propertyId } = props.match.params;
  return {
    addPropertyGuest: values => dispatch(addPropertyGuest(props.match.params.propertyId, values)),
    removePropertyGuest: guestId => dispatch(removePropertyGuest(propertyId, guestId)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(withTranslation(['propertyGuests', 'buttons'])(PropertyGuestsList));
