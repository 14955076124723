import React from 'react';
import { Link } from 'react-router-dom';
import querystring from 'query-string';
import { useTranslation } from 'react-i18next';

export default function ResidentGuestsTable({ accessLogPathname, unitGuests, onClickRevoke }) {
  const { t } = useTranslation('residentGuests', { keyPrefix: 'list' });

  if (unitGuests.size === 0) {
    return <div className="zero-content">{t('noResidents')}</div>;
  }

  return (
    <table className="table-list">
      <thead>
        <tr>
          <th>{t('tableHeaders.residentName')}</th>
          <th>{t('tableHeaders.residentUnit')}</th>
          <th>{t('tableHeaders.guestName')}</th>
          <th>{t('tableHeaders.guestAccessDateRange')}</th>
          <th>{t('tableHeaders.scheduleType')}</th>
          <th>{t('tableHeaders.status')}</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {unitGuests.map(guest => (
          <tr key={guest.get('id')}>
            <td>{guest.get('residentName') || 'Former Resident'}</td>
            <td>{guest.get('unitNumber')}</td>
            <td>{guest.get('guestName')}</td>
            <td>{guest.get('guestAccessDateRange')}</td>
            <td>{guest.get('scheduleType')}</td>
            <td>{guest.get('communityAccessStatus')}</td>
            <td>
              <Link
                className="table-row__action-button"
                to={{
                  pathname: accessLogPathname,
                  search: querystring.stringify({
                    name: guest.get('guestName'),
                    page: 0,
                    page_size: 30,
                  }),
                }}
              >
                {t('accessLog')} →
              </Link>
            </td>
            <td>
              {guest.get('communityAccessStatus') !== 'Revoked' && (
                <button
                  className="button button--destructive"
                  type="button"
                  onClick={() => onClickRevoke(guest.get('id'))}
                >
                  {t('revoke')}
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
