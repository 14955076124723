import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withTranslation } from 'react-i18next';
import Icon from '../../../../../../../../../../common/icons/icon';
import ResidentList from './ResidentList';
import DeviceControlRow from '../../DeviceControlRow';
import AlertMessage from '../../../../../../../../../../common/AlertMessage';
import PropertyPageHeader from '../../../../../../../../../../common/PropertyPageHeader';
// import pusher from '../../../../../../../../../../common/pusher';
import { Link } from 'react-router-dom';
import AddPanelRequestForm from '../../AddPanelRequestForm';
import Yup from 'yup';
import Loading from '../../../../../../../../../../common/Loading';
import PrivateSection from '../../../../../../../../../../common/PrivateSection';

class ResidentsAndDevicesList extends Component {
  state = {
    devicesLoading: {},
    deviceExpanded: null,
    showPanelRequest: false,
    panelRequestSent: false,
    userPermission: {},
  };

  componentDidMount() {
    if (this.props.unit.get('panel_id') && this.props.unit.getIn(['panel', 'devices']).size) {
      this.props.unit.getIn(['panel', 'devices']).forEach(device => {
        const newObj = Object.assign(this.state.devicesLoading, { [device.get('id')]: false });
        this.setState({ devicesLoading: newObj });
      });
    }

    if (this.canEditSchedules()) {
      this.props.getUnitSchedules();
    }
  }

  canEditSchedules() {
    return this.props.isAdmin || this.props.unit.get('residents').size === 0;
  }

  isSchedulableDevice(device) {
    const schedulableTypes = ['THERMOSTAT', 'LIGHT'];
    return schedulableTypes.indexOf(device.get('dmp_hardware_type')) > -1;
  }

  refreshDeviceList = () => {
    if (this.props.unit.get('panel_id') && this.props.unit.getIn(['panel', 'devices']).size) {
      this.props.unit.getIn(['panel', 'devices']).forEach(device => {
        switch (device.get('dmp_hardware_type')) {
          case 'LOCK':
            this.setDeviceStatus(device.get('id'), true);
            this.props.getLockStatus(device.get('id')).then(() => this.setDeviceStatus(device.get('id'), false));
            break;
          case 'BARRIER_OPERATOR':
            this.setDeviceStatus(device.get('id'), true);
            this.props.getBarrierStatus(device.get('id')).then(() => this.setDeviceStatus(device.get('id'), false));
            break;
          case 'LIGHT':
            this.setDeviceStatus(device.get('id'), true);
            this.props.getLightStatus(device.get('id')).then(() => {
              this.setDeviceStatus(device.get('id'), false);
            });
            break;
          case 'THERMOSTAT':
            this.setDeviceStatus(device.get('id'), true);
            this.props.getThermostatLatest(device.get('id')).then(() => this.setDeviceStatus(device.get('id'), false));
            break;
          default:
            break;
        }
      });
    }
  };

  handleDeviceUpdate = data => {
    this.props.updateZoneStatusFromWS(data);
  };

  componentDidUpdate(prevProps) {
    const { unit } = this.props;
    if (unit.get('panel_request_sent') && this.state.showPanelRequest) {
      this.setState({ showPanelRequest: false });
    }
    if (prevProps.unit.get('residents').size !== unit.get('residents').size && !unit.get('residents').size) {
      this.refreshDeviceList();

      if (this.canEditSchedules()) {
        // schedules removed when unit becomes vacant, so update schedule list
        this.props.getUnitSchedules();
      }
    } else if (prevProps.unit.get('residents').size === 0 && unit.get('residents').size > 0) {
      if (this.canEditSchedules()) {
        // schedules removed when unit becomes occupied, so update schedule list
        this.props.getUnitSchedules();
      }
    } else {
      // if (unit.get('panel_id') && unit.getIn(['panel', 'devices']).size) {
      //   unit.getIn(['panel', 'devices']).forEach(device => {
      //     if (device.get('dmp_hardware_type') === 'SENSOR') {
      //       const deviceChannelName = `private-device-${device.get('id')}`;
      //       let deviceChannel = pusher.channel(deviceChannelName);
      //       if (!deviceChannel) {
      //         deviceChannel = pusher.subscribe(deviceChannelName);
      //         deviceChannel.bind('status-changed', data => {
      //           this.handleDeviceUpdate(data);
      //         });
      //       }
      //     }
      //   });
      // }
    }
  }

  setDeviceStatus = (id, status) => {
    const newObj = { [id]: status };
    this.setState(state => ({ devicesLoading: Object.assign(state.devicesLoading, newObj) }));
  };

  toggleExpandedDevice = deviceId => {
    this.setState(prevState => {
      if (prevState.deviceExpanded === deviceId) {
        return { deviceExpanded: null };
      }
      return { deviceExpanded: deviceId };
    });
  };
  submitPanelRequest = (values, formActions) => {
    this.props.submitPanelRequest(values).then(action => {
      if (!action.response.ok) {
        this.setState({ submitPanelError: 'Error submitting form, please try again' });
      } else {
        this.setState({ panelRequestSent: true });
      }
    });
  };

  renderEmptyState() {
    const { unit, property, t } = this.props;
    const allowPanelRequest = !this.props.unit.getIn(['panel', 'id']) && !this.props.unit.get('panel_request_sent');
    const panelValidationSchema = Yup.object().shape({
      serial_number: Yup.string('')
        .trim()
        .required('Please enter a serial number'),
      account_number: Yup.string('')
        .trim()
        .required('Please enter the account number'),
    });
    return (
      <div className="empty-state">
        {this.state.panelRequestSent ? <AlertMessage msg="Activation request sent." type="success" /> : null}
        {allowPanelRequest ? (
          this.state.showPanelRequest ? (
            <div>
              <AddPanelRequestForm
                onCancel={() => {
                  this.setState({ showPanelRequest: false });
                }}
                submitFunc={this.submitPanelRequest}
                validationSchema={panelValidationSchema}
                initialValues={{
                  serial_number: '',
                  account_number: '',
                  property_name: property.get('name'),
                  unit_number: unit.get('number'),
                }}
              />{' '}
              {this.state.submitPanelError}{' '}
            </div>
          ) : (
            <button
              onClick={() => {
                this.setState({ showPanelRequest: true });
              }}
              className="button button--request-panel"
            >
              {t('unitDevicesTextBtn')}
            </button>
          )
        ) : null}
        <h1 className="h1">{t('unitDevicesHelperText')}</h1>
        <Icon icon="Robot" />
      </div>
    );
  }

  renderDevices() {
    const {
      createDeviceSchedule,
      deleteDeviceSchedule,
      editDeviceSchedule,
      updateLock,
      updateLight,
      getThermostatLatest,
      getLightStatus,
      getLockStatus,
      updateThermostat,
      updateBarrier,
      unit,
      property,
      userPermissionList,
      isAdmin,
      getVideoDoorbellDevice,
      editVideoDoorbellDeviceSettings,
      isVideoDoorbellsV2Enabled,
    } = this.props;

    const userPermission = {
      controlSmartHomeDevicesOccupiedUnit: userPermissionList.includes('CONTROL_SMART_HOME_DEVICES_OCCUPIED_UNIT'),
      controlSmartHomeDevicesvacantUnit: userPermissionList.includes('CONTROL_SMART_HOME_DEVICES_VACANT_UNIT'),
    };
    const isUnitVacant = unit.get('residents').size === 0;
    const controlOccupiedUnit = userPermission.controlSmartHomeDevicesOccupiedUnit && !isUnitVacant;
    const controlVacantUnit = userPermission.controlSmartHomeDevicesvacantUnit && isUnitVacant;
    const disableControls = isAdmin ? false : controlVacantUnit ? false : controlOccupiedUnit ? false : true;
    const devicesContent = [];

    if (!property) {
      return <Loading />;
    }
    const approvedDevices =
      unit.get('panel_id') && unit.getIn(['panel', 'devices']).size
        ? property.get('enable_video_doorbells')
          ? unit.getIn(['panel', 'devices'])
          : unit.getIn(['panel', 'devices']).filter(device => device.get('dmp_hardware_type') !== 'DOORBELL')
        : [];

    approvedDevices.forEach(device => {
      devicesContent.push(
        <DeviceControlRow
          isLoading={this.state.devicesLoading[device.get('id')]}
          setDeviceStatus={this.setDeviceStatus}
          key={device.get('id')}
          disableControls={disableControls}
          getThermostatLatest={getThermostatLatest}
          getLightStatus={getLightStatus}
          getLockStatus={getLockStatus}
          updateLock={updateLock}
          updateBarrier={updateBarrier}
          updateLight={updateLight}
          editVideoDoorbellDeviceSettings={editVideoDoorbellDeviceSettings}
          getVideoDoorbellDevice={getVideoDoorbellDevice}
          device={device}
          updateThermostat={updateThermostat}
          createDeviceSchedule={createDeviceSchedule}
          editDeviceSchedule={editDeviceSchedule}
          deleteDeviceSchedule={deleteDeviceSchedule}
          canSchedule={this.canEditSchedules() && this.isSchedulableDevice(device)}
          schedules={unit.getIn(['schedules', device.get('id')])}
          onToggleExpanded={this.toggleExpandedDevice.bind(this, device.get('id'))}
          expanded={this.state.deviceExpanded === device.get('id')}
          isVideoDoorbellsV2Enabled={isVideoDoorbellsV2Enabled}
        />
      );
    });
    return devicesContent.length === 0 ? this.renderEmptyState() : <div className="device--list">{devicesContent}</div>;
  }

  render() {
    const { property, removeResident, resendInvite, unit, userId } = this.props;
    const { userPermissionList, t } = this.props;
    const userPermission = {
      addResidentToUnit: userPermissionList.includes('ADD_RESIDENT_TO_UNIT'),
      changeAresidentsAccessControlProfile: userPermissionList.includes("CHANGE_A_RESIDENT'S_ACCESS_CONTROL_PROFILE"),
      editResidentPersonalDetails: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
      removeResident: userPermissionList.includes('REMOVE_RESIDENT'),
      resendResidentsAaccessCodes: userPermissionList.includes("RESEND_A_RESIDENT'S_ACCESS_CODES"),
      controlSmartHomeDevicesOccupiedUnit: userPermissionList.includes('CONTROL_SMART_HOME_DEVICES_OCCUPIED_UNIT'),
      controlSmartHomeDevicesvacantUnit: userPermissionList.includes('CONTROL_SMART_HOME_DEVICES_VACANT_UNIT'),
      viewTheStatusOfSmartHomeDevicesOccupiedUnit: userPermissionList.includes(
        'VIEW_THE_STATUS_OF_SMART_HOME_DEVICES_OCCUPIED_UNIT'
      ),
      viewTheStatusOfSmartHomeDevicesVacantUnit: userPermissionList.includes(
        'VIEW_THE_STATUS_OF_SMART_HOME_DEVICES_VACANT_UNIT'
      ),
      viewResidentContactInformation: userPermissionList.includes('VIEW_RESIDENT_CONTACT_INFORMATION'),
      editUnitDetails: userPermissionList.includes('EDIT_UNIT_DETAILS'),
      viewResidentList: userPermissionList.includes('VIEW_RESIDENT_LIST'),
    };
    let residentsize = unit.get('residents').size === 0 ? true : false;
    let viewOccupiedUnit = userPermission.viewTheStatusOfSmartHomeDevicesOccupiedUnit && !residentsize;
    let viewVacantUnit = userPermission.viewTheStatusOfSmartHomeDevicesVacantUnit && residentsize;
    let viewDevicesPage = userPermissionList.includes('VIEW_DEVICES_PAGE');
    let failedDevices;
    if (unit.get('panel_id') && unit.getIn(['panel', 'devices']).size) {
      failedDevices = unit
        .getIn(['panel', 'devices'])
        .filter(device => !device.get('last_status') && device.get('dmp_hardware_type') !== 'DOORBELL');
    }
    return (
      <div className="unit-details--page">
        {userPermission.editUnitDetails === true ? (
          <PropertyPageHeader subtitle={property.get('name')} backLink="./" editLink={`./${unit.get('id')}/edit`} />
        ) : (
          <PropertyPageHeader subtitle={property.get('name')} backLink="./" editLink="" />
        )}
        {userPermission.viewResidentList ? (
          <div className="container">
            <ResidentList
              removeResident={removeResident}
              onResendInvite={resendInvite}
              residents={unit.get('residents')}
              property={property}
              unit={unit}
              userPermission={userPermission}
              userId={userId}
            />
          </div>
        ) : null}

        {property.get('admin_enabled_unit_automation')
          ? (viewOccupiedUnit || viewVacantUnit) && (
              <div className="container unit-details__device-list--container">
                {failedDevices && failedDevices.size ? (
                  <AlertMessage
                    msg="One of the devices is not responding. Try refreshing devices to re-establish a connection. If it's still having trouble connecting, send a maintenance request"
                    type="error"
                  />
                ) : null}
                <div className="page-header subheader">
                  <div className="page-header__title">
                    <h4 className="h4">{t('unitDevicesTitle')}</h4>

                    {this.props.unit.get('panel_id') &&
                    this.props.unit.getIn(['panel', 'devices']).size &&
                    (this.props.isAdmin ||
                      this.props.isPropertyManager ||
                      userPermission.controlSmartHomeDevicesOccupiedUnit ||
                      unit.get('residents').size === 0) ? (
                      <div className="refresh-button" onClick={this.refreshDeviceList}>
                        <Icon icon="Refresh" />
                      </div>
                    ) : null}
                  </div>
                  <div className="page-header__read-only">
                    <h4>
                      {unit.get('panel_id') ? (
                        <PrivateSection
                          roles={viewDevicesPage ? ['ADMIN', 'PROPERTY_MANAGER'] : ['ADMIN']}
                          renderFallback={() => `Panel#: ${unit.getIn(['panel', 'dmp_serial_number'])}`}
                        >
                          <Link
                            to={`/properties/${property.get(
                              'id'
                            )}/property-details/devices/properties/panels/${unit.getIn(['panel', 'id'])}`}
                          >
                            Panel#: {unit.getIn(['panel', 'dmp_serial_number'])}
                          </Link>
                        </PrivateSection>
                      ) : null}
                    </h4>
                  </div>
                </div>
                {this.renderDevices()}
              </div>
            )
          : null}
      </div>
    );
  }
}

ResidentsAndDevicesList.propTypes = {
  loaded: PropTypes.bool.isRequired,
  property: PropTypes.instanceOf(Map).isRequired,
  removeResident: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  unit: PropTypes.instanceOf(Map).isRequired,
};
export default withTranslation('units')(ResidentsAndDevicesList);
