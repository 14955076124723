import React from 'react';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const AmenityDailyAvailabilityPicker = ({ isSubmitting, setFieldValue, values, weekDays, errors, touched }) => {
  const { t } = useTranslation('amenities', {
    keyPrefix: 'amenityPage.tabs.availability.sections.daysAndHours.subsections.daily',
  });
  const { t: tCommon } = useTranslation('time');

  const setDailyOpenTime = time => {
    let schedule = {};
    weekDays.forEach(day => {
      schedule[day.name] = {
        open: time,
        close: values.availability_schedule[day.name]?.close,
      };
    });
    setFieldValue('availability_schedule', schedule);
  };

  const setDailyCloseTime = time => {
    let schedule = {};
    weekDays.forEach(day => {
      schedule[day.name] = {
        close: time,
        open: values.availability_schedule[day.name]?.open,
      };
    });
    setFieldValue('availability_schedule', schedule);
  };

  return (
    <>
      <h4 className="section-header h4 w--helper">{t('title')}</h4>
      <p className="help-text">{t('subtitle')}</p>
      <InputScaffold label={t('openTimeField')} required>
        <TimePicker
          name="open"
          showSecond={false}
          defaultValue={values.availability_schedule?.monday?.open}
          onChange={setDailyOpenTime}
          defaultOpenValue={moment()
            .hour(8)
            .minute(0)
            .second(0)}
          format={tCommon('timeFormat')}
          allowEmpty={false}
          use12Hours={tCommon('is12HoursTime')}
          inputReadOnly
          disabled={isSubmitting}
          minuteStep={15}
        />
      </InputScaffold>
      <InputScaffold label={t('closeTimeField')} required>
        <TimePicker
          name="close"
          showSecond={false}
          defaultValue={values.availability_schedule?.monday?.close}
          defaultOpenValue={moment()
            .hour(20)
            .minute(0)
            .second(0)}
          onChange={setDailyCloseTime}
          format={tCommon('timeFormat')}
          allowEmpty={false}
          use12Hours={tCommon('is12HoursTime')}
          inputReadOnly
          disabled={isSubmitting}
          minuteStep={15}
        />
      </InputScaffold>
      {touched.availability_schedule && errors.availability_schedule ? (
        <div className="input-validation">{errors.availability_schedule}</div>
      ) : null}
    </>
  );
};
