import React, { useState, useEffect } from 'react';
import Icon from '../../../../../../../../common/icons/icon';
import AlertMessage from '../../../../../../../../common/AlertMessage';
import { useCancelRegistration } from '../../hooks';
import { useTranslation } from 'react-i18next';

const formattedErrorMessages = {
  ResidentNotRegistered: 'This registration is not active.',
  CannotUnregisterForEventThatHasStarted: 'This event has started and registrations cannot be canceled.',
};

export const CancelRegistrationModal = ({ eventId, residentId, onClose, onCancel, visible, cancelType }) => {
  const [submitError, setSubmitError] = useState(null);
  const cancelRegistration = useCancelRegistration(eventId, residentId);
  //   const cancelWaitList = useCancelWaitList(eventId, residentId);
  const { t } = useTranslation('events', { keyPrefix: 'modals' });
  const { t: tButtons } = useTranslation('buttons');

  useEffect(() => {
    if (submitError) {
      setSubmitError(null);
    }
  }, [submitError, visible]);

  if (!visible) return null;

  return (
    <div className="modal modal--visible">
      <div className="modal__children">
        <div className="modal__header">
          <h3>
            {cancelType === 'Attend'
              ? t('cancelEventRegistration.title')
              : cancelType === 'Pending'
              ? t('cancelEventRegistrationPending.title')
              : t('cancelEventRegistrationWaitList.title')}
          </h3>
          <div className="modal__close" onClick={onCancel}>
            <Icon icon="Clear" />
          </div>
        </div>
        <div className="modal__body">
          <p className="modal__body--text">
            {cancelType === 'Attend'
              ? t('cancelEventRegistration.body')
              : cancelType === 'Pending'
              ? t('cancelEventRegistrationPending.body')
              : t('cancelEventRegistrationWaitList.body')}
          </p>
          {submitError && <AlertMessage msg={submitError} type="error" />}
        </div>
        <div className="modal__button--container">
          <button
            disabled={cancelRegistration.isLoading}
            className="button"
            type="button"
            onClick={() => {
              cancelRegistration.mutate(null, {
                onSuccess: () => {
                  // refetch list..?
                  onClose();
                },
                onError: e => {
                  setSubmitError(formattedErrorMessages[e.message] || e.message);
                },
              });
            }}
          >
            {cancelType === 'Attend'
              ? t('cancelEventRegistration.submit')
              : cancelType === 'Pending'
              ? t('cancelEventRegistrationPending.submit')
              : t('cancelEventRegistrationWaitList.submit')}
          </button>
          <button
            disabled={cancelRegistration.isLoading}
            className="button button--secondary"
            type="button"
            onClick={onCancel}
          >
            {tButtons('cancel')}
          </button>
        </div>
      </div>
      <button type="button" className="modal__overlay" onClick={onCancel} />
    </div>
  );
};
