import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/lib/src/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { useAddTemporaryClosing } from '../../hooks';
import moment from 'moment';
import { DeleteTemporaryClosingModal } from './DeleteTemporaryClosingModal';
import Icon from '../../../../../../../../common/icons/icon';
import AlertMessage from '../../../../../../../../common/AlertMessage';
import { ConfirmTemporaryClosingModal } from './ConfirmTemporaryClosingModal';
import { useTranslation } from 'react-i18next';

const formattedTemporaryClosingsErrors = {
  InvalidTemporaryClosingDateRange: 'The open date must be after the close date.',
  OverlappingTemporaryClosings:
    'There is already a closing during this time.  Please delete a previous closing, or modify the date range.',
};

export const TemporaryClosings = ({
  isSubmitting,
  amenity,
  isAddingTemporaryClosing,
  setIsAddingTemporaryClosing,
  refetchAmenity,
}) => {
  const sortAndFilterTemporaryClosings = closingsArray => {
    return closingsArray
      .filter(closing => moment().isBefore(moment(closing.reopen_date)))
      .sort((first, second) => (moment(second.close_date).isBefore(moment(first.close_date)) ? 1 : -1));
  };

  const [isSubmittingTemporaryClosing, setIsSubmittingTemporaryClosing] = useState(false);
  const [currentClosing, setCurrentClosing] = useState({});
  const addTemporaryClosing = useAddTemporaryClosing(amenity?.id);
  const [temporaryClosingsList, setTemporaryClosingsList] = useState(
    sortAndFilterTemporaryClosings(amenity?.temporary_closings)
  );

  const [createTemporaryClosingModal, setCreateTemporaryClosingModal] = useState(false);
  const [deleteTemporaryClosingModal, setDeleteTemporaryClosingModal] = useState(null);

  const { t } = useTranslation('amenities', {
    keyPrefix: 'amenityPage.tabs.availability.sections.temporaryClosings',
  });
  const { t: tCommon } = useTranslation('buttons');
  const { t: tTime } = useTranslation('time');

  const createTemporaryClosing = () => {
    setIsSubmittingTemporaryClosing(true);

    let temporaryClosingData = { ...currentClosing };
    temporaryClosingData.close_date = moment(temporaryClosingData.close_date).format('YYYY-MM-DD');
    temporaryClosingData.reopen_date = moment(temporaryClosingData.reopen_date).format('YYYY-MM-DD');

    addTemporaryClosing.mutate(temporaryClosingData, {
      onSuccess: res => {
        refetchAmenity().then(() => {
          setTemporaryClosingsList(sortAndFilterTemporaryClosings(res.temporary_closings));
          setCurrentClosing({});
          setIsAddingTemporaryClosing(false);
          setIsSubmittingTemporaryClosing(false);
        });
      },
      onError: e => {
        setIsSubmittingTemporaryClosing(false);
      },
    });
  };

  return (
    <div>
      <ConfirmTemporaryClosingModal
        onClose={() => {
          setCreateTemporaryClosingModal(false);
        }}
        onCreateTemporaryClosing={() => {
          createTemporaryClosing();
          setCreateTemporaryClosingModal(false);
        }}
        visible={!!createTemporaryClosingModal}
      />
      {!!deleteTemporaryClosingModal && (
        <DeleteTemporaryClosingModal
          temporaryClosingId={deleteTemporaryClosingModal}
          amenityId={amenity?.id}
          onDelete={res => {
            refetchAmenity().then(() => {
              setTemporaryClosingsList(res.temporary_closings);
              setDeleteTemporaryClosingModal(null);
            });
          }}
          onClose={() => setDeleteTemporaryClosingModal(null)}
        />
      )}

      <p className="help-text">{t('editSubtitle')}</p>

      {isAddingTemporaryClosing && (
        <div className="temporary-closing-form">
          {addTemporaryClosing.isError && (
            <AlertMessage
              msg={
                formattedTemporaryClosingsErrors[addTemporaryClosing.error.message] ||
                'An unknown error occurred while adding temporary closing.'
              }
              type="error"
              icon
            />
          )}

          <InputScaffold label={t('nameField')} required>
            <input
              type="text"
              name="name"
              maxLength="64"
              disabled={isSubmittingTemporaryClosing}
              onChange={e =>
                setCurrentClosing({
                  ...currentClosing,
                  name: e.target.value,
                })
              }
              value={currentClosing.name || ''}
            />
          </InputScaffold>
          <div className="double-input__wrapper">
            <InputScaffold className="double-input-item" label={t('closeDateField')} required>
              <DayPickerInput
                inputProps={{
                  autoComplete: 'false',
                  disabled: isSubmittingTemporaryClosing,
                }}
                name="close_date"
                value={currentClosing.close_date}
                placeholder=""
                format={tTime('dateFormat')}
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  numberOfMonths: 1,
                  disabledDays: date => moment(date).isBefore(moment()),
                }}
                onDayChange={date =>
                  setCurrentClosing({
                    ...currentClosing,
                    close_date: date,
                    reopen_date: '',
                  })
                }
                disabled={isSubmittingTemporaryClosing}
              />
            </InputScaffold>
            <InputScaffold className="double-input-item" label={t('openDateField')} required>
              <DayPickerInput
                inputProps={{
                  autoComplete: 'false',
                  disabled: isSubmittingTemporaryClosing,
                }}
                name="reopen_date"
                value={currentClosing.reopen_date}
                placeholder=""
                format={tTime('dateFormat')}
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  numberOfMonths: 1,
                  disabledDays: date => moment(date).isBefore(moment(currentClosing.close_date).add(1, 'day')),
                }}
                onDayChange={date =>
                  setCurrentClosing({
                    ...currentClosing,
                    reopen_date: date,
                  })
                }
                disabled={isSubmittingTemporaryClosing || !currentClosing.close_date}
              />
            </InputScaffold>
          </div>
          <div className="temporary-closing-form--button__wrapper">
            <button
              type="button"
              className="button button--secondary"
              disabled={isSubmittingTemporaryClosing}
              onClick={() => {
                setCurrentClosing({});
                setIsAddingTemporaryClosing(false);
              }}
            >
              {tCommon('cancel')}
            </button>
            <button
              type="button"
              className="button"
              disabled={
                isSubmittingTemporaryClosing ||
                !currentClosing.name ||
                !currentClosing.close_date ||
                !currentClosing.reopen_date
              }
              onClick={() => {
                setCreateTemporaryClosingModal(true);
              }}
            >
              {t('saveClosingButton')}
            </button>
          </div>
        </div>
      )}
      {!isAddingTemporaryClosing && (
        <div className={`temp-closing-add--button__wrapper ${temporaryClosingsList.length > 0 ? '' : 'empty'}`}>
          <button
            type="button"
            className="button button--outline--primary button--icon button-sm"
            disabled={isSubmitting}
            onClick={() => setIsAddingTemporaryClosing(true)}
          >
            <div className="button--children">
              <Icon icon="Add" />
              <span>{t('addTemporaryClosingButton')}</span>
            </div>
          </button>
        </div>
      )}
      <div className="temporary-closing-list">
        {temporaryClosingsList.map((closing, index) => (
          <div key={index} className="temporary-closing-item">
            <div className="temporary-closing-item__inner">
              <div className="temporary-closing-name">{closing.name}</div>
              <div className="temporary-closing-item__dates">
                <div>
                  {t('closes')}: {moment.utc(closing.close_date).format('MM/DD/YYYY')}
                </div>
                <div>
                  {t('reopens')}: {moment.utc(closing.reopen_date).format('MM/DD/YYYY')}
                </div>
              </div>
            </div>
            <button
              type="button"
              className="button button--destructive button--icon-only"
              onClick={() => setDeleteTemporaryClosingModal(closing.id)}
            >
              <Icon icon="TrashOutline" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
