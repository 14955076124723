import React from 'react';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { useTranslation } from 'react-i18next';

export const AmenityDetails = ({ errors, handleChange, isSubmitting, setFieldValue, touched, values }) => {
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.amenityDetails.sections' });

  return (
    <div className="paper__grid">
      <div className="paper__section--grid-item">
        <section className="paper__section">
          <div className="section-header h4">{t('amenityDetails.title')}</div>
          <InputScaffold label={t('amenityDetails.nameField')} required validation={touched.name && errors.name}>
            <input
              type="text"
              name="name"
              maxLength="50"
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.name}
            />
          </InputScaffold>
          <InputScaffold
            label={t('amenityDetails.descriptionField')}
            required
            validation={touched.description && errors.description}
          >
            <textarea
              name="description"
              maxLength="500"
              rows="6"
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.description}
            />
          </InputScaffold>
          <InputScaffold
            label={t('amenityDetails.maxOccupancyField')}
            validation={touched.max_occupancy && errors.max_occupancy}
          >
            <input
              type="text"
              name="max_occupancy"
              disabled={isSubmitting}
              onChange={({ target }) => {
                if (/^(?:[0-9][0-9]{0,2}?|1000|$)$/.test(target.value)) {
                  setFieldValue('max_occupancy', target.value);
                }
              }}
              value={values.max_occupancy}
            />
          </InputScaffold>
        </section>
      </div>
      <div className="paper__section--grid-item">
        <section className="paper__section">
          <h4 className="section-header h4">{t('termsOfUse.title')}</h4>
          <InputScaffold
            label={t('termsOfUse.termOfUseField')}
            validation={touched.terms_of_use && errors.terms_of_use}
          >
            <textarea
              name="terms_of_use"
              maxLength="2000"
              rows="6"
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.terms_of_use}
            />
          </InputScaffold>
        </section>
      </div>
    </div>
  );
};
