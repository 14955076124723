import React from 'react';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useNavigateToEditAmenityPage } from '../hooks';
import Kebab from '../../../../../../../common/Kebab';
import Icon from '../../../../../../../common/icons/icon';
import { RESERVATION_TYPES } from '../constants/reservationTypes';
import Dinero from 'dinero.js';
import { useTranslation } from 'react-i18next';

const CustomAvailabilityTooltip = ({ availabilitySchedule }) => {
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.availability' });
  const { t: tTime } = useTranslation('time');

  const weeks = [
    { key: 'sunday', display: t('weeks.sun') },
    { key: 'monday', display: t('weeks.mon') },
    { key: 'tuesday', display: t('weeks.tue') },
    { key: 'wednesday', display: t('weeks.wed') },
    { key: 'thursday', display: t('weeks.thu') },
    { key: 'friday', display: t('weeks.fri') },
    { key: 'saturday', display: t('weeks.sat') },
  ];

  return (
    <Tippy
      placement="bottom"
      content={
        <div className="availability-tooltip__wrapper">
          {weeks.map(({ key, display }) => {
            const scheduleForDay = availabilitySchedule.find(scheduleDay => scheduleDay.weekday === key);

            let hoursText = tTime('closed');
            if (scheduleForDay) {
              const openTime = moment(scheduleForDay.open_time, 'HH:mm:ss');
              const closeTime = moment(scheduleForDay.close_time, 'HH:mm:ss');
              const openTimeFormatString = tTime('timeFormat') || 'HH:mm';
              const closeTimeFormatString = tTime('timeFormat') || 'HH:mm';
              hoursText = `${openTime.format(openTimeFormatString)} - ${closeTime.format(closeTimeFormatString)}`;
            }

            return (
              <div key={key} className="availability-tooltip__row">
                <div className="day-of-week">{display}</div>
                <div className="hours">{hoursText}</div>
              </div>
            );
          })}
        </div>
      }
    >
      <span>
        <Icon icon="InfoOutline" />
      </span>
    </Tippy>
  );
};

const getFeeDisplayText = (reservationType, price, t) => {
  if (reservationType === 'NON_RESERVABLE') {
    return '-';
  }

  return price ? Dinero({ amount: price }).toFormat('$0,0.00') : t('mainScreen.free');
};

const AmenityListItem = ({
  amenity,
  isKebabMenuOpen,
  openKebabMenu,
  closeKebabMenu,
  onClickDelete,
  editDeleteAmenityPermission,
}) => {
  const { t } = useTranslation('amenities');
  const { t: tModal } = useTranslation('amenities', { keyPrefix: 'amenityPage.modals.amenityListItem' });
  const navigateToEditAmenityPage = useNavigateToEditAmenityPage(amenity.id);

  const formattedAvailabilityString = amenity?.availability.toLowerCase();
  const reservationTypeTranslateFn = key => t(`amenityPage.tabs.reservationType.${key}`);

  const reservationTypeDisplayValue =
    RESERVATION_TYPES(reservationTypeTranslateFn).find(type => type.name === amenity.reservation_type)?.display ??
    'N/A';

  return (
    <tr>
      <td className="first-column">
        <Link to={`/properties/${amenity.community_id}/community/amenities/${amenity.id}`}>{amenity.name}</Link>
      </td>

      <td>
        <div className="availability-cell">
          <span>
            {t(`amenityPage.tabs.availability.sections.daysAndHours.radioOptions.${formattedAvailabilityString}`)}
          </span>
          {amenity.availability === 'CUSTOM' && (
            <CustomAvailabilityTooltip availabilitySchedule={amenity.availability_schedule} />
          )}
        </div>
      </td>
      <td>{reservationTypeDisplayValue}</td>
      <td>{getFeeDisplayText(amenity.reservation_type, amenity.price, t)}</td>
      <td>{amenity.max_occupancy || '-'}</td>
      <td className="table__align-right">
        {editDeleteAmenityPermission && (
          <Kebab onClick={openKebabMenu} onDisengage={closeKebabMenu} menuOpened={isKebabMenuOpen}>
            <div className="kebab__menu">
              <div className="kebab__menu--item" onClick={navigateToEditAmenityPage}>
                <Icon icon="Edit" />
                <span>{tModal('edit')}</span>
              </div>
              <div className="kebab__menu--item" onClick={onClickDelete}>
                <Icon icon="TrashOutline" />
                <span>{tModal('delete')}</span>
              </div>
            </div>
          </Kebab>
        )}
      </td>
    </tr>
  );
};

export default AmenityListItem;
