import { connect } from 'react-redux';
import ResidentInformation from './ResidentInformation';
import { getUserPermission, selectorIsAdmin } from '../../../../../../../../../../../redux/selectors';
import { editResident, removeResident } from '../../../../../redux/actions';
import { selectActiveResident } from '../../../../../redux/selectors';
import { selectAccessControlProfileOptions } from '../../../../../../../../../redux/selectors';
import { getProfiles } from '../../../../../../../../../redux/actions';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state, props) {
  return {
    loaded: state.property.unit.activeUnitLoaded,
    property: state.property.activeProperty,
    hasPanel: !!state.property.activeProperty.get('clubhouse_panel_id'),
    unit: state.property.unit.activeUnit,
    isAdmin: selectorIsAdmin(state),
    resident: selectActiveResident(state, props.match.params.residentId),
    removeResidentLoading: state.property.unit.removeResidentLoading,
    accessControlProfilesLoaded: state.property.accessControlProfilesLoaded,
    accessControlProfileOptions: selectAccessControlProfileOptions(state),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { propertyId, unitId } = ownProps.match.params;

  return {
    editResident: (residentId, payload) => dispatch(editResident(residentId, payload)),
    removeResident: residentId => dispatch(removeResident(propertyId, unitId, residentId)),
    getProfiles: () => dispatch(getProfiles(ownProps.match.params.propertyId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getOffer: () => dispatchProps.getOffer(ownProps.match.params.offerId),
    editOffer: values => dispatchProps.editOffer(values, ownProps.match.params.offerId),
  });
}

const EditResidentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withTranslation('residents')(ResidentInformation));

export default EditResidentContainer;
