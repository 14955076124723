import { connect } from 'react-redux';
import { selectorIsAdmin, getUserPermission } from '../../redux/selectors';
import AdminLayout from './AdminLayout';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    isAdmin: selectorIsAdmin(state),
    userPermissionList: getUserPermission(state),
  };
}

const AdminLayoutContainer = compose(
  connect(mapStateToProps),
  withRouter
)(withTranslation('navigation', { keyPrefix: 'adminNavigation' })(AdminLayout));

export default AdminLayoutContainer;
