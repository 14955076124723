import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Loading from '../../../../../../common/Loading';
import Icon from '../../../../../../common/icons/icon';
import { Link } from 'react-router-dom';
import { SGUnitLabel } from '../SGTours/common';
import { UnitTooltip } from './UnitTooltip';

const UnitTable = ({ loading, property, units, userPermission, t }) => {
  if (loading) {
    return <Loading />;
  }
  const truncateUnitNumber = unitNumber => (unitNumber.length > 7 ? `${unitNumber.slice(0, 7)}...` : unitNumber);
  return (
    <div className="table-units-list__wrapper unit-table--container">
      <div className="units-list__container">
        <div className="units__flex-container">
          {units.map(unit => {
            const unitNumber = unit.get('number');
            const isEmpty = !unit.get('residents').size;
            return (
              <div className="unit-card" key={unit.get('id')}>
                <div className={`unit-card__inner paper ${isEmpty && 'empty'}`}>
                  <div className="unit-card__top">
                    <div className="unit-number">
                      <div className="unit-number-center">
                        {userPermission.viewUnit ||
                        userPermission.controlSmartHomeDevicesOccupiedUnit ||
                        (userPermission.viewVacantUnitAutomationPage && unit.get('residents').size === 0) ? (
                          <UnitTooltip
                            offset={[0, 10]}
                            theme={isEmpty && 'light'}
                            placement="bottom-start"
                            content={unitNumber}
                            disabled={unitNumber.length <= 7}
                          >
                            <Link to={`/properties/${property.get('id')}/units/${unit.get('id')}`}>
                              {truncateUnitNumber(unitNumber)}
                            </Link>
                          </UnitTooltip>
                        ) : (
                          <UnitTooltip
                            offset={[0, 10]}
                            theme={isEmpty && 'light'}
                            placement="bottom-start"
                            content={unitNumber}
                            disabled={unitNumber.length <= 7}
                          >
                            <div>{truncateUnitNumber(unitNumber)}</div>
                          </UnitTooltip>
                        )}
                        {unit.get('unit_type') === 'model' && <SGUnitLabel title="Model Unit" />}
                      </div>
                    </div>
                    <div className="unit-card__action-status">
                      {property.get('admin_enabled_unit_automation') && (
                        <div className="active-state__wrapper" title={unit.get('panel_id') ? 'Active' : 'Inactive'}>
                          {unit.get('panel_id') ? (
                            <Icon className="active-state--active" icon="CircleSolid" />
                          ) : (
                            <Icon className="active-state--inactive" icon="CircleSolid" />
                          )}
                        </div>
                      )}
                      {userPermission.editUnitDetails && (
                        <Link
                          className="link--edit"
                          to={`/properties/${property.get('id')}/units/${unit.get('id')}/edit`}
                        >
                          <Icon icon="Edit" />
                        </Link>
                      )}
                    </div>
                  </div>
                  {userPermission.viewResidentList ? (
                    <div className="unit-card__residents">
                      {unit.get('residents').size
                        ? unit
                            .get('residents')
                            .sortBy(resident => resident.getIn(['user', 'first_name']).toLowerCase())
                            .map((resident, i) => {
                              return (
                                <Fragment key={resident.get('id')}>
                                  {i < 2 && (
                                    <span key={resident.get('id')} className="table-units-list__resident">
                                      {resident.getIn(['user', 'first_name']) + ' '}
                                      {resident.getIn(['user', 'last_name'])}
                                      {i + 1 !== unit.get('residents').size ? ', ' : null}
                                    </span>
                                  )}
                                </Fragment>
                              );
                            })
                        : null}
                      {unit.get('residents').size > 2 && (
                        <>
                          {userPermission.viewUnit ? (
                            <Link
                              className="more-residents__link"
                              to={`/properties/${property.get('id')}/units/${unit.get('id')}`}
                            >
                              +{unit.get('residents').size - 2} {t('moreUnit')}
                            </Link>
                          ) : (
                            <>
                              +{unit.get('residents').size - 2} {t('moreUnit')}
                            </>
                          )}
                        </>
                      )}
                      {!unit.get('residents').size && <div>No Residents</div>}
                    </div>
                  ) : (
                    !unit.get('residents').size &&
                    userPermission.viewVacantUnitAutomationPage && (
                      <div className="unit-card__residents">
                        <div>No Residents</div>
                      </div>
                    )
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

UnitTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('units')(UnitTable);
