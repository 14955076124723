import React, { useState } from 'react';
import Icon from '../../../../../../../../common/icons/icon';
import AlertMessage from '../../../../../../../../common/AlertMessage';
import { useCancelAmenityReservation } from '../../hooks';
import { Formik } from 'formik';
import Yup from 'yup';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { useTranslation } from 'react-i18next';

const formattedErrorMessages = {
  MissingCancelReason: 'Please enter a reason',
};

export const CancelReservationModal = ({ amenityId, reservationId, onClose, onCancelReservation, visible }) => {
  const [submitError, setSubmitError] = useState(null);
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.modals.cancelReservation' });
  const { t: tCommon } = useTranslation('buttons');

  const cancelAmenityReservation = useCancelAmenityReservation(amenityId, reservationId);

  if (!visible) return null;

  return (
    <Formik
      initialValues={{
        reason: '',
      }}
      validationSchema={Yup.object().shape({
        reason: Yup.string()
          .trim()
          .max(2000, t('reasonErrors.length'))
          .required(t('reasonErrors.required')),
      })}
      onSubmit={(formValues, formikActions) => {
        cancelAmenityReservation.mutate(formValues, {
          onSuccess: () => {
            onCancelReservation();
          },
          onError: e => {
            setSubmitError(formattedErrorMessages[e.message] || e.message);
          },
        });
      }}
      render={({ values, errors, handleChange, touched, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="modal modal--visible">
            <div className="modal__children">
              <div className="modal__header">
                <h3>{t('title')}</h3>
                <div className="modal__close" onClick={onClose}>
                  <Icon icon="Clear" />
                </div>
              </div>
              <div className="modal__body">
                <p className="modal__body--text">{t('body')}</p>
                <InputScaffold label={t('field')} required validation={touched.reason && errors.reason}>
                  <textarea
                    name="reason"
                    maxLength="2000"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.reason}
                    rows={6}
                  />
                </InputScaffold>

                {submitError && <AlertMessage msg={submitError} type="error" />}
              </div>
              <div className="modal__button--container">
                <button className="button" type="submit">
                  {t('submit')}
                </button>
                <button className="button button--secondary" type="button" onClick={onClose}>
                  {tCommon('cancel')}
                </button>
              </div>
            </div>
            <button type="button" className="modal__overlay" onClick={onClose} />
          </div>
        </form>
      )}
    />
  );
};
