/* eslint-disable no-restricted-globals */

// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/fn/array/includes';
// These polyfills are needed for Yup to work in IE
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/es6/map';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import URLSearchParams from 'url-search-params';
import store from 'store2';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';
import i18n from './common/i18n';

import App from './App';
import config from './common/config';
import reduxStore, { DevTools } from './common/store';
import './index.css';
import * as Sentry from '@sentry/browser';
import { version } from '../package.json';

// const ga = window.ga;

const process_env = JSON.parse(process.env.REACT_APP_ENV_VARS || null) || process.env;

const { REACT_APP_ENV } = process_env;

Sentry.init({
  dsn: 'https://7603e9ad08289e9a79e56e1b4a10f51f@o4505975885135872.ingest.sentry.io/4505983648333824',
  environment: REACT_APP_ENV,
  release: version,
});

// Polyfill URLSearchParams so fetch-client works in IE
window.URLSearchParams = URLSearchParams;
// Polyfill isNaN for IE11
Number.isNaN =
  Number.isNaN ||
  function(value) {
    // The following works because NaN is the only value in javascript which is not equal to itself.
    return value !== value; // eslint-disable-line no-self-compare
  };

// Handle auth tokens from OIDC provider, if set
if (window.location.hash) {
  // Parse URL hash of format #foo=bar&stuff=things
  const hashQuery = window.location.hash
    .substring(1)
    .split('&')
    .map(str => str.split('='))
    .reduce((prev, curr) => {
      prev[curr[0]] = curr[1];
      return prev;
    }, {});

  // nonce is only set when frontend requests login
  const nonce = store.get('nonce');

  if (hashQuery.id_token && nonce) {
    let decoded = JSON.parse(atob(hashQuery.id_token.split('.')[1]));

    if (nonce === decoded.nonce) {
      store.set('code', hashQuery.code);
      store.set(config.auth.idTokenKey, hashQuery.id_token);
      store.remove('nonce');
    }
  }

  // Remove URL hash while maintaining history and query string
  history.replaceState('', document.title, window.location.pathname + window.location.search);
}

const removeTrailingSlash = ({ location }) => {
  const { pathname } = location;

  if (pathname.substr(-1) === '/') {
    return <Redirect to={pathname.substr(0, pathname.length - 1)} />;
  } else {
    return null;
  }
};

// Google Analytics setup.
// ga('create', config.gaData.id, 'auto');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: 0,
    },
  },
});

// Bootstrap the React app

ReactDOM.render(
  <Provider store={reduxStore}>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <div>
            <Route path="/" render={removeTrailingSlash} />
            <Route component={App} />
            <Route component={DevTools} />
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// Unregister the service worker

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
