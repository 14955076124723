import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import { withTranslation } from 'react-i18next';
import { trimAndSetNullFalsyValues } from '../../../../../../../../../../common/utils/helpers';
import ResidentForm from '../../../../../../../../common/ResidentForm';
import Loading from '../../../../../../../../../../common/Loading';
import { useCreateUnitAccessCode } from '../../../../../../../../../../common/AccessCodes/mutations/useUnitAccessCodeMutations';
import { useCreateCommunityAccessCode } from '../../../../../../../../../../common/AccessCodes/mutations/useCommunityAccessCodeMutations';
import PageHeader from '../../../../../../../../../../common/PageHeader';

const AddResident = ({
  accessControlProfilesLoaded,
  accessControlProfileDefaults,
  accessControlProfileOptions,
  addResident,
  communityHasAccessControlEnabled,
  getProfiles,
  hasPanel,
  history,
  match,
  property,
  setCommunityAccessCodeOnResident,
  setUnitAccessCodeOnResident,
  unit,
  unitHasSmartLock,
  userPermissionList,
  t,
}) => {
  const [isResidentAdded, setIsResidentAdded] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);
  const [invalidCodeDetails, setInvalidCodeDetails] = useState(null);
  const createUnitAccessCodeMutation = useCreateUnitAccessCode();
  const createCommunityAccessCodeMutation = useCreateCommunityAccessCode();
  const userPermission = {
    removeResident: userPermissionList.includes('REMOVE_RESIDENT'),
    ChangResidentAccessControlProfile: userPermissionList.includes("CHANGE_A_RESIDENT'S_ACCESS_CONTROL_PROFILE"),
    editResidentPersonalDetails: true,
  };

  useGetProfilesOnMount(getProfiles, hasPanel, match.params.propertyId);

  useEffect(() => {
    if (
      isResidentAdded &&
      (!unitHasSmartLock || createUnitAccessCodeMutation.isSuccess || createUnitAccessCodeMutation.isError) &&
      (!communityHasAccessControlEnabled ||
        createCommunityAccessCodeMutation.isSuccess ||
        createCommunityAccessCodeMutation.isError)
    ) {
      if (createUnitAccessCodeMutation.isError || createCommunityAccessCodeMutation.isError) {
        window.alert('Resident created successfully, but an error occurred when creating their access code(s).');
      }
      history.push(`/properties/${match.params.propertyId}/units/${match.params.unitId}`);
    }
  }, [
    isResidentAdded,
    unitHasSmartLock,
    createUnitAccessCodeMutation.isSuccess,
    createUnitAccessCodeMutation.isError,
    communityHasAccessControlEnabled,
    createCommunityAccessCodeMutation.isSuccess,
    createCommunityAccessCodeMutation.isError,
    history,
    match.params.propertyId,
    match.params.unitId,
  ]);

  const onAddResident = (values, formActions) => {
    return addResident(match.params.unitId, trimAndSetNullFalsyValues(values)).then(action => {
      if (action.response.ok) {
        if (!unitHasSmartLock && !communityHasAccessControlEnabled) {
          formActions.resetForm();
        }
        setIsResidentAdded(true);
        if (unitHasSmartLock) {
          createUnitAccessCodeMutation.mutate(
            { residentId: action.json.id },
            {
              onSuccess: (data, variables) => {
                setUnitAccessCodeOnResident(variables.residentId, data);
                formActions.resetForm();
              },
            }
          );
        }
        if (communityHasAccessControlEnabled) {
          createCommunityAccessCodeMutation.mutate(
            { residentId: action.json.id },
            {
              onSuccess: (data, variables) => {
                setCommunityAccessCodeOnResident(variables.residentId, data);
                formActions.resetForm();
              },
            }
          );
        }
      } else {
        formActions.setSubmitting(false);
        let formattedErrorMessage;

        switch (action.json.message) {
          case 'no_open_slots':
            formattedErrorMessage = 'No User Code Slots Available';
            break;
          case 'code_create_error':
            formattedErrorMessage = 'Error Creating Code. Please Try Again';
            break;
          case 'duplicate_resident':
            formattedErrorMessage = 'Resident already belongs to this unit';
            break;
          default:
            formattedErrorMessage = 'Problem Adding Resident';
            break;
        }
        setSubmitMessage(formattedErrorMessage);
        setSubmitStatus('Failure');
        setInvalidCodeDetails(action.json.invalidCodeDetails);
      }
    });
  };

  if (hasPanel && !accessControlProfilesLoaded) {
    return <Loading />;
  }

  return (
    <div>
      <PageHeader title={t('unitAddResidentBtn')} white backLink={'../'} />
      <ResidentForm
        editing={false}
        submitFunc={onAddResident}
        submitMessage={submitMessage}
        submitStatus={submitStatus}
        onCancel={() => {
          history.push(`/properties/${match.params.propertyId}/units/${match.params.unitId}`);
        }}
        invalidCodeDetails={invalidCodeDetails}
        unitId={unit.get('id')}
        propertyId={match.params.propertyId}
        initialValues={{
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          phone_country_code: '1',
          directory_display_name: '',
          directory_sort_name: '',
          directory_phone: '',
          directory_phone_country_code: '1',
          enable_directory_listing: false,
          enable_guest_community_access: true,
          profiles: accessControlProfileDefaults,
        }}
        profileOptions={hasPanel ? accessControlProfileOptions : new List()}
        propertyHasMasterPanel={property.get('clubhouse_panel_id') !== null}
        propertyHasAccessControl={property.get('admin_enabled_access_control')}
        propertyHasResidentGuestCommunityAccess={property.get('enabled_resident_community_guests')}
        userPermission={userPermission}
      />
    </div>
  );
};

const useGetProfilesOnMount = (getProfiles, hasPanel, propertyId) => {
  return useEffect(() => {
    if (hasPanel) {
      getProfiles(propertyId);
    }
  }, [getProfiles, hasPanel, propertyId]);
};

export default withTranslation('units')(AddResident);
