import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Loading from '../../../../../../../../common/Loading';
import SubHeader from '../../../../../../../../common/SubHeader';
import EmptyPlaceholder from '../../../../../../../../common/EmptyPlaceholder';
import Pin from '../../../../../../../../common/Pin';
import { getAnnouncements, pinAnnouncement } from '../../../../../../redux/actions';
import { getUserPermission } from '../../../../../../../../redux/selectors';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GenerateCSVBtn } from '../../../Events/EditEvent/Registrations/GenerateCSVBtn';

const AnnouncementList = connect(state => ({ userPermissionList: getUserPermission(state) }))(props => {
  const { match, userPermissionList, t } = props;
  const userPermission = {
    Add_Announcement: userPermissionList.includes('ADD_ANNOUNCEMENT'),
  };
  const loading = useSelector(state => state.property.messagesListLoading);
  const property = useSelector(state => state.property.activeProperty);
  const announcementList = useSelector(state => state.property.messagesList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnnouncements(property.get('id').toString()));
  }, [dispatch, property]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="messages-list__wrapper">
      <div className="container">
        <div>
          <SubHeader
            title={t('pageTitle')}
            actions={[
              !announcementList.size ? null : (
                <>
                  {userPermission.Add_Announcement && (
                    <>
                      <GenerateCSVBtn
                        filePath={`properties/${property.get('id')}/messages-csv-list.csv`}
                        style={{ marginRight: '15px' }}
                        title={t('downloadCSVBtn')}
                      />
                      <Link className="button" to={`${match.url}/add`}>
                        {t('addAnnouncementsBtn')}
                      </Link>
                    </>
                  )}
                </>
              ),
            ]}
          />
        </div>
        {announcementList.size ? (
          <div>
            <table className="table-list">
              <thead>
                <tr>
                  <th></th>
                  <th>{t('titleColumn')}</th>
                  <th>{t('groupColumn')}</th>
                  <th>{t('postedDateColumn')}</th>
                </tr>
              </thead>

              <tbody>
                {announcementList.map(announcement => {
                  return (
                    <tr key={announcement.get('id')}>
                      <td>
                        <Pin
                          pinned={announcement.get('pinned')}
                          didPin={pinned => {
                            dispatch(pinAnnouncement(property.get('id'), announcement.get('id'), pinned));
                          }}
                        />
                      </td>
                      <td className="message-table-title">
                        <div className="message-table-title__inner">
                          <Link
                            to={`/properties/${property.get('id')}/community/announcements/${announcement.get('id')}`}
                          >
                            {announcement.get('title')}
                          </Link>
                        </div>
                      </td>
                      <td>
                        {announcement.get('groups').size ? (
                          announcement.get('groups').map(group => {
                            return (
                              <div key={group.get('id')} className="table-messages-list__resident">
                                {group.get('name') + ' '}
                              </div>
                            );
                          })
                        ) : (
                          <div>{t('allResidents')}</div>
                        )}
                      </td>
                      <td>
                        {moment
                          .tz(announcement.get('created_at'), property.get('timezone_id'))
                          .format(t('dateWithFullTimeFormat', { ns: 'time' }))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <EmptyPlaceholder
            buttonText={t('addAnnouncementsBtn')}
            linkTo={userPermission.Add_Announcement ? `${match.url}/add` : ''}
          />
        )}
      </div>
    </div>
  );
});

export default withTranslation(['announcements', 'time'])(AnnouncementList);
