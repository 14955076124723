import React, { useState } from 'react';
import Icon from '../../../../../../../../common/icons/icon';
import AlertMessage from '../../../../../../../../common/AlertMessage';
import { useDeleteTemporaryClosing } from '../../hooks';
import { useTranslation } from 'react-i18next';

const formattedErrorMessages = {
  TemporaryClosingAlreadyDeleted: 'This temporary closing has already been deleted.',
};

export const DeleteTemporaryClosingModal = ({ amenityId, temporaryClosingId, onDelete, onClose }) => {
  const [submitError, setSubmitError] = useState(null);
  const deleteAmenity = useDeleteTemporaryClosing(amenityId);
  const { t } = useTranslation('amenities', {
    keyPrefix: 'amenityPage.modals.deleteTemporaryClosing',
  });

  return (
    <div className="modal modal--visible">
      <div className="modal__children modal--small">
        <div className="modal__header">
          <h3>{t('title')}</h3>
          <div className="modal__close" onClick={onClose}>
            <Icon icon="Clear" />
          </div>
        </div>
        <div className="modal__body">
          <p className="modal__body--text">{t('subtitle')}</p>
          {submitError && <AlertMessage msg={submitError} type="error" />}
        </div>
        <div className="modal__button--container flex-end">
          <button className="button button--secondary" type="button" onClick={onClose}>
            {t('cancel')}
          </button>
          <button
            className="button"
            type="button"
            onClick={() => {
              deleteAmenity.mutate(temporaryClosingId, {
                onSuccess: onDelete,
                onError: e => {
                  setSubmitError(formattedErrorMessages[e.message] || e.message);
                },
              });
            }}
          >
            {t('submit')}
          </button>
        </div>
      </div>
      <button type="button" className="modal__overlay" onClick={onClose} />
    </div>
  );
};
