import React, { useState } from 'react';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export const AmenityCustomAvailabilityPicker = ({ isSubmitting, setFieldValue, values, weekDays, errors, touched }) => {
  const [lastCurrentHours, setLastCurrentHours] = useState({
    open: null,
    close: null,
  });
  const { t } = useTranslation('amenities', {
    keyPrefix: 'amenityPage.tabs.availability.sections.daysAndHours.subsections.custom',
  });
  const { t: tCommon } = useTranslation('time');

  const currentHours = { open: lastCurrentHours.open, close: lastCurrentHours.close };

  return (
    <>
      <h4 className="section-header h4 w--helper">{t('title')}</h4>
      <p className="help-text">{t('subtitle')}</p>
      <div className="week-days__wrapper">
        {weekDays.map(day => (
          <div
            key={day.name}
            style={{ borderRadius: '100%' }}
            className={`week-day ${
              values.availability_schedule[day.name] ? 'week-day--selected' : 'week-day--unselected'
            }`}
            onClick={() => {
              setFieldValue('availability_schedule', {
                ...values.availability_schedule,
                [day.name]: values.availability_schedule[day.name]
                  ? undefined
                  : { open: currentHours.open, close: currentHours.close },
              });
              values.availability_schedule[day.name] = { open: currentHours.open, close: currentHours.close };
            }}
          >
            {day.shorthand}
          </div>
        ))}
      </div>
      <div className="amenity--custom-availability__week-days-list">
        {weekDays.map(day => {
          if (!values.availability_schedule[day.name]) {
            return null;
          }

          let errorStateClass = {
            'error-state': !!touched.availability_schedule?.[day.name] && !!errors.availability_schedule?.[day.name],
          };

          return (
            <div key={day.name} className={cn('amenity--custom-availability__single-day', errorStateClass)}>
              <div className="day--shorthand">
                <span>{day.shorthand}</span>
              </div>
              <div className="custom-availability--inner-scaffold__wrapper">
                <InputScaffold
                  validation={touched.availability_schedule?.[day.name] && errors.availability_schedule?.[day.name]}
                  key={day.name}
                >
                  <div className="custom-availability--flex-inputs">
                    <InputScaffold label={t('openingTimeField')} required>
                      <TimePicker
                        name="open"
                        showSecond={false}
                        value={values.availability_schedule[day.name]?.open}
                        defaultOpenValue={moment()
                          .hour(8)
                          .minute(0)
                          .second(0)}
                        onChange={time => {
                          currentHours.open = time;
                          currentHours.close = values.availability_schedule[day.name]?.close;
                          values.availability_schedule[day.name].open = time;
                          setLastCurrentHours(currentHours);
                          setFieldValue('availability_schedule', {
                            ...values.availability_schedule,
                            [day.name]: {
                              open: time,
                              close: values.availability_schedule[day.name]?.close,
                            },
                          });
                        }}
                        format={tCommon('timeFormat')}
                        allowEmpty={false}
                        use12Hours={tCommon('is12HoursTime')}
                        inputReadOnly
                        disabled={isSubmitting}
                        minuteStep={15}
                      />
                    </InputScaffold>
                    <InputScaffold label={t('closingTimeField')} required>
                      <TimePicker
                        name="close"
                        showSecond={false}
                        value={values.availability_schedule[day.name]?.close}
                        defaultOpenValue={moment()
                          .hour(20)
                          .minute(0)
                          .second(0)}
                        onChange={time => {
                          currentHours.close = time;
                          currentHours.open = values.availability_schedule[day.name]?.open;
                          values.availability_schedule[day.name].close = time;
                          setLastCurrentHours(currentHours);
                          setFieldValue('availability_schedule', {
                            ...values.availability_schedule,
                            [day.name]: {
                              close: time,
                              open: values.availability_schedule[day.name]?.open,
                            },
                          });
                        }}
                        format={tCommon('timeFormat')}
                        allowEmpty={false}
                        use12Hours={tCommon('is12HoursTime')}
                        inputReadOnly
                        disabled={isSubmitting}
                        minuteStep={15}
                      />
                    </InputScaffold>
                  </div>
                </InputScaffold>
              </div>
            </div>
          );
        })}
        {touched.availability_schedule &&
        errors.availability_schedule &&
        typeof errors.availability_schedule === 'string' ? (
          <div className="input-validation">{errors.availability_schedule}</div>
        ) : null}
      </div>
    </>
  );
};
