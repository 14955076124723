import React from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';
import Icon from '../../../../../../../../common/icons/icon';
import Kebab from '../../../../../../../../common/Kebab';
import { useTranslation } from 'react-i18next';

export const ReservationListItem = ({
  communityTimezoneId,
  amenityId,
  reservation,
  isKebabMenuOpen,
  openKebabMenu,
  closeKebabMenu,
  onCancelReservation,
  setCancelModalReservation,
  cancelAmenityReservationPermission,
}) => {
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.reservations' });
  const { t: tTime } = useTranslation('time');

  const cancelReservation = () => {
    closeKebabMenu();
    setCancelModalReservation(reservation.id);
  };

  const currentTimeInCommunityTimezone = moment.tz(communityTimezoneId);
  const reservationEndTimeInCommunityTimezone = moment.tz(
    `${reservation.date} ${reservation.end_time}`,
    'YYYY-MM-DD HH:mm',
    communityTimezoneId
  );

  const amenityReservationKebabItems = (
    <>
      <div
        className="kebab__menu--item"
        onClick={() => {
          cancelReservation();
        }}
      >
        <Icon icon="CircleX" />
        {t('cancelReservation')}
      </div>
    </>
  );

  const isReservationInPast = currentTimeInCommunityTimezone.isAfter(reservationEndTimeInCommunityTimezone);

  /* @Design "past" state needs a design pass */
  const classes = {
    'amenity-reservation-item': true,
    past: isReservationInPast,
  };

  return (
    <tr className={cn(classes)}>
      <td>{reservation.resident_name}</td>
      <td>
        {moment(reservation.date).format(tTime('dateFormat'))}
        {isReservationInPast ? ` (${t('past')})` : ''}
      </td>
      <td>
        {moment(reservation.start_time, 'HH:mm').format(tTime('timeFormat'))} -{' '}
        {moment(reservation.end_time, 'HH:mm').format(tTime('timeFormat'))}
      </td>
      <td className="table__align-right">
        {!isReservationInPast && cancelAmenityReservationPermission && (
          <Kebab onClick={openKebabMenu} onDisengage={closeKebabMenu} menuOpened={isKebabMenuOpen}>
            <div className="kebab__menu">{amenityReservationKebabItems}</div>
          </Kebab>
        )}
      </td>
    </tr>
  );
};
