import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Delete extends Component {
  static getDerivedStateFromProps(props, state) {
    return (state.isError = props.hasError ? props.hasError : null);
  }

  state = {
    isError: false,
  };

  onClickCancel = () => {
    this.setState({ isError: false });
    this.props.onCancel();
  };

  render() {
    const { message, disabled, inline, deleteFunc, t } = this.props;
    const { isError } = this.state;
    return (
      <div className={`delete__wrapper ${inline ? 'inline' : ''}`}>
        <div className="text-error remove-text">{message}</div>
        <div className="delete__button-container">
          <button
            className="button button--destructive"
            type="button"
            onClick={deleteFunc}
            style={{ marginRight: '24px' }}
            disabled={disabled}
          >
            {isError ? t('tryAgain') : t('yes')}
          </button>
          <button className="button button--secondary--destructive" type="button" onClick={this.onClickCancel}>
            {t('cancel')}
          </button>
        </div>
      </div>
    );
  }
}

Delete.propTypes = {
  deleteFunc: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.node,
  hasError: PropTypes.bool,
  inline: PropTypes.bool,
};

Delete.defaultProps = {
  reasonBox: {
    reason: false,
    label: '',
    error: '',
  },
  message: (
    <span>
      Are you sure you want to <strong className="strong">remove this</strong>?
    </span>
  ),
  hasError: false,
};

export default withTranslation('buttons')(Delete);
