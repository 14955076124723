import React, { Component, Fragment } from 'react';
import Icon from '../../../../../../../common/icons/icon';
import { withTranslation } from 'react-i18next';

class PendingPostRow extends Component {
  state = {
    showBody: false,
  };
  renderUnitNumber = post => {
    const units = post.getIn(['author', 'resides_in']);

    return units.count() > 0 ? post.getIn(['unit', 'number']) : '-';
  };
  render() {
    const { post, approveDenyPendingDiscussionPermission, t } = this.props;
    return (
      <Fragment>
        <tr key={post.get('id')} className={`pending-post-row ${post.get('deleted_at') ? 'deleted' : ''}`}>
          <td className="post-table-title">
            {post.getIn(['author', 'first_name'])} {post.getIn(['author', 'last_name'])}
          </td>
          <td>{this.renderUnitNumber(post)}</td>
          <td>{t(post.get('post_type'))}</td>
          <td>{post.get('headline')}</td>
          <td>
            <div
              className="show-body-button"
              onClick={() => {
                this.setState({ showBody: !this.state.showBody });
              }}
            >
              {t('See Post Body')} {this.state.showBody ? <Icon icon="ChevronUp" /> : <Icon icon="ChevronDown" />}
            </div>
          </td>
          <td>
            <div className="pending-post--action-button__wrapper">
              {approveDenyPendingDiscussionPermission && (
                <>
                  <div className="action-button reject" onClick={this.props.onReject}>
                    <Icon icon="FatClear" />
                  </div>
                  <div className="action-button approve" onClick={this.props.onApprove}>
                    <Icon icon="FatCheck" />
                  </div>
                </>
              )}
            </div>
          </td>
        </tr>
        {this.state.showBody && (
          <tr className="pending-post-body__row">
            <td colspan={6}>
              <div className="pending-post-body">{post.get('body')}</div>
            </td>
          </tr>
        )}
      </Fragment>
    );
  }
}

export default withTranslation('pendingDiscussions')(PendingPostRow);
