import React from 'react';
import { Formik, Field } from 'formik';
import Yup from 'yup';
import MaskedInput from 'react-input-mask';
import { withTranslation } from 'react-i18next';
import InputScaffold from '../../../../common/forms/InputScaffold';
import { getPhoneMask, isValidUUID } from '../../../../common/utils/helpers';
import Loading from '../../../../common/Loading/ComponentLoading';
import SubHeader from '../../../../common/SubHeader';
import { uniqueProperty } from '../../../../common/utils/yup-extensions';
import AccessProfiles from '../../../../common/forms/AccessProfiles';
import NotificationStatusIndicator from '../../../../common/forms/NotificationStatusIndicator';
import { Prompt } from 'react-router-dom';
import DeleteWithPrompt from '../../../../common/DeleteWithPrompt';

Yup.addMethod(uniqueProperty.schemaConstructor, uniqueProperty.name, uniqueProperty.method);
const ResidentForm = ({
  initialValues,
  onCancel,
  submitMessage,
  submitStatus,
  submitFunc,
  propertyId,
  editing,
  handleRemoveResident,
  profileOptions,
  propertyHasAccessControl,
  propertyHasMasterPanel,
  propertyHasResidentGuestCommunityAccess,
  isRemoving,
  userPermission,
  t,
}) => {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .max(64, 'First Name must be 64 characters or less')
      .required(t('residentFirstNameError')),
    last_name: Yup.string()
      .trim()
      .max(64, 'Last Name must be 64 characters or less')
      .required(t('residentLastNameError')),
    phone: Yup.string()
      .matches(/^[1]\d{10}|[0,2-9]\d{9}$/, t('residentPhoneError'))
      .nullable(true),
    email: Yup.string()
      .trim()
      .email('Must be a valid email address')
      .max(255)
      .required(t('residentEmailError')),
    enable_directory_listing: Yup.boolean().required(),
    directory_display_name: Yup.string()
      .max(64)
      .when('enable_directory_listing', {
        is: val => val === true,
        then: Yup.string()
          .trim()
          .required('Display name is required'),
        otherwise: Yup.string().notRequired(),
      }),
    directory_sort_name: Yup.string()
      .max(64)
      .when('enable_directory_listing', {
        is: val => val === true,
        then: Yup.string()
          .trim()
          .required('Sort name is required.'),
        otherwise: Yup.string().notRequired(),
      }),
    directory_phone: Yup.string().when('enable_directory_listing', {
      is: val => val === true,
      then: Yup.string()
        .matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number')
        .required('Fallback phone is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    profiles:
      propertyHasAccessControl && propertyHasMasterPanel
        ? Yup.array()
            .of(
              Yup.string()
                .trim()
                .required()
                .test('is-uuid', 'Must be a valid UUID', value => isValidUUID(value))
            )
            .min(1, 'At least one access profile option must be selected')
        : Yup.string().notRequired(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        const payload = { ...values };
        if (editing) {
          delete payload.email;
        }

        if (!payload.enable_directory_listing) {
          delete payload.directory_display_name;
          delete payload.directory_sort_name;
          delete payload.directory_phone;
          delete payload.directory_phone_country_code;
        }

        if (propertyHasAccessControl && propertyHasMasterPanel) {
          payload.access_control_profile_id1 = payload.profiles[0] || null;
          payload.access_control_profile_id2 = payload.profiles[1] || null;
          payload.access_control_profile_id3 = payload.profiles[2] || null;
          payload.access_control_profile_id4 = payload.profiles[3] || null;
        } else {
          payload.access_control_profile_id1 = null;
          payload.access_control_profile_id2 = null;
          payload.access_control_profile_id3 = null;
          payload.access_control_profile_id4 = null;
        }
        delete payload.profiles;

        return submitFunc(payload, actions);
      }}
    >
      {({
        dirty,
        values,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        setFieldValue,
        isSubmitting,
        isValid,
      }) => {
        if (isRemoving) {
          return (
            <div className="add-resident-card__loading">
              <Loading />
              <div className="add-resident-card__loading--text">Removing Resident. Please wait.</div>
            </div>
          );
        }
        // const userPermission =
        //   permissionCanEdit && userPermission.editResidentPersonalDetails;

        return (
          <div className="container">
            <Prompt when={dirty} message="Are you sure you want to leave? You have unsaved changes." />
            <form onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius-top-right">
                <SubHeader title={t('unitResidentInformation')} underline />
                <div>
                  <div className="input-scaffold__wrapper-row">
                    <InputScaffold
                      label={t('residentFirstName')}
                      required
                      validation={touched.first_name && errors.first_name}
                      disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                    >
                      <Field
                        type="text"
                        name="first_name"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.first_name}
                        disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                      />
                    </InputScaffold>
                    <InputScaffold
                      label={t('residentLastName')}
                      required
                      disabled={isSubmitting}
                      validation={touched.last_name && errors.last_name}
                    >
                      <Field
                        type="text"
                        name="last_name"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.last_name}
                        disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                      />
                    </InputScaffold>
                  </div>
                  <InputScaffold
                    label={t('residentEmail')}
                    required
                    disabled={isSubmitting}
                    validation={touched.email && errors.email}
                  >
                    <Field
                      disabled={editing || isSubmitting || !userPermission.editResidentPersonalDetails}
                      type="text"
                      name="email"
                      maxLength="255"
                      onChange={handleChange}
                      value={values.email}
                    />
                  </InputScaffold>
                  <div className="input-scaffold__wrapper-row" style={{ flexDirection: 'column' }}>
                    <InputScaffold
                      label={t('residentPhone')}
                      disabled={isSubmitting}
                      validation={touched.phone && errors.phone}
                    >
                      <MaskedInput
                        mask={getPhoneMask(values.phone)}
                        name="phone"
                        onChange={event => {
                          setFieldValue('phone', event.target.value.replace(/[^0-9]+/g, ''));
                        }}
                        onBlur={handleBlur}
                        value={values.phone}
                        disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                      />
                    </InputScaffold>
                    <p className="telnyx-eula-message">{t('residentHelperText')}</p>
                  </div>
                  {propertyHasResidentGuestCommunityAccess ? (
                    <div className="checkbox-list--item">
                      <label>
                        <Field
                          type="checkbox"
                          checked={values.enable_guest_community_access}
                          onChange={e => {
                            setFieldValue('enable_guest_community_access', e.target.checked);
                          }}
                          name="enable_guest_community_access"
                          disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                        />
                        {t('residentCheckbox')}
                      </label>
                    </div>
                  ) : null}

                  {propertyHasAccessControl && propertyHasMasterPanel && (
                    <AccessProfiles
                      errors={errors}
                      profileOptions={profileOptions}
                      propertyId={propertyId}
                      setFieldValue={setFieldValue}
                      selectedProfiles={values.profiles}
                      touched={touched}
                      disabled={isSubmitting}
                      userPermission={userPermission}
                    />
                  )}
                </div>

                <div className="resident--directory-listing-form__wrapper">
                  {propertyHasAccessControl && (
                    <div className="checkbox-list--item">
                      <label>
                        <Field
                          type="checkbox"
                          checked={values.enable_directory_listing}
                          onChange={e => {
                            setFieldValue('enable_directory_listing', e.target.checked);

                            if (e.target.checked) {
                              setFieldValue(
                                'directory_display_name',
                                `${values.first_name} ${values.last_name}`.substr(0, 64)
                              );
                              setFieldValue('directory_sort_name', `${values.last_name || ''}`.toUpperCase());
                              setFieldValue('directory_phone', values.phone);
                            }
                          }}
                          name="enable_directory_listing"
                          disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                        />
                        {t('enableDirectory')}
                      </label>
                    </div>
                  )}

                  {values.enable_directory_listing && propertyHasAccessControl && (
                    <div>
                      <InputScaffold
                        label="display name"
                        required
                        disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                        validation={touched.directory_display_name && errors.directory_display_name}
                      >
                        <Field
                          type="text"
                          name="directory_display_name"
                          maxLength="64"
                          disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                        />
                      </InputScaffold>
                      <InputScaffold
                        label="sort name"
                        required
                        disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                        validation={touched.directory_sort_name && errors.directory_sort_name}
                      >
                        <Field
                          type="text"
                          name="directory_sort_name"
                          maxLength="64"
                          value={values.directory_sort_name}
                          disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                        />
                      </InputScaffold>
                      <InputScaffold
                        required
                        disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                        label="fallback phone"
                        validation={touched.directory_phone && errors.directory_phone}
                      >
                        <MaskedInput
                          mask={getPhoneMask(values.directory_phone)}
                          name="directory_phone"
                          onChange={event => {
                            setFieldValue('directory_phone', event.target.value.replace(/[^0-9]+/g, ''));
                          }}
                          onBlur={handleBlur}
                          value={values.directory_phone}
                          disabled={isSubmitting || !userPermission.editResidentPersonalDetails}
                        />
                      </InputScaffold>
                    </div>
                  )}
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <button
                  disabled={isSubmitting || Object.keys(errors).length || !isValid}
                  type="submit"
                  className="button"
                >
                  {isSubmitting ? t('saveResidentBtn_1') : t('saveResidentBtn')}
                </button>
                {editing && userPermission && userPermission.removeResident && (
                  <DeleteWithPrompt
                    disabled={isSubmitting}
                    message={<span dangerouslySetInnerHTML={{ __html: t('removalConfirmation') }} />}
                    buttonLabel={t('removeResident')}
                    deleteFunc={handleRemoveResident}
                  />
                )}
                <button disabled={isSubmitting} type="button" className="button button--secondary" onClick={onCancel}>
                  {t('detailsCancelBtn')}
                </button>
                <NotificationStatusIndicator
                  hideNotification={!submitMessage}
                  message={submitMessage}
                  type={submitStatus}
                />
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default withTranslation('units')(ResidentForm);
