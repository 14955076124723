import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withTranslation } from 'react-i18next';
import AnnouncementDetailsForm from '../../../../../../../../common/forms/AnnouncementDetailsForm';
import PropertyPageHeader from '../../../../../../../../common/PropertyPageHeader';

class AddAnnouncement extends Component {
  render() {
    const { createMessage, history, property, groupOptions, t } = this.props;
    return (
      <div>
        <PropertyPageHeader title={t('pageTitle')} subtitle={this.props.property.get('name')} backLink="./" />
        <AnnouncementDetailsForm
          initialValues={{
            title: '',
            groups: [],
            all_residents_selected: true,
          }}
          groupOptions={groupOptions}
          submitFunction={submitValues => {
            if (submitValues.groups && submitValues.groups.length) {
              submitValues.groups = submitValues.groups.map(group => {
                return { id: group };
              });
            }
            createMessage(submitValues, property.get('id')).then(response => {
              if (response.response.ok) {
                history.push(`./`);
              }
            });
          }}
        />
      </div>
    );
  }
}

AddAnnouncement.propTypes = {
  createMessage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  property: PropTypes.instanceOf(Map).isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation('announcements')(AddAnnouncement);
