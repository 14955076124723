import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import ToggleScaffold from '../../../../../../../../common/forms/ToggleScaffold';
import { RESERVATION_TYPES } from '../../constants/reservationTypes';
import { MIN_RESERVATION_TIME_OPTIONS } from '../../constants/minReservationTimeOptions';
import Loading from '../../../../../../../../common/Loading';
import { CancelAllReservationsModal } from '../../EditAmenity/CancelAllReservationsModal';
import Icon from '../../../../../../../../common/icons/icon';
import { CANCEL_ALL_RESERVATIONS_MODAL_TYPES } from '../../constants/cancelAllReservationsModalTypes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const getAvailabilityCustomDays = values => {
  let customDaysArray = [];
  if (values.availability === 'CUSTOM') {
    customDaysArray = Object.values(values.availability_schedule).filter(Boolean);
  }
  return customDaysArray;
};

export const getMaximumReservationLengthOptions = (minReservationLength, amenity, values, t) => {
  let maxReservationLength = 24;
  let maxReservationTimeOptions = null;

  switch (amenity?.availability) {
    case 'DAILY':
      let close = moment('2020-01-20 ' + amenity.availability_schedule[0].close_time);
      let open = moment('2020-01-20 ' + amenity.availability_schedule[0].open_time);
      let duration = moment.duration(close.diff(open));
      maxReservationLength = duration.asMinutes();

      break;

    case 'CUSTOM':
      let biggestWindow = 0;

      for (let day of amenity.availability_schedule) {
        let close = moment('2020-01-20 ' + day.close_time);
        let open = moment('2020-01-20 ' + day.open_time);
        let duration = moment.duration(close.diff(open));
        const amountOfTime = duration.asMinutes();

        if (biggestWindow < amountOfTime) {
          biggestWindow = amountOfTime;
        }
      }
      maxReservationLength = biggestWindow;

      break;

    case '24/7':
      maxReservationLength = 24 * 60; //Minutes
      break;

    default:
      maxReservationLength = 0;
      break;
  }

  if (values?.availability) {
    switch (values.availability) {
      case 'DAILY':
        let duration = moment.duration(
          values.availability_schedule.friday?.close?.diff(values.availability_schedule.friday?.open)
        );
        maxReservationLength = duration.asMinutes();
        maxReservationLength = Math.round(maxReservationLength);

        break;

      case 'CUSTOM':
        let biggestWindow = 0;
        let customDays = getAvailabilityCustomDays(values);
        for (var i = 0; i < customDays.length; i++) {
          let amountOfTime = customDays[i].close?.diff(customDays[i].open, 'minutes');
          if (biggestWindow < amountOfTime) biggestWindow = amountOfTime;
        }
        maxReservationLength = biggestWindow;

        break;

      case '24/7':
        maxReservationLength = 24 * 60; //Minutes
        break;

      default:
        maxReservationLength = 0;
        break;
    }
  }

  if (minReservationLength) {
    maxReservationTimeOptions = [];
    let current = values.min_reservation;
    maxReservationTimeOptions.push({
      value: values.min_reservation,
      label: values.min_reservation + ` ${t('minutes')}`,
    });
    while (current < maxReservationLength) {
      current += 15;
      let minuteDisplayValue = current <= 120 ? current + ` ${t('minutes')}` : current / 60 + ` ${t('hours')}`;
      maxReservationTimeOptions.push({
        value: current,
        label: minuteDisplayValue,
      });
    }
  }

  return maxReservationTimeOptions;
};

export const ReservationType = props => {
  const {
    mode,
    errors,
    handleChange,
    isSubmitting,
    setFieldValue,
    touched,
    values,
    amenityHasReservations,
    amenity,
    setReasonForCancelAll,
  } = props;
  const { t: tTime } = useTranslation('time');
  const maxReservationTimeOptions = useMemo(
    () => getMaximumReservationLengthOptions(values.min_reservation, amenity, values, tTime),
    [values, amenity, tTime]
  );
  const { t } = useTranslation('amenities', { keyPrefix: 'amenityPage.tabs.reservationType' });

  const customDays = useMemo(() => getAvailabilityCustomDays(values), [values]);

  const [showCancelReservationsModal, setShowCancelReservationsModal] = useState(false);
  const [typeName, setTypeName] = useState('NON-RESERVABLE');
  const amenityHasActiveReservations = !!amenityHasReservations?.data ?? false;

  let amenityDuration;
  switch (amenity?.availability) {
    case 'DAILY':
      let open = moment('2020-01-20 ' + amenity.availability_schedule[0].open_time);
      let close = moment('2020-01-20 ' + amenity.availability_schedule[0].close_time);

      let duration = moment.duration(close.diff(open));
      amenityDuration = duration.asMinutes();
      break;
    case 'CUSTOM':
      let longestDuration = 0;
      for (let day of amenity.availability_schedule) {
        let close = moment('2020-01-20 ' + day.close_time);
        let open = moment('2020-01-20 ' + day.open_time);

        if (moment.duration(close.diff(open)).asMinutes() > longestDuration) {
          longestDuration = moment.duration(close.diff(open)).asMinutes();
        }
      }

      amenityDuration = longestDuration;

      break;

    case '24/7':
      amenityDuration = 24 * 60; //Minutes

      break;
    default:
      amenityDuration = 0;
      break;
  }
  if (values?.availability) {
    switch (values?.availability) {
      case 'DAILY':
        let open = values.availability_schedule.monday?.open;
        let close = values.availability_schedule.monday?.close;

        let duration = moment.duration(close?.diff(open));
        amenityDuration = duration.asMinutes();
        break;
      case 'CUSTOM':
        let longestDuration = 0;

        for (let day of customDays) {
          if (moment.duration(day.close?.diff(day.open)).asMinutes() > longestDuration) {
            longestDuration = moment.duration(day.close?.diff(day.open)).asMinutes();
          }
        }
        amenityDuration = longestDuration;
        break;

      case '24/7':
        amenityDuration = 24 * 60; //Minutes
        break;

      default:
        amenityDuration = 0;
        break;
    }
  }

  if (mode === 'edit' && amenityHasReservations?.status === 'loading') {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  const limitedOptions = () => {
    return (
      <div className="paper__grid">
        <div className="paper__section--grid-item">
          <section className="paper__section">
            <div className="section-header h4">{t('title')}</div>
            <p className="help-text">{t('limitedSubtitle')}</p>
          </section>
        </div>
      </div>
    );
  };

  const allOptions = () => {
    return (
      <div className="paper__grid">
        {mode === 'edit' && (
          <CancelAllReservationsModal
            onClose={() => {
              setShowCancelReservationsModal(false);
            }}
            onCancelReservation={reason => {
              setFieldValue('reservation_type', typeName);
              setFieldValue('cancel_all_reservations_reason', reason);
              setReasonForCancelAll(reason);
              setShowCancelReservationsModal(false);
            }}
            amenityId={amenity.id}
            visible={!!showCancelReservationsModal}
            cancelModalType={CANCEL_ALL_RESERVATIONS_MODAL_TYPES[typeName]}
          />
        )}
        <div className="paper__section--grid-item">
          <section className="paper__section">
            <div className="section-header h4">{t('title')}</div>
            <p className="help-text">{t('subtitle')}</p>

            {/* @Design This set of radios needs to look like the TextToggle component according to the mocks */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {RESERVATION_TYPES(t).map(type => {
                const shouldCancelReservations =
                  (type.name === 'NON_RESERVABLE' && amenityHasActiveReservations) ||
                  (values.reservation_type === 'FREE' && type.name === 'PAID' && amenityHasActiveReservations);

                return (
                  <ToggleScaffold label={type.display} key={type.name}>
                    <input
                      type="radio"
                      name={type.name}
                      disabled={amenity?.availability === 'NONE'}
                      checked={values.reservation_type === type.name}
                      onChange={() => {
                        if (type.name === 'NON_RESERVABLE') {
                          setFieldValue('cancel_fee_percent', '');
                          setFieldValue('price', '');
                          setFieldValue('refund_cutoff_time', '');
                          setFieldValue('refund_policy', '');
                          setFieldValue('cancelation_policy', '');
                          setFieldValue('max_reservation', '');
                          setFieldValue('min_reservation', '');
                        } else if (type.name === 'FREE') {
                          setFieldValue('cancel_fee_percent', '');
                          setFieldValue('price', '');
                          setFieldValue('refund_cutoff_time', '');
                          setFieldValue('refund_policy', '');
                        }

                        if (mode === 'edit') {
                          setReasonForCancelAll('');

                          if (shouldCancelReservations) {
                            setTypeName(type.name);
                            setShowCancelReservationsModal(true);
                          } else {
                            setFieldValue('reservation_type', type.name);
                          }
                        } else {
                          setFieldValue('reservation_type', type.name);
                        }
                      }}
                    />
                  </ToggleScaffold>
                );
              })}
            </div>
            {touched.reservation_type && errors.reservation_type ? (
              <div className="input-validation">{errors.reservation_type}</div>
            ) : null}
            {['FREE', 'PAID'].includes(values.reservation_type) && (
              <div>
                <div className="double-input__wrapper">
                  <InputScaffold
                    className="double-input-item"
                    label={t('types.free.minReservationField')}
                    required
                    validation={touched.min_reservation && errors.min_reservation}
                  >
                    <Select
                      clearable={false}
                      closeOnSelect={true}
                      placeHolder={t('types.free.minReservationPlaceholder')}
                      name="min_reservation"
                      searchable={false}
                      value={values.min_reservation}
                      onChange={value => {
                        setFieldValue('max_reservation', '');
                        setFieldValue('min_reservation', value.value);
                      }}
                      options={MIN_RESERVATION_TIME_OPTIONS(tTime).filter(
                        min_reservation_time => min_reservation_time.value < amenityDuration + 1
                      )}
                    />
                  </InputScaffold>
                  <InputScaffold
                    className="double-input-item"
                    label={t('types.free.maxReservationField')}
                    required
                    validation={touched.max_reservation && errors.max_reservation}
                  >
                    <Select
                      clearable={false}
                      closeOnSelect={true}
                      placeHolder={t('types.free.maxReservationPlaceholder')}
                      disabled={!values.min_reservation}
                      name="max_reservation"
                      searchable={false}
                      value={values.max_reservation}
                      onChange={value => setFieldValue('max_reservation', value.value)}
                      options={maxReservationTimeOptions}
                    />
                  </InputScaffold>
                </div>
                {['PAID'].includes(values.reservation_type) && (
                  <div>
                    <InputScaffold
                      label={t('types.paid.pricePerIncrementField')}
                      required
                      validation={touched.price && errors.price}
                    >
                      <div className="input-wrapper__prefix">
                        <div className="input--prefix-suffix-icon__wrapper">
                          <Icon icon="Money" />
                        </div>
                        <input
                          type="text"
                          name="price"
                          maxLength="6"
                          disabled={isSubmitting}
                          onChange={({ target }) => {
                            if (/^[0-9]*.?[0-9]?[0-9]?$/.test(target.value)) {
                              setFieldValue('price', target.value.replace(/[^0-9.]+/g, ''));
                            }
                          }}
                          value={values.price}
                        />
                      </div>
                    </InputScaffold>
                    <InputScaffold
                      label={t('types.paid.refundField')}
                      validation={touched.refund_cutoff_time && errors.refund_cutoff_time}
                    >
                      <div className="input-wrapper__suffix">
                        <input
                          type="text"
                          name="refund_cutoff_time"
                          maxLength="3"
                          disabled={isSubmitting}
                          onChange={({ target }) => {
                            if (/^[0-9]*$/.test(target.value)) {
                              setFieldValue('refund_cutoff_time', target.value);
                            }
                          }}
                          value={values.refund_cutoff_time}
                        />
                        <div className="input--prefix-suffix-icon__wrapper">
                          <Icon icon="Clock" />
                        </div>
                      </div>
                    </InputScaffold>
                    <InputScaffold
                      label={t('types.paid.cancelationFeeField')}
                      required
                      validation={touched.cancel_fee_percent && errors.cancel_fee_percent}
                    >
                      <div className="input-wrapper__suffix">
                        <input
                          type="text"
                          name="cancel_fee_percent"
                          maxLength="3"
                          disabled={isSubmitting}
                          onChange={({ target }) => {
                            if (/^(?:[1-9][0-9]?|100|$)$/.test(target.value)) {
                              setFieldValue('cancel_fee_percent', target.value);
                            }
                          }}
                          value={values.cancel_fee_percent}
                        />
                        <div className="input--prefix-suffix-icon__wrapper">
                          <Icon icon="Percent" />
                        </div>
                      </div>
                    </InputScaffold>
                  </div>
                )}
              </div>
            )}
          </section>
        </div>
        {['FREE', 'PAID'].includes(values.reservation_type) && (
          <div className="paper__section--grid-item">
            <section className="paper__section">
              <div className="section-header h4">
                {['PAID'].includes(values.reservation_type)
                  ? t('types.paid.cancelationPolicy.title')
                  : t('types.free.cancelationPolicy.title')}
              </div>
              <p className="help-text">{t('types.free.cancelationPolicy.subtitle')}</p>
              <InputScaffold
                label={t('types.free.cancelationPolicy.field')}
                required
                validation={touched.cancelation_policy && errors.cancelation_policy}
              >
                <textarea
                  name="cancelation_policy"
                  maxLength="2000"
                  rows="6"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  value={values.cancelation_policy}
                />
              </InputScaffold>

              {['PAID'].includes(values.reservation_type) && (
                <InputScaffold
                  label={t('types.paid.cancelationPolicy.refundField')}
                  required
                  validation={touched.refund_policy && errors.refund_policy}
                >
                  <textarea
                    name="refund_policy"
                    maxLength="2000"
                    rows="6"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.refund_policy}
                  />
                </InputScaffold>
              )}
            </section>
          </div>
        )}
      </div>
    );
  };

  return <div>{values.availability === 'NONE' || amenityDuration === 0 ? limitedOptions() : allOptions()}</div>;
};
