import React, { useState } from 'react';
import { Formik } from 'formik';
import Yup from 'yup';
import Icon from '../../../../../../common/icons/icon';
import AlertMessage from '../../../../../../common/AlertMessage';
import InputScaffold from '../../../../../../common/forms/InputScaffold';
import { useCancelEvent } from './hooks';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../../common/i18n';

const formattedErrorMessages = {
  AlreadyCanceled: i18n.t('modals.cancelEvent.alreadyCancelledErr', { ns: 'events' }),
  CannotCancelEventThatHasStarted: i18n.t('modals.cancelEvent.hasStartedErr', { ns: 'events' }),
};

export const CancelEventModal = ({ eventId, onCancel, onClose, visible }) => {
  const [submitError, setSubmitError] = useState(null);
  const cancelEvent = useCancelEvent(eventId);
  const { t } = useTranslation('events');

  if (!visible) return null;

  return (
    <Formik
      initialValues={{
        reason: '',
      }}
      validationSchema={Yup.object().shape({
        reason: Yup.string()
          .trim()
          .max(2000, t('modals.cancelEvent.invalidLengthErr'))
          .required(t('modals.cancelEvent.noReasonErr')),
      })}
      onSubmit={(formValues, formikActions) => {
        cancelEvent.mutate(formValues, {
          onSuccess: () => {
            onCancel();
          },
          onError: e => {
            formikActions.setSubmitting(false);
            setSubmitError(formattedErrorMessages[e.message] || e.message);
          },
        });
      }}
      render={({ values, errors, handleChange, touched, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="modal modal--visible">
            <div className="modal__children">
              <div className="modal__header">
                <h3>{t('modals.cancelEvent.header')}</h3>
                <div className="modal__close" onClick={onClose}>
                  <Icon icon="Clear" />
                </div>
              </div>
              <div className="modal__body">
                <p className="modal__body--text">{t('modals.cancelEvent.provideReason')}</p>
                <InputScaffold
                  label={t('modals.cancelEvent.inputLabel')}
                  required
                  validation={touched.reason && errors.reason}
                >
                  <textarea
                    name="reason"
                    maxLength="2000"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.reason}
                    rows={6}
                  />
                </InputScaffold>

                {submitError && <AlertMessage msg={submitError} type="error" />}
              </div>
              <div className="modal__button--container">
                <button className="button" type="submit">
                  {t('modals.cancelEvent.cancelEventBtn')}
                </button>
                <button className="button button--secondary" type="button" onClick={onClose}>
                  {t('modals.cancelEvent.cancelBtn')}
                </button>
              </div>
            </div>
            <button type="button" className="modal__overlay" onClick={onClose} />
          </div>
        </form>
      )}
    />
  );
};
