import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import querystring from 'query-string';
import { withTranslation } from 'react-i18next';

import UnitTable from './UnitTable';
import Pager from '../../../../../../common/Pager';
import { SearchText, SearchList } from '../../../../../../common/Search';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import PropertyPageHeader from '../../../../../../common/PropertyPageHeader';
import get from 'lodash/get';
import AlertMessage from '../../../../../../common/AlertMessage';
import Loading from '../../../../../../common/Loading';
import PrivateSection from '../../../../../../common/PrivateSection';

class UnitsList extends Component {
  state = {
    successMessage: null,
  };

  componentDidMount() {
    this._isMounted = true;

    const { propertyId, location } = this.props;
    // const userPermission = {
    //   addUnit: userPermissionList.includes('ADD_UNIT'),
    //   editUnitDetails: userPermissionList.includes('EDIT_UNIT_DETAILS'),
    //   importUnits: userPermissionList.includes('IMPORT_UNITS'),
    //   viewUnit: userPermissionList.includes('VIEW_UNIT'),
    //   addResidentToUnit: userPermissionList.includes('ADD_RESIDENT_TO_UNIT'),
    // };
    // this.setState({ userPermission: userPermission });

    this.updateData(propertyId, location);

    if (get(this.props, 'location.state.deletedUnit')) {
      this.setState({ successMessage: 'Unit Deleted' });
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({ successMessage: null });
        }
      }, 5000);
    }
  }

  componentDidUpdate(prevProps) {
    const { propertyId, location } = this.props;
    if (prevProps.location.search !== location.search) {
      return this.updateData(propertyId, location);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateData = (propertyId, location) => {
    if (location.search) {
      const search = querystring.parse(location.search);
      delete search.sectionName;
      if (['active', 'inactive', 'occupied', 'unoccupied'].indexOf(search.filter) === -1) {
        delete search.filter;
      }
      return this.props.browseUnits(propertyId, search);
    }
    this.props.browseUnits(propertyId);
  };

  onClickClearSearch = () => {
    const { history, location } = this.props;
    return history.push(location.pathname);
  };

  renderPlaceholder() {
    const { property, userPermissionList } = this.props;
    const userPermission = {
      addUnit: userPermissionList.includes('ADD_UNIT'),
      editUnitDetails: userPermissionList.includes('EDIT_UNIT_DETAILS'),
      importUnits: userPermissionList.includes('IMPORT_UNITS'),
      viewUnit: userPermissionList.includes('VIEW_UNIT'),
      addResidentToUnit: userPermissionList.includes('ADD_RESIDENT_TO_UNIT'),
    };
    const isShow = userPermissionList.includes('ADD_UNIT');
    return (
      <EmptyPlaceholder
        disabled="disabled"
        buttonText="Add Unit"
        linkTo={isShow ? `/properties/${property.get('id')}/units/add` : ''}
      >
        {userPermission && userPermission.importUnits && (
          <PrivateSection roles={['ADMIN']}>
            <div className="import-unit--action">
              <Link className="button" to={`/properties/${property.get('id')}/units/import`}>
                Import Units
              </Link>
              {isShow && <span>– or –</span>}
            </div>
          </PrivateSection>
        )}
      </EmptyPlaceholder>
    );
  }

  render() {
    const {
      loaded,
      loading,
      location,
      property,
      units,
      unitListTotal,
      residentsListTotal,
      searching,
      userPermissionList,
      t,
    } = this.props;
    const userPermission = {
      addUnit: userPermissionList.includes('ADD_UNIT'),
      editUnitDetails: userPermissionList.includes('EDIT_UNIT_DETAILS'),
      importUnits: userPermissionList.includes('IMPORT_UNITS'),
      viewUnit: userPermissionList.includes('VIEW_UNIT'),
      addResidentToUnit: userPermissionList.includes('ADD_RESIDENT_TO_UNIT'),
      viewVacantUnitAutomationPage: userPermissionList.includes('VIEW_VACANT_UNIT_AUTOMATION_PAGE'),
      controlSmartHomeDevicesOccupiedUnit: userPermissionList.includes('CONTROL_SMART_HOME_DEVICES_OCCUPIED_UNIT'),
      viewResidentList: userPermissionList.includes('VIEW_RESIDENT_LIST'),
    };
    const locationObj = querystring.parse(location.search);

    if (!loaded) {
      return <Loading />;
    }

    return (
      <div>
        <PropertyPageHeader>
          {(searching || (!searching && unitListTotal > 0)) && <SearchText name="q" placeHolder={t('searchUnits')} />}

          {searching ? (
            <button type="button" className="button" onClick={this.onClickClearSearch} style={{ marginTop: '10px' }}>
              {t('clearSearchBtn')}
            </button>
          ) : null}
        </PropertyPageHeader>
        <div className="container pm-units__content">
          <div className="properties-table__title">
            <div className="page-header__title">
              {!loading ? (
                <h4 className="h4">
                  {`${unitListTotal} ${t('counterUnits')}`} {`(${residentsListTotal} ${t('counterResidents')})`}{' '}
                  {locationObj.sectionName ? `in ${locationObj.sectionName}` : null}
                </h4>
              ) : null}
            </div>
            {(units.size > 0 || searching) && (
              <div className="page-header__actions">
                <div className="status-filter__container">
                  <span className="status-filter--label">{t('filterUnits')}</span>
                  <SearchList
                    name="filter"
                    placeHolder="Filters..."
                    options={[
                      { value: '', label: `${t('filterOptionsNone')}` },
                      { value: 'active', label: `${t('filterOptionsActive')}` },
                      { value: 'inactive', label: `${t('filterOptionsInactive')}` },
                      { value: 'occupied', label: `${t('filterOptionsOccupied')}` },
                      { value: 'unoccupied', label: `${t('filterOptionsUnoccupied')}` },
                    ]}
                  />
                </div>
                {userPermission.importUnits && (
                  <Link
                    className="button button--outline button--size--small unit-button"
                    to={`/properties/${property.get('id')}/units/import`}
                  >
                    {t('importUnitsBtn')}
                  </Link>
                )}

                {userPermission.addUnit && (
                  <Link
                    className="button button--size--small unit-button"
                    to={`/properties/${property.get('id')}/units/add`}
                  >
                    {t('addUnitBtn')}
                  </Link>
                )}
              </div>
            )}
          </div>
          <div>
            {searching && unitListTotal === 0 && (
              <h6 className="no-results"> There are no results for your search criteria. </h6>
            )}
          </div>
          {this.state.successMessage ? <AlertMessage type="success" msg={this.state.successMessage} /> : null}
          {!units.size && !searching && this.renderPlaceholder()}
        </div>
        {units.size > 0 && (
          <div>
            <UnitTable loading={loading} property={property} units={units} userPermission={userPermission} />
            {!loading ? (
              <Pager
                history={this.props.history}
                initialPage={parseInt(locationObj.page, 10) || 0}
                location={this.props.location}
                pageSize={30}
                totalItems={unitListTotal}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

UnitsList.propTypes = {
  browseUnits: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  property: PropTypes.instanceOf(Map).isRequired,
  searching: PropTypes.bool.isRequired,
  units: PropTypes.instanceOf(List).isRequired,
  unitListTotal: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation('units')(UnitsList);
